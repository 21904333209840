import styled from 'styled-components';
import { TabPanel } from '@mui/lab';

export const StyledTabPanel = styled(TabPanel)`
  && {
    .MuiToolbar-root {
      //padding: 0 12px;
    }
  }
`;

import React, { useState, useCallback, useEffect } from 'react';

// components
import {
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

// utils
import axios from 'axios';

// types
import { QuickbooksProjectType } from 'pages/CompanyPage/types';
import { StyledQuickbookSelect } from 'molecules/ProjectAutoComplete/ProjectAutoComplete.styles';

interface ProjectAutoCompleteProps {
  disabled: boolean;
  submitted: boolean;
  companyHsId?: string;
  project?: QuickbooksProjectType;
  onChange: (p: QuickbooksProjectType) => void;
}

export const QuickbooksProjectAutoComplete = ({
  submitted,
  disabled,
  companyHsId,
  project,
  onChange,
}: ProjectAutoCompleteProps) => {
  const [projectsList, setProjectsList] = useState<
    Array<QuickbooksProjectType>
  >([]);
  const [selected, setSelected] = useState<string>('');

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      setSelected(event.target.value);

      const selectedPrj = projectsList.find(
        ({ quickbook_project_id }) =>
          quickbook_project_id === event.target.value
      );

      if (!selectedPrj) return;

      onChange(selectedPrj);
    },
    [onChange, projectsList]
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE}/quickbook-projects/${companyHsId}`
      )
      .then((response) => {
        const { projects } = response.data;

        // If it's editing mode, then we need to push to list
        // already selected option as well;
        if (project && project.quickbook_project_id) projects.unshift(project);

        setProjectsList(projects);

        setSelected(String(project?.quickbook_project_id) || '');
      });
    // eslint-disable-next-line
  }, []);

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="quickbooks-project">Quickbooks Project Name</InputLabel>
      <StyledQuickbookSelect
        disabled={disabled}
        error={submitted && (!selected || selected === 'undefined')}
        labelId="quickbooks-project"
        value={selected}
        label="Quickbooks Project Name"
        onChange={handleSelectChange}
        // IconComponent={() => null}
      >
        {projectsList.length ? (
          projectsList.map(
            ({ quickbook_project_id, quickbook_project_name }) => (
              <MenuItem key={quickbook_project_id} value={quickbook_project_id}>
                {quickbook_project_name}
              </MenuItem>
            )
          )
        ) : (
          <MenuItem value="" disabled>
            no QuickBooks projects
          </MenuItem>
        )}
      </StyledQuickbookSelect>
    </FormControl>
  );
};

import React, { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from 'molecules/Table/styles';

const EmptyRow: FC<{ text?: string }> = ({ text }) => {
  if (text === '') return null;

  return (
    <TableRow>
      <StyledTableCell colSpan={20} sx={{ textAlign: 'center' }}>
        {text ?? 'No data'}
      </StyledTableCell>
    </TableRow>
  );
};

export default EmptyRow;

import React from 'react';
import styled from 'styled-components';
import {
  Radio,
  Stack,
  Button,
  Typography,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const CancelButtonWrapper = styled.div`
  .MuiButton-root {
    color: #333333;
    border-color: #e8e9eb;

    &:hover {
      color: #6f9fe9;
      background-color: #6f9fe926;
      border: 1px solid #e8e9eb;
    }
  }
`;

export const ConfirmButtonWrapper = styled.div`
  .MuiButton-root {
    border-color: #6f9fe9;
    background-color: #6f9fe9;

    &:hover {
      background-color: #8aafe9;
      border-color: #8aafe9;
    }

    &:disabled {
      background: #e8e9eb;
      border-color: #e8e9eb;
    }
  }
`;

export const ButtonNotVerified = styled(Button)`
  && {
    color: #fff;
    background-color: #ff626d;
    border-radius: 3px;
    padding: 2px 8px;
    font-size: 0.6rem;
    margin: 0 5px;
    text-transform: uppercase;

    &:hover {
      background-color: #ff626d;
    }
  }
`;

export const ModalTitleTypography = styled(Typography)`
  && {
    line-height: 24px;
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const ModalBodyTypography = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 1rem;
  }
`;

export const ModalBodyLink = styled(({ ...otherProps }) => (
  <Button {...otherProps} />
))`
  && {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    color: #6f9fe9;
    text-decoration: underline;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const MobileDialogTitle = styled(DialogTitle)`
  && {
    padding: 16px;
    h2 {
      color: var(--neutrals-600, #575d66);
      font-size: 1.85rem;
      font-weight: 700;
      line-height: 38px; /* 172.727% */
    }
    h5 {
      margin-top: 20px;
      color: var(--neutrals-800, #262b33);
      font-size: 1.17rem;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
`;

/**
 * *******************************
 * BELOW: PaymentOptions styles
 * *******************************
 */
export const PaymentOptionsWrapper = styled(Stack)`
  && {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;

    padding-top: 8px;
    padding-bottom: 16px;
    border-top: 1px solid ${neutrals[300]};
    border-bottom: 1px solid ${neutrals[300]};
  }
`;

export const PaymentOptionItem = styled(FormControlLabel)`
  && {
    margin: 0;
    gap: 16px;

    .payment-option-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .MuiTypography-root {
        font-weight: 400;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .MuiTypography-body1 {
        color: ${neutrals[700]};
      }

      .MuiTypography-body2 {
        color: ${neutrals[500]};
      }
    }
  }
`;

export const PaymentOptionRadio = styled(Radio)`
  && {
    padding: 0;
    width: 16px;
    height: 16px;

    color: #505459;
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.error.main} !important;
  }
`;

export const NotVerifiedButton = styled(Button)`
  && {
    color: ${palette.primary.main};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 500;
    line-height: 15.5px;
    text-decoration-line: underline;

    &:hover {
      background-color: transparent;
      text-decoration-line: underline;
    }
  }
`;

export const AddPaymentMethodButton = styled(Button)`
  && {
    padding: 0;

    color: ${({ theme }) => theme.palette.primary.main};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-decoration-line: underline;
  }
`;

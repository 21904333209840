import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { MobilePageWrapper } from 'templates/PageWrapper/PageWrapper';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';

const MobileDashboardTemplate: FC<{ children: JSX.Element }> = ({
  children,
}) => (
  <MobilePageWrapper
    title={
      <MobileInvoicesTitle variant="h2" my={3}>
        Outstanding invoices
      </MobileInvoicesTitle>
    }
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9}>
        {children}
      </Grid>
    </Grid>
  </MobilePageWrapper>
);

export default MobileDashboardTemplate;

import React from 'react';

import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface StyledLinkProps extends LinkProps {
  to: string;
  $fontSize?: string;
  $color?: string;
  $textDecoration?: 'underline' | 'none';
  children: JSX.Element[] | JSX.Element | string;
}

const StyledLink = styled(RouterLink)<StyledLinkProps>`
  && {
    overflow: hidden;
    color: ${(props) => props.$color || props.theme.palette.primary.main};
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: ${(props) => props.$fontSize || '0.75rem'};
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: ${({ $textDecoration }) =>
      $textDecoration === 'none' ? 'none' : 'underline'};

    width: fit-content;

    p {
      display: inherit;
    }
  }
`;

const Link = ({
  to,
  $fontSize,
  $color,
  children,
  ...props
}: StyledLinkProps) => (
  <StyledLink to={to} $fontSize={$fontSize} $color={$color} {...props}>
    {children}
  </StyledLink>
);

export default Link;

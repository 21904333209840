import styled from 'styled-components';
import { MenuItem, TextField, Typography } from '@mui/material';
import { light, neutrals } from 'utils/styles/color';

const StyledTextField = styled(TextField)<{
  $isMobile?: boolean;
  $hovered?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    min-width: ${({ $isMobile }) => ($isMobile ? '120px' : '240px')};
    max-width: ${({ $isMobile }) => ($isMobile ? '120px' : '320px')};
    height: 40px;
    padding: 0 8px;

    border-radius: 6px;
    background: ${({ $hovered }) => ($hovered ? light[100] : 'transparent')};

    .MuiInput-root.MuiInput-underline.MuiInputBase-root {
      overflow: hidden;
      min-width: ${({ $isMobile }) => ($isMobile ? '120px' : '240px')};
    }

    /* hide underline */
    .MuiInput-underline::after,
    .MuiInput-underline::before,
    .MuiSelect-nativeInput {
      border: none !important;
    }

    .MuiSelect-select.MuiInput-input {
      padding: 0 8px 0 0;

      display: flex;
      align-items: center;
      overflow: hidden;

      .MuiTypography-root {
        white-space: nowrap;
        color: ${neutrals[800]};
        text-overflow: ellipsis;

        font-family: Poppins, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    height: 40px;
    padding: 5px 8px;
    align-items: center;
  }
`;

const StyledMenuText = styled(Typography)`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export { StyledTextField, StyledMenuItem, StyledMenuText };

import styled from 'styled-components';
import { Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const MobileInvoicesTitle = styled(Typography)`
  && {
    color: ${neutrals[600]};
    font-family: Poppins, sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;

    text-align: right;
  }
`;

export const MobileInvoiceSubTitle = styled(Typography)`
  && {
    color: ${neutrals[800]};
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
`;

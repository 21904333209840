export const paymentCompanyOptions1 = [
  {
    value: 'USD',
    code: 'usa-USD',
    // description: 'Zazmic USA Quickbooks account',
  },
];

// export const paymentCompanyOptions3 = [
//   {
//     value: 'CAD',
//     code: 'cad-CAD',
//     // description: 'Zazmic USA Quickbooks account',
//   },
// ];

export const paymentCompanyOptions2 = [
  {
    value: 'EUR',
    code: 'europe-EUR',
    // description: 'Zazmic Ireland Quickbooks account',
  },
  {
    value: 'GBP',
    code: 'europe-GBP',
    // description: 'Zazmic Ireland Quickbooks account',
  },
  {
    value: 'USD',
    code: 'europe-USD',
    // description: 'Zazmic USA Quickbooks account',
  },
];

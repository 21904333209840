import styled from 'styled-components';

export const Invoices = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Invoice = styled.div<{ $color: string; $width: string }>`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 0.69rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  flex: 1;
  background-color: ${({ $color }) => $color};
  padding: 7px 8px;
  box-sizing: border-box;
  white-space: nowrap;

  h3 {
    margin: 0;
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

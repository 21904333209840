import styled from 'styled-components';

export default styled('span')`
  color: ${({ children }) => {
    let invoiceStatus = '';
    let invoices = '';

    if (Array.isArray(children)) {
      children.forEach((item) => {
        if (item && typeof item !== 'boolean') {
          const stringArr = item.split(' ');
          // eslint-disable-next-line prefer-destructuring
          invoices = stringArr[0];
        }
      });
    }

    invoiceStatus = invoices;

    switch (invoiceStatus) {
      case 'OPEN':
        return '#56B8EF';
      case 'PAID':
        return '#47C26A';
      case 'OVERDUE':
        return '#FF626D';
      case 'VOIDED':
        return '#8C8C8C';
      case 'PENDING':
        return '#F4BF32';
      case 'PAYMENT_PENDING':
        return '#F4BF32';
      default:
        return '#56B8EF';
    }
  }};

  width: 50px;
  padding: 3px;
  border-radius: 3px;
  text-align: center;

  font-family: Poppins, sans-serif;
  font-size: 0.69rem;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 127.273% */
  text-transform: uppercase;

  margin: auto;
`;

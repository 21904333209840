import React, { useCallback, FormEvent, useEffect, useState } from 'react';
import PageWrapper, {
  MobilePageWrapper,
} from 'templates/PageWrapper/PageWrapper';
import { Alert, CardActions, Button, Box } from '@mui/material';
import {
  ContainedBtn,
  PasswordInput,
  BasicInput,
  OutlinedBtn,
  MobileAccountPageWrapper,
  MobilePasswordContent,
} from './AccountPage.styles';
import SettingsPageWrapper from 'utils/styles/SettingsPageWrapper.styles';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import {
  validConfirmPassword,
  validName,
  validPassword,
} from 'utils/helpers/formValidators';
import { useBaoSelector } from 'utils/hooks/redux';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { PASSWORD_CONFIRM_ERROR_MESSAGE } from 'utils/constants/form';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { AccountDetail } from 'molecules/AccountDetail';
import { DesktopAccountDetail } from 'molecules/AccountDetail/DesktopAccountDetail';
import CardWithActions from 'molecules/Card/CardWithActions';
import {
  StyledCardActions,
  StyledCardCancelAction,
  StyledCardContent,
  StyledCardSubmitAction,
} from 'molecules/Card/Card.styles';

interface ICompany {
  active_since?: string;
  last_login?: string;
  currency?: string;
  country?: string;
  payment_terms?: string;
  gcp_billing_account_name?: string;
  gcp_billing_id?: string;
  discount?: number;
}

const passwordFormInitial = {
  oldPassword: { value: '', error: '' },
  password: { value: '', error: '' },
  confirmPassword: { value: '', error: '' },
};

const AccountPage = () => {
  const { width } = useWindowDimensions();

  const { currentCompany } = useBaoSelector((state) => state.common);

  const [showCredentialsCaptcha, setShowCredentialsCaptcha] =
    useState<boolean>(false);
  const [showPasswordCaptcha, setShowPasswordCaptcha] =
    useState<boolean>(false);
  const [personalInfoForm, setPersonalInfoForm] = useState({
    firstName: { value: '', error: '' },
    lastName: { value: '', error: '' },
    email: '',
    companyName: '',
  });
  const [companyInfo, setCompanyInfo] = useState<ICompany>({});
  const [showPass, setShowPass] = useState<boolean>(false);

  const [infoResponse, setInfoResponse] = useState({
    error: false,
    message: '',
  });
  const [passwordResponse, setPasswordResponse] = useState({
    error: false,
    message: '',
  });
  const { value: user } = useBaoSelector((state) => state.user);
  const isAdmin = user.userType === SUPER_ADMIN || user.userType === ADMIN;

  const [passwordForm, setPasswordForm] = useState(passwordFormInitial);
  // const [activities, setActivities] = useState([
  //   { browser: '', city: '', country: '', createdAt: '', os: '' },
  // ]);

  useEffect(() => {
    // todo add catch block and handle the error
    axios.get(`${process.env.REACT_APP_USER_SERVICE}/me`).then(({ data }) => {
      setPersonalInfoForm((prevState) => ({
        firstName: {
          ...prevState.firstName,
          value: data.name,
        },
        lastName: {
          ...prevState.lastName,
          value: data.surname,
        },
        email: data.email,
        companyName: data.company_name ? data.company_name : '',
      }));
    });
  }, []);

  useEffect(() => {
    if (!currentCompany) return;

    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company/activity-info`, {
        params: {
          company_hs_id: currentCompany?.company_hs_id,
        },
      })
      .then((response) => setCompanyInfo(response.data as ICompany));

    // todo add catch block and handle error
    // axios
    //   .get(`${process.env.REACT_APP_USER_SERVICE}/dashboard/activity/info`, {
    //     params: {
    //       user_id: user.userId,
    //     },
    //   })
    //   .then(({ data }) => {
    //     setActivities(
    //       data.map((item: (typeof activities)[0]) =>
    //         mapKeys(item, (v, k) => camelCase(k))
    //       )
    //     );
    //   });
  }, [user.userId]);

  const handlePersonalInfoFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const updatedPersonalInfo = cloneDeep(personalInfoForm);
    if (!validName(personalInfoForm.firstName.value)) {
      updatedPersonalInfo.firstName.error = 'Please enter your first name';
    }
    if (!validName(personalInfoForm.lastName.value)) {
      updatedPersonalInfo.lastName.error = 'Please enter your last name';
    }
    if (
      updatedPersonalInfo.firstName.error ||
      updatedPersonalInfo.lastName.error
    ) {
      return setPersonalInfoForm(updatedPersonalInfo);
    }

    setShowCredentialsCaptcha(true);
    return null;
  };

  const handlePersonalInfoFormChange = (
    value: string,
    field: 'firstName' | 'lastName'
  ) => {
    const updatedPersonalInfo = cloneDeep(personalInfoForm);
    updatedPersonalInfo[field].value = value;
    if (validName(updatedPersonalInfo[field].value)) {
      updatedPersonalInfo[field].error = '';
    }
    setPersonalInfoForm(updatedPersonalInfo);
  };

  const handlePasswordFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const updatedForm = cloneDeep(passwordForm);
    // if (!validPassword(passwordForm.password.value)) {
    //   updatedForm.password.error = PASSWORD_ERROR_MESSAGE;
    // }
    if (
      !validConfirmPassword(
        passwordForm.password.value,
        passwordForm.confirmPassword.value
      )
    ) {
      updatedForm.confirmPassword.error = PASSWORD_CONFIRM_ERROR_MESSAGE;
    }
    if (!passwordForm.oldPassword.value) {
      updatedForm.oldPassword.error = 'Please enter your old password';
    }
    // if we have an error don't continue submitting form
    if (
      Boolean(updatedForm.password.error) ||
      Boolean(updatedForm.confirmPassword.error) ||
      Boolean(updatedForm.oldPassword.error)
    ) {
      return setPasswordForm(updatedForm);
    }

    setShowPasswordCaptcha(true);
    return null;
  };

  const hideCaptcha = useCallback(() => {
    setShowCredentialsCaptcha(false);
    setShowPasswordCaptcha(false);
  }, []);

  const handleUpdateCredentials = useCallback(() => {
    setShowCredentialsCaptcha(false);
    axios
      .put(`${process.env.REACT_APP_USER_SERVICE}/update`, {
        name: personalInfoForm.firstName.value,
        surname: personalInfoForm.lastName.value,
      })
      .then((res) => {
        if (res.status === 200) {
          setInfoResponse({ error: false, message: 'Updated Successfully!' });
        } else {
          setInfoResponse({ error: true, message: 'Error occurred!' });
        }
      })
      .catch((err) => {
        setInfoResponse({ error: true, message: err.response.data.message });
      });
  }, [personalInfoForm]);

  const handleUpdatePassword = useCallback(() => {
    setShowPasswordCaptcha(false);
    axios
      .patch(`${process.env.REACT_APP_USER_SERVICE}/change-password`, {
        old_password: passwordForm.oldPassword.value,
        password: passwordForm.password.value,
      })
      .then(({ data }) => {
        setPasswordResponse({ error: false, message: data.message });
        setPasswordForm(passwordFormInitial);
        if (width < 600) {
          setTimeout(() => {
            onCloseForm();
          }, 1500);
        }
      })
      .catch((err) => {
        setPasswordResponse({
          error: true,
          message: err.response.data.message,
        });
      });
  }, [passwordForm]);

  const handlePasswordFormChange = (
    value: string,
    field: keyof typeof passwordForm
  ) => {
    const updatedPasswordForm = cloneDeep(passwordForm);
    updatedPasswordForm[field].value = value;
    if (field === 'password' && validPassword(value)) {
      updatedPasswordForm.password.error = '';
    } else if (
      field === 'confirmPassword' &&
      validConfirmPassword(
        updatedPasswordForm.password.value,
        updatedPasswordForm.confirmPassword.value
      )
    ) {
      updatedPasswordForm.confirmPassword.error = '';
    } else if (field === 'oldPassword' && value) {
      updatedPasswordForm.oldPassword.error = '';
    }
    setPasswordForm(updatedPasswordForm);
  };

  const onCloseForm = useCallback(() => {
    setShowPass(false);
    setPasswordResponse({ error: false, message: '' });
    setPasswordForm(passwordFormInitial);
  }, []);

  if (width < 600 && !isAdmin) {
    const details = [
      {
        title: 'Full Name',
        value: `${personalInfoForm.firstName.value} ${personalInfoForm.lastName.value}`,
      },
      { title: 'Email', value: personalInfoForm.email },
      { title: 'Company', value: currentCompany?.name || '' },
      {
        title: 'GCP Account Name',
        value: companyInfo.gcp_billing_account_name,
      },
      { title: 'GCP Billing ID', value: companyInfo.gcp_billing_id },
      { title: 'Payment Terms', value: companyInfo.payment_terms },
      {
        title: 'Zazmic Discount',
        subtitle: 'Applied on GCP invoices',
        value: `${companyInfo.discount || '0'}%`,
      },
    ];

    if (showPass) {
      return (
        <MobilePageWrapper
          title={
            <MobileInvoicesTitle variant="h2" my={3}>
              Change password
            </MobileInvoicesTitle>
          }
        >
          <MobilePasswordContent>
            {passwordResponse.message && (
              <Alert severity={passwordResponse.error ? 'error' : 'success'}>
                {passwordResponse.message}
              </Alert>
            )}
            <form noValidate onSubmit={handlePasswordFormSubmit}>
              <input
                hidden
                type="text"
                name="username"
                autoComplete="username"
              />
              <PasswordInput
                autoComplete="current-password"
                fullWidth
                variant="outlined"
                label="Current Password"
                margin="normal"
                value={passwordForm.oldPassword.value}
                error={!!passwordForm.oldPassword.error}
                helperText={passwordForm.oldPassword.error}
                onChange={(event) =>
                  handlePasswordFormChange(event.target.value, 'oldPassword')
                }
              />
              <PasswordInput
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                label="New Password"
                margin="normal"
                value={passwordForm.password.value}
                error={!!passwordForm.password.error}
                helperText={passwordForm.password.error}
                onChange={(event) =>
                  handlePasswordFormChange(event.target.value, 'password')
                }
              />
              <PasswordInput
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                label="Confirm New Password"
                margin="normal"
                value={passwordForm.confirmPassword.value}
                error={!!passwordForm.confirmPassword.error}
                helperText={passwordForm.confirmPassword.error}
                onChange={(event) =>
                  handlePasswordFormChange(
                    event.target.value,
                    'confirmPassword'
                  )
                }
              />
              <CardActions sx={{ pl: 0, pr: 0, mt: 1 }}>
                <OutlinedBtn variant="outlined" onClick={onCloseForm}>
                  Cancel
                </OutlinedBtn>
                <ContainedBtn variant="contained" type="submit">
                  Confirm
                </ContainedBtn>
              </CardActions>
            </form>
          </MobilePasswordContent>

          {showPasswordCaptcha ? (
            <CaptchaModal
              onComplete={handleUpdatePassword}
              onClose={hideCaptcha}
              className={width > 600 ? '' : 'mobile-modal'}
            />
          ) : (
            <span />
          )}
        </MobilePageWrapper>
      );
    }

    return (
      <MobilePageWrapper
        title={
          <MobileInvoicesTitle variant="h2" my={3}>
            Account details
          </MobileInvoicesTitle>
        }
      >
        <MobileAccountPageWrapper>
          <Box>
            <Button
              variant="text"
              size="medium"
              onClick={() => setShowPass(true)}
            >
              Change password
            </Button>
          </Box>
          {details.map(({ title, subtitle, value }) => (
            <AccountDetail
              key={title}
              title={title}
              subtitle={subtitle}
              value={value as string}
            />
          ))}
        </MobileAccountPageWrapper>
      </MobilePageWrapper>
    );
  }

  const customerDetails = [
    {
      title: 'Full Name',
      value: `${personalInfoForm.firstName.value} ${personalInfoForm.lastName.value}`,
      isHidden: false,
    },
    { title: 'Email', value: personalInfoForm.email, isHidden: false },
    { title: 'Company', value: currentCompany?.name || '', isHidden: false },
    {
      title: 'Payment Terms',
      subtitle: 'Applied On GCP Invoices',
      value: companyInfo.payment_terms,
      isHidden: false,
    },
    {
      title: 'GCP Account Name',
      value: companyInfo.gcp_billing_account_name,
      isHidden: !companyInfo.gcp_billing_account_name,
    },
    {
      title: 'GCP Billing ID',
      value: companyInfo.gcp_billing_id,
      isHidden: !companyInfo.gcp_billing_account_name,
    },
    {
      title: 'Zazmic Discount',
      subtitle: 'Applied on GCP invoices',
      value: `${companyInfo.discount || '0'}%`,
      isHidden: !companyInfo.gcp_billing_account_name,
    },
  ];

  return (
    <PageWrapper title="Account Details">
      <SettingsPageWrapper>
        <CardWithActions title="Personal information" avatar={<CardAvatar />}>
          {!isAdmin ? (
            <StyledCardContent>
              {customerDetails
                .filter(({ isHidden }) => !isHidden)
                .map(({ title, subtitle, value }) => (
                  <DesktopAccountDetail
                    key={title}
                    title={title}
                    subtitle={subtitle}
                    value={value as string}
                  />
                ))}
            </StyledCardContent>
          ) : (
            <form noValidate onSubmit={handlePersonalInfoFormSubmit}>
              <StyledCardContent>
                {infoResponse.message && (
                  <Alert severity={infoResponse.error ? 'error' : 'success'}>
                    {infoResponse.message}
                  </Alert>
                )}
                <BasicInput
                  autoComplete="given-name"
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  margin="normal"
                  disabled={!isAdmin}
                  value={personalInfoForm.firstName.value}
                  error={!!personalInfoForm.firstName.error}
                  helperText={personalInfoForm.firstName.error}
                  onChange={(event) =>
                    handlePersonalInfoFormChange(
                      event.target.value,
                      'firstName'
                    )
                  }
                />
                <BasicInput
                  autoComplete="family-name"
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  margin="normal"
                  disabled={!isAdmin}
                  value={personalInfoForm.lastName.value}
                  error={!!personalInfoForm.lastName.error}
                  helperText={personalInfoForm.lastName.error}
                  onChange={(event) =>
                    handlePersonalInfoFormChange(event.target.value, 'lastName')
                  }
                />
                <BasicInput
                  autoComplete="email"
                  fullWidth
                  disabled
                  variant="outlined"
                  label="Email"
                  margin="normal"
                  value={personalInfoForm.email}
                />
                {!isAdmin && (
                  <BasicInput
                    autoComplete="company-name"
                    fullWidth
                    disabled
                    variant="outlined"
                    label="Company Name"
                    margin="normal"
                    value={personalInfoForm.companyName}
                  />
                )}
              </StyledCardContent>

              {isAdmin && (
                <StyledCardActions>
                  <StyledCardSubmitAction variant="contained" type="submit">
                    Save
                  </StyledCardSubmitAction>
                </StyledCardActions>
              )}
            </form>
          )}
        </CardWithActions>

        <CardWithActions title="Change password" avatar={<CardAvatar />}>
          <form
            noValidate
            onSubmit={handlePasswordFormSubmit}
            style={{ maxWidth: '80%' }}
          >
            <StyledCardContent>
              {passwordResponse.message && (
                <Alert severity={passwordResponse.error ? 'error' : 'success'}>
                  {passwordResponse.message}
                </Alert>
              )}
              <input
                hidden
                type="text"
                name="username"
                autoComplete="username"
              />
              <PasswordInput
                autoComplete="current-password"
                fullWidth
                variant="outlined"
                label="Current Password"
                margin="normal"
                value={passwordForm.oldPassword.value}
                error={!!passwordForm.oldPassword.error}
                helperText={passwordForm.oldPassword.error}
                onChange={(event) =>
                  handlePasswordFormChange(event.target.value, 'oldPassword')
                }
              />
              <PasswordInput
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                label="New Password"
                margin="normal"
                value={passwordForm.password.value}
                error={!!passwordForm.password.error}
                helperText={passwordForm.password.error}
                onChange={(event) =>
                  handlePasswordFormChange(event.target.value, 'password')
                }
              />
              <PasswordInput
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                label="Confirm New Password"
                margin="normal"
                value={passwordForm.confirmPassword.value}
                error={!!passwordForm.confirmPassword.error}
                helperText={passwordForm.confirmPassword.error}
                onChange={(event) =>
                  handlePasswordFormChange(
                    event.target.value,
                    'confirmPassword'
                  )
                }
              />
            </StyledCardContent>
            <StyledCardActions>
              <StyledCardCancelAction variant="outlined">
                Cancel
              </StyledCardCancelAction>
              <StyledCardSubmitAction variant="contained" type="submit">
                Save
              </StyledCardSubmitAction>
            </StyledCardActions>
          </form>
        </CardWithActions>
      </SettingsPageWrapper>

      {showCredentialsCaptcha ? (
        <CaptchaModal
          onComplete={handleUpdateCredentials}
          onClose={hideCaptcha}
        />
      ) : (
        <span />
      )}

      {showPasswordCaptcha ? (
        <CaptchaModal onComplete={handleUpdatePassword} onClose={hideCaptcha} />
      ) : (
        <span />
      )}
    </PageWrapper>
  );
};

export default AccountPage;

import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledInvoiceStatus = styled(Typography)<{
  $status?: 'paid' | 'unpaid';
}>`
  && {
    color: ${({ $status }) => ($status === 'paid' ? '#47C26A' : '#8C8C8C')};

    /* New/Statuses */
    font-family: Poppins, sans-serif;
    font-size: 0.69rem;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    text-align: center;
  }
`;

import styled from 'styled-components';

export const FooterComp = styled.div`
  margin-top: 50px;
  border: 1px solid #ecedef;
  display: flex;

  @media (max-width: 600px) {
    margin-top: 0;
    background: #fff;
    box-shadow: 0px -3px 5px 0px rgba(237, 237, 237, 0.5);

    p {
      color: var(--neutrals-400, #a1a8b2);
      font-size: 1rem;
      font-weight: 700;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  justify-content: flex-end;
`;

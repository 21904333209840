function padLeft(chr: number) {
  return chr < 10 ? `0${chr}` : chr;
}

export const dateSlices = (date: string | Date) => {
  const d = new Date(date);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dd = padLeft(d.getDate());
  const MM = padLeft(d.getMonth() + 1);
  const yyyy = padLeft(d.getFullYear());
  const HH = padLeft(d.getHours());
  const mm = padLeft(d.getMinutes());
  const ddMMyy = `${months[d.getMonth()]} ${dd}, ${yyyy}`;
  const HHmm = `${HH}:${mm}`;

  return {
    dd,
    MM,
    yyyy,
    HH,
    mm,
    ddMMyy,
    HHmm,
  };
};

export const formatDate = (date: string, options: { time: string }) => {
  if (date) {
    const { ddMMyy, HHmm } = dateSlices(date);
    return options?.time ? `${ddMMyy} ${HHmm}` : ddMMyy;
  }
  return '';
};

export const formatMS = (ms?: number) => {
  if (!ms) return '';

  const minutes = Math.floor(ms / 60000);
  const seconds = ((ms % 60000) / 1000).toFixed(0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const convertDate = (date: string, options: { time: string }) => {
  const d = new Date(date);
  const { dd, MM, yyyy, HH, mm } = dateSlices(d);
  return options?.time
    ? `${yyyy}-${MM}-${dd}T${HH}:${mm}`
    : `${yyyy}-${MM}-${dd}`;
};

export const getNumberOfDays = (start: string, end: string) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays > 0 ? diffInDays : 1;
};

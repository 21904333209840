import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { palette } from 'utils/styles/variables';
import { neutrals } from 'utils/styles/color';

export const StyledInput = styled(TextField)`
  && {
    display: flex;
    width: 40px;
    height: 32px;

    justify-content: center;
    align-items: center;
    gap: 10px;

    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    .MuiOutlinedInput-root {
      width: 40px;
      height: 32px;

      padding: 0;
      text-align: center;
    }
  }
`;

export const StyledTableButton = styled(Button)`
  && {
    display: flex;
    padding: 2px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 3px;
    background: ${palette.primary.main};

    color: #fff;
    text-align: justify;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;

    &:hover {
      background-color: ${palette.primary.main};
    }
  }
`;

export const StyledSwitchButton = styled.div<{ $active: boolean }>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  }
`;

import React, { FC, ReactElement, useState, useRef } from 'react';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import { ReactComponent as DotsIcon } from 'assets/icons/ThreeDots.svg';
import {
  StyledItemText,
  StyledMenuItem,
} from 'molecules/ButtonMenu/ButtonMenu.styles';

const ButtonMenu: FC<{
  options: {
    icon?: ReactElement;
    label: string;
    value: string;
  }[];
  onSelect: (value: string) => void;
}> = ({ options, onSelect }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (option: {
    icon?: ReactElement;
    label: string;
    value: string;
  }) => {
    onSelect(option.value);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <DotsIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{
          zIndex: 1,
          top: '10px',
        }}
        role={undefined}
        popperOptions={{
          placement: 'bottom-end',
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ py: '4px' }}
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {options.map((option) => (
                    <StyledMenuItem
                      key={option.value}
                      onClick={() => handleItemClick(option)}
                    >
                      {option.icon && option.icon}
                      <StyledItemText>{option.label}</StyledItemText>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ButtonMenu;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { StyledInvoiceTableDetails } from './InvoiceTableStat.styles';

interface InvoiceTableState {
  totalCount: number;
  totalAmount: number;
}

const InvoiceTableStat = ({
  details,
  currency,
  totalHidden,
}: {
  details: InvoiceTableState | null;
  currency?: string;
  totalHidden?: boolean;
}) => {
  if (!details) return null;

  const { totalCount, totalAmount } = details;

  return (
    <StyledInvoiceTableDetails>
      <Box>
        <Typography variant="body2">Invoices:</Typography>
        <Typography variant="body2">{totalCount || 0}</Typography>
      </Box>
      {!totalHidden && (
        <Box>
          <Typography variant="body2">Total:</Typography>
          <Typography variant="body2">
            {totalAmount
              ? currencyFormatter(Number(totalAmount), currency)
              : currencyFormatter(0, currency)}
          </Typography>
        </Box>
      )}
    </StyledInvoiceTableDetails>
  );
};

export default InvoiceTableStat;

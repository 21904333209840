import React, { FC, useState, useCallback, useEffect } from 'react';

import Table from 'molecules/Table';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { CalendarValue } from 'molecules/Calendar';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';

import dayjs from 'dayjs';

export interface LogRow {
  created_at: string;
  emails: string;
  subject: string;
}

export const initialValue = { page: 1, limit: 20, count: 0 };

const LogTable: FC<{
  fetch: (f: typeof initialValue) => Promise<{ logs: LogRow[]; count: number }>;
  email?: string;
  range?: CalendarValue;
}> = ({ fetch, email, range }) => {
  const [pagination, setPagination] = useState(initialValue);
  const [data, setData] = useState<LogRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };

      const { logs, count } = await fetch(nextState);

      setPagination({ ...nextState, count });
      setData(logs);
    },
    [pagination, range, email]
  );

  const handleChangeRowsPerPage = useCallback(
    async (option: number) => {
      const nextState = {
        ...pagination,
        limit: option,
        page: 1,
      };

      const { logs, count } = await fetch(nextState);

      setPagination({ ...nextState, count });
      setData(logs);
    },
    [range, email]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { logs, count } = await fetch({ ...pagination, page: 1 });

      setLoading(false);
      setPagination({ ...pagination, count });
      setData(logs.map(setRowKey));
    })();
  }, [email, range]);

  function setRowKey(row: LogRow, index: number) {
    return { ...row, id: `${row.created_at}-${index}` };
  }

  const columns = [
    {
      key: 'created_at',
      label: 'Date',
      sortable: false,
      width: '15%',
      render: ({ created_at }: LogRow) => (
        <StyledCellText>
          {dayjs(dayjs(created_at)).format('DD MMM YYYY')}
        </StyledCellText>
      ),
    },
    {
      key: 'emails',
      label: 'Receivers List',
      sortable: false,
      width: '35%',
      render: ({ emails }: LogRow) => {
        if (!emails) return '';

        return (
          <StyledCellContainer $vStack>
            {emails.split(', ').map((item: string) => (
              <StyledCellText key={item}>{item}</StyledCellText>
            ))}
          </StyledCellContainer>
        );
      },
    },
    {
      key: 'subject',
      label: 'Email Subjects',
      sortable: false,
      render: ({ subject }: LogRow) => (
        <StyledCellText>{subject}</StyledCellText>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <Table
        data={data}
        columns={columns}
        idKey="id"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        loading={loading}
      />
      <StyledTablePagination
        labelDisplayedRows={() => null}
        rowsPerPageOptions={[]}
        count={pagination.count}
        rowsPerPage={pagination.limit}
        page={pagination.count ? pagination.page - 1 : 0}
        onPageChange={handleChangePage}
        ActionsComponent={(props) => (
          <PaginationActions
            {...props}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      />
    </StyledTableContainer>
  );
};

export default LogTable;

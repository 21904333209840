import styled from 'styled-components';

export const PaymentSteps = styled.div`
  .MuiStep-root {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

import React from 'react';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from 'molecules/Table/styles';
import loaderGif from 'assets/images/loaderGif.gif';

const LoadingRow = () => (
  <TableRow>
    <StyledTableCell colSpan={20} sx={{ textAlign: 'center' }}>
      <img src={loaderGif} alt="Data loading" />
    </StyledTableCell>
  </TableRow>
);

export default LoadingRow;

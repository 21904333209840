import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const TextFieldCustomized = styled(TextField)(() => ({
  '& label.Mui-focused': {
    color: '#1890ff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#1890ff',
  },
  '& .MuiOutlinedInput-root': {
    padding: '7px 8px !important',
    '& fieldset': {
      // borderColor: '#1890ff',
    },
    '&:hover fieldset': {
      // borderColor: '#1890ff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#1890ff',
    },
    '& fieldset legend span': {
      fontSize: '0.65rem',
    },
  },
  '& input': {
    fontSize: '0.875rem',
    lineHeight: '18px',
  },
  '& label': {
    fontSize: '0.875rem',
  },
}));

import React, { FC } from 'react';
import { ModalActions } from 'molecules/Modal/Modal.styles';
import { Stack } from '@mui/material';
import Link from 'atoms/Link/Link';
import Modal from 'molecules/Modal';

const AccountNotVerifiedModal: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => (
  <Modal
    open={open}
    maxWidth="md"
    title="Verify your bank account"
    subtitle={
      <>
        To verify your account, please check your email for a verification link
        that Stripe have sent you. You will need to click on the link and follow
        the instructions to complete the verification process. This will only
        take a few minutes of your time. <br /> <br />
        We appreciate your cooperation and understanding. If you have any
        questions or issues, please feel free to contact our customer support
        team. We are always happy to help. <br /> <br />
        Thank you for choosing Zazmic-Connect. Have a wonderful day! blush
      </>
    }
    onClose={onClose}
    sx={{ zIndex: 32000 }}
  >
    <>
      <ModalActions>
        <Stack sx={{ p: '8px 0', alignItems: 'flex-end' }}>
          <Link
            $fontSize="13px"
            target="_blank"
            to="https://support.stripe.com/questions/not-receiving-email-sent-from-stripe"
          >
            Still have not received Stripe Email?
          </Link>
        </Stack>
      </ModalActions>
    </>
  </Modal>
);

export default AccountNotVerifiedModal;

import styled from 'styled-components';
import { Stack } from '@mui/material';
import { palette } from 'utils/styles/variables';
import { neutrals } from 'utils/styles/color';

export const MobileCloudOpsItem = styled(Stack)`
  && {
    .MuiBox-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .MuiBox-root:first-of-type {
      span {
        color: #484e56;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        text-transform: capitalize;
      }
      button {
        padding: 0;
        min-width: auto;
        color: ${palette.primary.main};
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 15.5px; /* 129.167% */
        text-decoration-line: underline;
      }
    }
    .MuiBox-root:last-of-type {
      span {
        color: ${neutrals[600]};
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        text-transform: capitalize;
      }
      span:last-of-type {
        font-weight: 500;
      }
    }
  }
`;

import styled from 'styled-components';
import { breakpoints } from 'utils/styles/variables';

export const PageHeaderWrapper = styled.div.attrs(
  (props: { alignItems: string }) => props
)`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'baseline')};

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;

    > div:last-child {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

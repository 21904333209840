import styled from 'styled-components';
import PasswordTextField from 'atoms/PasswordTextField/PasswordTextField';
import { Stack, Button, TextField, CardContent } from '@mui/material';

export const ContainedBtn = styled(Button)`
  background-color: #6f9fe9 !important;
  color: #fff !important;
`;

export const OutlinedBtn = styled(Button)`
  border-color: #6f9fe9 !important;
  color: #6f9fe9 !important;
`;

export const BasicInput = styled(TextField)`
  & label.Mui-focused {
    color: #6f9fe9;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #6f9fe9;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #6f9fe9;
    }
  }
`;

export const PasswordInput = styled(PasswordTextField)`
  & label.Mui-focused {
    color: #6f9fe9;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #6f9fe9;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #6f9fe9;
    }
  }
`;

export const MobileAccountPageWrapper = styled(Stack)`
  && {
    && > .MuiBox-root {
      display: flex;
      justify-content: flex-end;
      padding: 10px 16px;
      button {
        font-size: 0.875rem;
        text-decoration: underline;
        padding: 0;
      }
    }
  }
`;

export const MobilePasswordContent = styled(CardContent)`
  && {
    & .MuiInputBase-formControl {
      height: 40px;
    }

    & .MuiFormLabel-filled.MuiInputLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }
    & .MuiInputLabel-outlined {
      transform: translate(14px, 12px) scale(1);
    }
    & .Mui-focused.MuiInputLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }

    & .MuiFormHelperText-root {
      font-size: 0.875rem;
    }

    & .MuiCardActions-root {
      button {
        flex: 1;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
`;

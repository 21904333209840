import React, { FC } from 'react';
import { Table } from '@mui/material';
import { ITableProps } from 'molecules/Table/types';
import TableHead from 'molecules/Table/TableHead';
import TableBody from 'molecules/Table/TableBody';

const CustomTable: FC<ITableProps> = ({
  idKey,
  hover,
  loading,
  size,
  borderless,
  order,
  orderBy,
  onRequestSort,
  columns,
  data,
  collapsible,
  collapsedRow,
  onRowClick,
  rowDetailsRenderer,
  emptyText,
  ...props
}) => (
  <Table {...props}>
    <TableHead
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
      columns={columns}
      borderless={borderless}
    />
    <TableBody
      size={size}
      hover={hover}
      borderless={borderless}
      loading={loading}
      columns={columns}
      data={data}
      idKey={idKey}
      collapsible={collapsible}
      collapsedRow={collapsedRow}
      onRowClick={onRowClick}
      rowDetailsRenderer={rowDetailsRenderer}
      emptyText={emptyText}
    />
  </Table>
);

export default CustomTable;

import styled from 'styled-components';
import backgroundBrown from 'assets/images/background-auth.jpeg';
import { breakpoints } from 'utils/styles/variables';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const AuthPageWrapper = styled(Grid)`
  display: flex;
  overflow: hidden;

  position: relative;

  @media (max-width: 900px) {
    overflow: auto;
  }
`;

export const GridItem = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
    background: url(${backgroundBrown});
    background-size: cover;
    font-family: Montserrat, sans-serif;

    @media (min-width: ${breakpoints.xl}) {
      p {
        font-size: 1.2rem;
      }
    }

    @media (min-width: ${breakpoints.md}) {
      h2 {
        font-size: 2.2rem;
        margin-bottom: 2rem;
      }
    }

    @media (max-width: ${breakpoints.sm}) {
      padding: 20% 10% 40%;
    }

    @media (max-width: 900px) {
      position: relative;
    }

    @media (max-width: 480px) {
      position: relative;
      padding: 20% 10% 50%;
    }
  }
`;

export const AuthTitleWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: start;

  span {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 1.15rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
  }
`;

/* ===== Mobile layout styles ===== */
export const MobileAuthPageWrapper = styled(Stack)`
  display: flex;
  padding: 12px 24px 0;

  flex: 1;

  & .MuiInputBase-formControl {
    height: 40px;
  }

  & .MuiFormLabel-filled.MuiInputLabel-root {
    transform: translate(14px, -6px) scale(0.75);
  }
  & .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  & .Mui-focused.MuiInputLabel-root {
    transform: translate(14px, -6px) scale(0.75);
  }

  & .MuiFormHelperText-root {
    font-size: 0.875rem;
  }

  .form-actions {
    .MuiTypography-root,
    a {
      font-size: 1rem;
    }
  }
  .form-footer,
  .form-footer a {
    text-align: right;
    font-size: 1rem;
  }

  button.MuiButton-root {
    font-size: 1.175rem;
  }

  .mobile-subheader {
    font-size: 1.175rem;
  }
`;

export const MobileAuthTitleWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: start;

  margin-bottom: 5vh;

  span {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */

    margin-bottom: 27px;
  }
`;

export const MobileAuthFormSubheader = styled(Typography)`
  && {
    height: 80px;
    display: flex;
    align-items: end;
    margin-bottom: 5vh;

    color: #31363e;
    font-family: 'Poppins', sans-serif;
    font-size: 1.175rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  && .empty-subheader {
    height: 0;
  }
`;

export const MobileImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

  margin-top: 16px;

  img {
    height: 27vh;
  }
`;

export const ZazmicConnectLogo = styled.img`
  width: 15rem;
  padding: 1rem 0 3rem;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;

  width: 25%;

  transform: translateX(45%);

  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 40%;
  }

  @media (max-width: 480px) {
    width: 60%;
    transform: translateX(25%);
  }

  @media (max-width: 320px) {
    width: 60%;
    transform: translateX(25%);
  }
`;

export const FormGrid = styled(Grid)`
  h2 {
    font-size: 2.1rem;
  }
  p {
    max-width: 480px;
  }
`;

export const Form = styled.form`
  max-width: 480px;
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  p {
    font-size: 1.12rem;
    padding-left: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 1.2rem;
  }
`;

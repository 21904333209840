import React from 'react';

import { Container } from '@mui/material';
import { FlexBox, StyledHeader } from './Header.styles';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

import { ReactComponent as ZazmicConnectLogoDark } from 'assets/images/zazmic-connect-logo.svg';

const EmptyHeader = () => {
  const { width } = useWindowDimensions();

  if (width >= 600) return <span />;

  return (
    <StyledHeader>
      <Container>
        <FlexBox>
          <a
            href="https://www.zazmic.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ZazmicConnectLogoDark />
          </a>
        </FlexBox>
      </Container>
    </StyledHeader>
  );
};

export default EmptyHeader;

import React, { FC, useCallback } from 'react';
import axios from 'axios';
import { UserRow } from 'organisms/CompanyDetailsCard/CompanyContacts';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';

const MainContactDialog: FC<{
  company_hs_id: string;
  user: UserRow;
  onClose: () => void;
}> = ({ company_hs_id, user, onClose }) => {
  const handleSubmit = useCallback(async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE}/update/main-contact`,
        {
          is_main: !user.is_main,
          // eslint-disable-next-line
          // @ts-ignore
          company_hs_id: Number(company_hs_id),
          user_id: user.id,
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    onClose();
  }, [user, company_hs_id]);

  return (
    <Modal
      open
      padding24
      maxWidth="sm"
      title="Change main contact for Quickbooks integration?"
      subtitle={`
        The main contact serves as the primary point of communication and is
        vital for syncing essential data with QuickBooks. This includes fields
        such as Full Name and Email. Any modifications made to the main contact
        will automatically trigger an update of these fields in QuickBooks.
      `}
      onClose={onClose}
    >
      <ModalActions $padding24>
        <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
          Cancel
        </ModalCloseButton>
        <ModalSubmitButton variant="contained" onClick={handleSubmit}>
          Change
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
};

export default MainContactDialog;

import { UserCompany } from 'store/user/userSlice';

const getCompany = (): UserCompany | null => {
  try {
    const companyString = sessionStorage.getItem('company');

    if (companyString) {
      return JSON.parse(companyString);
    }

    return null;
  } catch {
    return null;
  }
};

const setCompany = (company: UserCompany): void => {
  if (!company) return;
  sessionStorage.setItem('company', JSON.stringify(company));
};

export { getCompany, setCompany };

import { useBaoDispatch } from 'utils/hooks/redux';
import { logout } from 'store/user/userSlice';

const useLogout = () => {
  const dispatch = useBaoDispatch();

  return () => {
    document.body.style.display = 'none';
    const { host } = window.location;
    /* If host is DEV OR STAGING, then no need to redirect to zazmic main page */
    if (
      host.indexOf('stg') >= 0 ||
      host.indexOf('dev') >= 0 ||
      host.indexOf('localhost') >= 0
    ) {
      window.location.reload();
    } else {
      window.location.href = 'https://www.zazmic.com/';
    }

    setTimeout(() => {
      dispatch(logout());
    }, 1);
  };
};

export default useLogout;

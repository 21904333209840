import React, { FC, ReactElement } from 'react';
import { Breakpoint, DialogProps, Stack } from '@mui/material';

import {
  ModalWrapper,
  ModalTitle,
  ModalTitleCloseButton,
  ModalTitleText,
  ModalSubtitleText,
} from 'molecules/Modal/Modal.styles';
import CloseIcon from '@mui/icons-material/Close';

interface IModalProps extends DialogProps {
  open: boolean;
  maxWidth: Breakpoint;
  title: string;
  subtitle?: string | ReactElement;
  padding24?: boolean;
  onClose?: () => void;
  children: ReactElement;
}

const Modal: FC<IModalProps> = ({
  open,
  maxWidth,
  padding24,
  title,
  subtitle,
  onClose,
  children,
  ...props
}) => (
  <ModalWrapper $width={maxWidth} open={open} maxWidth={maxWidth} {...props}>
    <ModalTitle as={Stack} $padding24={padding24}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        rowGap="16px"
      >
        <ModalTitleText>{title}</ModalTitleText>
        {onClose && (
          <ModalTitleCloseButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </ModalTitleCloseButton>
        )}
      </Stack>

      {subtitle && <ModalSubtitleText>{subtitle}</ModalSubtitleText>}
    </ModalTitle>

    {children}
  </ModalWrapper>
);

export default Modal;

import styled from 'styled-components';
import { breakpoints } from 'utils/styles/variables';

export default styled.div`
  display: flex;
  gap: 10px;
  > .MuiCard-root {
    flex-basis: 50%;
  }

  .MuiCardHeader-title {
    &.MuiTypography-h5 {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .MuiCardHeader-subheader.MuiTypography-body1 {
    margin-top: ${({ theme }) => theme.spacing(2)};
    font-size: 1rem;
    color: #666666;
  }

  @media (min-width: ${breakpoints.md}) {
    > .MuiCard-root {
      flex-basis: 50%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    > .MuiCard-root {
      flex-basis: 100%;
    }
  }
`;

import styled from 'styled-components';
import { Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const IconTextFieldWrapper = styled(Box)`
  && {
    display: flex;
    align-items: end;
    height: 26px;

    & > .MuiButtonBase-root {
      width: 24px;
      height: 24px;
      padding: 4px;
    }

    .MuiButtonBase-root.MuiButtonBase-root:hover {
      background-color: #f6faff !important;
    }

    .MuiInput-root {
      width: 100%;
      .MuiInput-input {
        ::placeholder {
          font-size: 0.75rem !important;
          color: ${neutrals[500]};
          padding-bottom: 4px;
        }
      }
      .MuiInputAdornment-root {
        width: 20px;
        height: 20px;
        .MuiButtonBase-root {
          width: 20px;
          height: 20px;
        }
      }
      &:after,
      &:before {
        border-bottom: 1px solid ${palette.primary.main} !important;
      }
    }
    .MuiInput-root.Mui-focused:before {
      transform: scaleX(0);
    }
  }
`;

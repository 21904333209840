/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
// import CloseIcon from '@mui/icons-material/Close';
// import ButtonRadio from 'atoms/ButtonRadio/ButtonRadio';
// import { PaymentIcons } from 'molecules/InvoicePopupPaymentOptions/InvoicePopupPaymentOptions.styles';
// import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
// import { ReactComponent as MastercardIcon } from 'molecules/InvoicePopupPaymentOptions/assets/mastercard.svg';
// import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { useBaoSelector } from 'utils/hooks/redux';

type AddPaymentMethodDialogProps = {
  addingPaymentMethod: boolean;
  onAddingPaymentMethod: (addingPaymentMethod: boolean) => void;
};

const AddPaymentMethodDialog = ({
  addingPaymentMethod,
  onAddingPaymentMethod,
}: AddPaymentMethodDialogProps) => {
  const { currentCompany } = useBaoSelector((state) => state.common);

  const handleAddingPaymentMethod = async () => {
    if (!currentCompany) {
      // eslint-disable-next-line no-console
      console.error('Company not found');
      return;
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/setup-auto-payment`,
      {
        cancel_url: window.location.href,
        success_url: window.location.href,
        company_hs_id: currentCompany?.company_hs_id,
      }
    );
    // onAddingPaymentMethod(false);
    window.open(data.url, '_self');
  };

  useEffect(() => {
    if (addingPaymentMethod) {
      handleAddingPaymentMethod();
    }
  }, [addingPaymentMethod]);

  return <></>;
};

export default AddPaymentMethodDialog;

import React, { FC, useCallback } from 'react';

import { Stack, TextField } from '@mui/material';
import Modal from 'molecules/Modal';
import {
  ModalContent,
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentTextButton } from 'molecules/Modal/ModalText.styles';
import { UserRow } from 'organisms/CompanyDetailsCard/CompanyContacts';
import { GeneratePassword } from 'js-generate-password';

const ChangePasswordModal: FC<{
  open: boolean;
  user: UserRow;
  value?: string;
  onChange: (value: string) => void;
  error: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ open, user, value, onChange, error, onClose, onSubmit }) => {
  const generate = useCallback(() => {
    const generated = GeneratePassword({
      length: 14,
      symbols: true,
    });

    onChange(generated);
  }, [onChange]);

  const handleClose = useCallback(() => {
    onClose();
    onChange('');
  }, [onClose]);

  return (
    <Modal
      padding24
      open={open}
      maxWidth="sm"
      title="Change password"
      subtitle={`
          You can set new password for ${user?.name} ${user?.surname},
          ${user?.email} yourself or click generate new password. 
        `}
      onClose={handleClose}
    >
      <>
        <ModalContent>
          <Stack columnGap="8px" alignItems="flex-start">
            <TextField
              error={error}
              autoComplete="password"
              fullWidth
              size="small"
              variant="outlined"
              label="Password"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              helperText={
                error &&
                `
                  The password must contain 8 letters, with at least a symbol,
                  upper and lower case letters and a number
                `
              }
            />

            <ModalContentTextButton onClick={generate}>
              Generate
            </ModalContentTextButton>
          </Stack>
        </ModalContent>
        <ModalActions $padding24>
          <ModalCloseButton variant="outlined" onClick={handleClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Change
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default ChangePasswordModal;

import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import { useBaoSelector } from 'utils/hooks/redux';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import { PaymentSteps } from './InvoicePopupPaymentSteps.styles';
import { ModalContent } from 'molecules/Modal/Modal.styles';

const steps = [1, 2, 3, 4];

const InvoicePopupPaymentSteps = () => {
  const payInvoice = useBaoSelector((state) => state.payInvoice);

  let step = <Step1 />;
  if (payInvoice.step === 2) {
    step = <Step2 />;
  }
  return (
    <ModalContent sx={{ mb: '16px' }}>
      <PaymentSteps>
        <Stepper activeStep={payInvoice.step - 1}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
        <Typography variant="h3" fontSize="1rem" my={4}>
          Step {payInvoice.step}
        </Typography>
        {step}
      </PaymentSteps>
    </ModalContent>
  );
};

export default InvoicePopupPaymentSteps;

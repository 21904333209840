/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
  FormControl,
  IconButton,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  CardHeader,
  Typography,
} from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { useBaoSelector } from 'utils/hooks/redux';
import DeleteSharp from '@mui/icons-material/DeleteSharp';
import FlexCenterBetween from 'utils/styles/FlexCenterBetween';
import { fetchPaymentMethods } from 'molecules/PaymentOptionRadio/helpers';
import PaymentMethod from 'molecules/PaymentMethod/PaymentMethod';
import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';
import {
  ConfirmButtonWrapper,
  ButtonNotVerified,
} from './PaymentOptionRadio.style';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { SUPER_ADMIN } from 'utils/constants/roles';
import AccountNotVerifiedModal from './components/AccountNotVerifiedModal';
import Modal from 'molecules/Modal';
import {
  ModalContent,
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';

type PaymentOptionRadioProps = {
  oneTime?: boolean;
  stateChanger?: any;
  dashboard?: boolean;
  dialogOpened: boolean;
  setPaymentToPay?: (data: any) => void;
  onDialogOpen?: (oepn: boolean) => void;
  onPaymentChange?: () => void;
  updatePrice?: (data: number | string) => void;
  onPrimaryChange?: (id: string) => void;
  diference?: boolean;
};

const PaymentOptionRadio = ({
  oneTime,
  stateChanger,
  dashboard,
  dialogOpened,
  onDialogOpen,
  setPaymentToPay,
  onPaymentChange,
  updatePrice,
  onPrimaryChange,
  diference,
}: PaymentOptionRadioProps) => {
  const { width } = useWindowDimensions();

  const [selectingPayment, setSelectingPayment] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [prevChangeData, setPrevChangeData] = useState<PMethod[]>([]);
  const [discount, setDicount] = useState(0);
  const [mainPaymentMethodData, setMainPaymentMethodData] = useState<PMethod>();
  const [paymentMethodIdBeingConfirmed, setPaymentMethodIdBeingConfirmed] =
    useState('');
  const [openLoadingSpinner, setOpenLoadingSpinner] = useState(false);
  const [paymentSelectedIsPAD, setPaymentSelectedIsPAD] =
    useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<null | number>();

  const payInvoice = useBaoSelector((state) => state.payInvoice);
  const user = useBaoSelector((state) => state.user.value);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  // useEffect(() => {
  //   fetchPaymentMethods(payInvoice?.invoice.invoiceQbNumber).then(
  //     setPaymentMethods
  //   );
  // }, [dialogOpened]);

  useEffect(() => {
    if (!currentCompany) return;

    fetchPaymentMethods({
      currentCompany,
      invoiceId: payInvoice?.invoice.invoiceQbNumber,
    }).then((response) => {
      setPaymentMethods(response);
      setPrevChangeData([...response]);
      const mainPaymentMethod =
        response.find((p: { isMain: any }) => p.isMain) || null;
      if (mainPaymentMethod) {
        setMainPaymentMethodData(mainPaymentMethod);
      }
    });

    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE}/company/${currentCompany.company_hs_id}`
      )
      .then((p) => setDicount(p.data.discount));
  }, [currentCompany]);

  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);
  const [deletingId, setDeletingId] = useState<string>('');
  const [cardChange, setCardChange] = useState<string>('');
  const [achChange, setAchChange] = useState<string>('');
  const [extraSelectModal, setExtraSelectModal] = useState<boolean>(false);
  const [extraSelected, setExtraOption] = useState<string>('');

  const [isNotVerifiedModal, setIsNotVerifiedModal] = useState<boolean>(false);

  const handleClickNotVerified = useCallback(() => {
    setIsNotVerifiedModal((prev) => !prev);
  }, []);

  const handleDelete = async (id: string) => {
    if (!currentCompany) return;

    // todo add try/catch
    await axios.delete(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-method/${id}`,
      { params: { company_hs_id: currentCompany.company_hs_id } }
    );
    fetchPaymentMethods({ currentCompany }).then((response) => {
      setPaymentMethods(response);
      stateChanger(response.length);
    });
  };

  const methodChangePopupConfirm = (plus = false) => {
    const paymentMethodList = [...paymentMethods];
    const id = cardChange || achChange;
    paymentMethodList.forEach((i) => {
      i.isMain = id === i.id;
    });
    setPaymentMethods(paymentMethodList);

    if (updatePrice && mainPaymentMethodData) {
      const npercent = Number(
        (Number(payInvoice.invoice.subtotal) * Number(discount)) / 100
      );
      const mainData = paymentMethods.find((p) => p.isMain === true);
      let newCalculatedPrice = 0;

      if (
        ['ach_debit', 'acss_debit'].includes(
          mainPaymentMethodData?.paymentMethod
        ) &&
        mainData?.paymentMethod === 'card'
      ) {
        newCalculatedPrice = Number(payInvoice.invoice.amountDue) + npercent;
      } else if (
        mainPaymentMethodData?.paymentMethod === 'card' &&
        ['ach_debit', 'acss_debit'].includes(mainData?.paymentMethod ?? '')
      ) {
        newCalculatedPrice = Number(payInvoice.invoice.amountDue) - npercent;
      } else {
        newCalculatedPrice = payInvoice.invoice.amountDue;
      }

      if (newCalculatedPrice) {
        setNewPrice(newCalculatedPrice);
        updatePrice(Number(newCalculatedPrice).toFixed(2));
      }
    }
    if (id && typeof id === 'string') {
      setPrevChangeData(paymentMethodList);
      if (setPaymentToPay) {
        setPaymentToPay(paymentMethodList);
      }
      setSelectingPayment(false);
      setPaymentMethodIdBeingConfirmed('');
      if (onPaymentChange) {
        onPaymentChange();
      }
    }

    setPrevChangeData([...paymentMethods]);
    setCardChange('');
    setAchChange('');
  };

  const methodChangePopupCancel = () => {
    const dataPayments = [...prevChangeData];
    setPaymentMethods(dataPayments);
    setCardChange('');
    setAchChange('');
  };

  const handleSelectPaymentMethod = async (id: string) => {
    if (!oneTime) {
      setSelectingPayment(true);
      if (onPrimaryChange) {
        onPrimaryChange(id);
      }
    }

    if (oneTime) {
      const newMethod = paymentMethods.find((p) => p.id === id);
      const newMwthodType = newMethod?.paymentMethod ?? '';
      const oldPaymentType =
        paymentMethods.find((p) => p.isMain === true)?.paymentMethod ?? '';

      const discountManual = (payInvoice.invoice as any).isManual;

      const paymentMethodList = [...paymentMethods];
      paymentMethodList.forEach((i) => {
        i.isMain = id === i.id;
      });

      setPaymentMethods(paymentMethodList);
      setPrevChangeData(paymentMethodList);
      if (setPaymentToPay) {
        setPaymentToPay(paymentMethodList);
      }
      setSelectingPayment(false);
      setPaymentMethodIdBeingConfirmed('');
      if (onPaymentChange) {
        onPaymentChange();
      }
    } else {
      if (!currentCompany) return;

      setOpenLoadingSpinner(true);

      await axios
        .patch(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-main-payment-method`,
          {
            id,
            company_hs_id: currentCompany?.company_hs_id,
          }
        )
        .then(() =>
          fetchPaymentMethods({ currentCompany }).then((response) => {
            setPaymentMethods(response);
            if (onPaymentChange) {
              onPaymentChange();
            }
            setExtraSelectModal(false);
            setSelectingPayment(false);
            setPaymentMethodIdBeingConfirmed('');
            setOpenLoadingSpinner(false);
          })
        );
    }
  };

  const mainPaymentMethod =
    paymentMethods.find((paymentMethod) => paymentMethod.isMain) || null;

  const deleteMethod = (id: string) => {
    handleDelete(id);
    setDeletingPaymentMethod(false);
  };

  const paymentChangeEvent = (id: string, select = false) => {
    if (!oneTime) {
      if (select) {
        handleSelectPaymentMethod(id);
      } else {
        setPaymentMethodIdBeingConfirmed(id);
      }
    } else {
      const choosenPayment = paymentMethods.find(
        (paymentMethod) => paymentMethod?.id === id
      );
      if (choosenPayment?.isVerified) {
        handleSelectPaymentMethod(id);
      } else {
        setVerifyError(true);
      }
    }
  };

  // Do we still need this?
  const getTextOfConfirm = (id: string) => {
    const mainData = paymentMethods.find((p) => p.id === id);
    if (
      mainPaymentMethod &&
      ['ach_debit', 'acss_debit'].includes(mainPaymentMethod?.paymentMethod) &&
      mainData?.paymentMethod === 'card'
    ) {
      return `Credit card payment method will remove the ${discount}% discount. This discount is valid only with Bank Transfer payment. Continue?`;
    }
    if (
      mainPaymentMethod?.paymentMethod === 'card' &&
      mainData &&
      ['ach_debit', 'acss_debit'].includes(mainData?.paymentMethod)
    ) {
      return `A ${discount}% invoice discount from Zazmic will be applied when the payment method is Bank Transfer. Continue?`;
    }
    return 'Are you sure you want to update your primary payment method?';
  };

  useEffect(() => {
    if (paymentMethods.length === 2 && !mainPaymentMethod) {
      setExtraSelectModal(true);
    }
  }, [paymentMethods, mainPaymentMethod]);

  const isCustomerMobileView = width < 600 && !isSuperAdmin;

  return (
    <FormControl fullWidth>
      <FormLabel id="payment-options">
        {!dashboard && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px',
              padding: isCustomerMobileView ? '0 16px' : 'auto',
            }}
          >
            {oneTime ? (
              <Typography
                sx={{
                  fontSize: isCustomerMobileView ? '0.875rem' : '17px',
                  fontWeight: 600,
                  color: '#262B33',
                }}
              >
                Payment methods
              </Typography>
            ) : isCustomerMobileView ? (
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  color: '#262B33',
                }}
              >
                Payment methods
              </Typography>
            ) : (
              <CardHeader
                style={{ padding: 0 }}
                title="Payment methods"
                avatar={<CardAvatar />}
              />
            )}

            {paymentMethods.length < 10 &&
              onDialogOpen &&
              (oneTime ? (
                <Button
                  size="medium"
                  variant="text"
                  sx={{
                    color: '#6F9FE9',
                    textDecoration: 'underline',
                    padding: isCustomerMobileView ? '0 !important' : '6px 8px',
                  }}
                  onClick={() => onDialogOpen(true)}
                >
                  Add payment method
                </Button>
              ) : (
                paymentMethods.length < 10 &&
                (isCustomerMobileView ? (
                  <Button
                    size="medium"
                    variant="text"
                    onClick={() => onDialogOpen(true)}
                    sx={{
                      textDecoration: 'underline',
                      padding: '0 !important',
                      fontSize: '0.875rem',
                    }}
                  >
                    Add new method
                  </Button>
                ) : (
                  <ConfirmButtonWrapper>
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() => onDialogOpen(true)}
                    >
                      Add new method
                    </Button>
                  </ConfirmButtonWrapper>
                ))
              ))}
          </div>
        )}
      </FormLabel>

      {dashboard && mainPaymentMethod && (
        <RadioGroup
          aria-labelledby="payment-options"
          name="payment-options"
          value={mainPaymentMethod?.id}
          onChange={(event) => {
            oneTime
              ? handleSelectPaymentMethod(event.target.value)
              : setPaymentMethodIdBeingConfirmed(event.target.value);
          }}
        >
          {paymentMethods.map(
            (paymentMethod) =>
              paymentMethod.isMain && (
                <FlexCenterBetween key={paymentMethod?.id}>
                  <PaymentMethod method={paymentMethod} />
                </FlexCenterBetween>
              )
          )}
        </RadioGroup>
      )}

      <Box display="flex" flexDirection="row">
        <Box width="100%">
          {!dashboard && (
            <RadioGroup
              aria-labelledby="payment-options"
              name="payment-options"
              value={mainPaymentMethod?.id}
              onChange={(event) => {
                event.preventDefault();
                paymentChangeEvent(event.target.value);
              }}
            >
              {paymentMethods.map((paymentMethod) => (
                <FlexCenterBetween key={paymentMethod?.id}>
                  <FormControlLabel
                    sx={{
                      marginRight: '20px',
                      padding: isCustomerMobileView ? '0 16px' : 'auto',
                    }}
                    checked={mainPaymentMethod?.id === paymentMethod?.id}
                    value={paymentMethod?.id}
                    disabled={selectingPayment}
                    control={<Radio />}
                    label={<PaymentMethod method={paymentMethod} />}
                  />

                  {/* Don't need to show buttons below for customer in Mobile View */}
                  {!isCustomerMobileView ? (
                    <>
                      <span>
                        {paymentMethod.paymentMethod !== 'card' &&
                          paymentMethod.isVerified === false && (
                            <ButtonNotVerified
                              variant="contained"
                              onClick={handleClickNotVerified}
                              endIcon={<InfoIcon color="#FFFFFF" />}
                            >
                              not verified
                            </ButtonNotVerified>
                          )}
                      </span>

                      {!oneTime && paymentMethod.paymentMethod === 'card' && (
                        <span style={{ margin: '0 auto' }}>
                          Card processing fees apply
                        </span>
                      )}

                      {!oneTime &&
                        paymentMethod?.id === mainPaymentMethod?.id && (
                          <div style={{ width: '34px', marginLeft: 'auto' }} />
                        )}

                      {(paymentMethod?.id !== mainPaymentMethod?.id ||
                        paymentMethods.length === 1) &&
                        !oneTime && (
                          <div
                            style={{ marginRight: '0px', marginLeft: 'auto' }}
                          >
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => {
                                setDeletingPaymentMethod(true);
                                setDeletingId(paymentMethod?.id);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          </div>
                        )}
                    </>
                  ) : (
                    <span />
                  )}
                </FlexCenterBetween>
              ))}
            </RadioGroup>
          )}
        </Box>
      </Box>

      <Modal
        open={deletingPaymentMethod}
        maxWidth="sm"
        title="Payment removal"
        subtitle="Please confirm that you want to remove payment method."
        onClose={() => setDeletingPaymentMethod(false)}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => setDeletingPaymentMethod(false)}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            autoFocus
            size="medium"
            variant="contained"
            onClick={() => deleteMethod(deletingId)}
            disabled={
              deletingId === mainPaymentMethod?.id && paymentMethods.length > 1
            }
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
      {/* TODO: Clarify if there is any difference between deleting payment method and auto payment method */}
      {/* <Dialog maxWidth="md" open={deletingPaymentMethod}> */}
      {/*   <DialogTitle> */}
      {/*     Please confirm the removal of auto-payment method. */}
      {/*   </DialogTitle> */}
      {/*   <DialogActions> */}
      {/*     <CancelButtonWrapper> */}
      {/*       <Button */}
      {/*         variant="outlined" */}
      {/*         autoFocus */}
      {/*         style={{ borderColor: '#6f9fe9' }} */}
      {/*         onClick={() => setDeletingPaymentMethod(false)} */}
      {/*       > */}
      {/*         Cancel */}
      {/*       </Button> */}
      {/*     </CancelButtonWrapper> */}
      {/*     <ConfirmButtonWrapper> */}
      {/*       <Button */}
      {/*         variant="contained" */}
      {/*         size="medium" */}
      {/*         onClick={() => deleteMethod(deletingId)} */}
      {/*         disabled={ */}
      {/*           deletingId === mainPaymentMethod?.id && */}
      {/*           paymentMethods.length > 1 */}
      {/*         } */}
      {/*       > */}
      {/*         Delete */}
      {/*       </Button> */}
      {/*     </ConfirmButtonWrapper> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      <Modal
        open={paymentMethodIdBeingConfirmed !== ''}
        maxWidth="sm"
        className="mobile-modal"
        title={oneTime ? 'Change payment method' : 'Auto payment method change'}
        subtitle={
          oneTime
            ? 'Payment method will be changed only for current payment session.'
            : 'Please confirm that you want to change payment method that will be used for auto payment.'
        }
        onClose={() => setPaymentMethodIdBeingConfirmed('')}
      >
        <>
          {oneTime && (
            <ModalContent>
              <ModalContentText>Сard processing fee apply</ModalContentText>
            </ModalContent>
          )}
          <ModalActions>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={() => setPaymentMethodIdBeingConfirmed('')}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton
              size="medium"
              variant="contained"
              onClick={() =>
                handleSelectPaymentMethod(paymentMethodIdBeingConfirmed)
              }
            >
              Confirm
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      {/* <Dialog maxWidth="md" open={paymentMethodIdBeingConfirmed !== ''}> */}
      {/*   <DialogTitle> */}
      {/*     {oneTime */}
      {/*       ? 'Do you want to change payment method?' */}
      {/*       : 'Please confirm the auto-payment method change.'} */}
      {/*   </DialogTitle> */}
      {/*   {oneTime && ( */}
      {/*     <DialogContent dividers> */}
      {/*       {oneTime */}
      {/*         ? 'Сard processing fee apply' */}
      {/*         : getTextOfConfirm(paymentMethodIdBeingConfirmed)} */}
      {/*     </DialogContent> */}
      {/*   )} */}
      {/*   <DialogActions> */}
      {/*     <CancelButtonWrapper> */}
      {/*       <Button */}
      {/*         size="medium" */}
      {/*         autoFocus */}
      {/*         variant="outlined" */}
      {/*         onClick={() => setPaymentMethodIdBeingConfirmed('')} */}
      {/*         style={{ */}
      {/*           borderColor: '#6f9fe9', */}
      {/*           color: '#6f9fe9', */}
      {/*         }} */}
      {/*       > */}
      {/*         Cancel */}
      {/*       </Button> */}
      {/*     </CancelButtonWrapper> */}
      {/*     <ConfirmButtonWrapper> */}
      {/*       <Button */}
      {/*         size="medium" */}
      {/*         variant="contained" */}
      {/*         onClick={() => */}
      {/*           handleSelectPaymentMethod(paymentMethodIdBeingConfirmed) */}
      {/*         } */}
      {/*       > */}
      {/*         Confirm */}
      {/*       </Button> */}
      {/*     </ConfirmButtonWrapper> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      <Modal
        open={verifyError}
        maxWidth="sm"
        title="Update primary payment method"
        subtitle="Bank verification required before payment can be made"
        onClose={() => setVerifyError(false)}
        sx={{ zIndex: 31323 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => setVerifyError(false)}
          >
            Close
          </ModalCloseButton>
        </ModalActions>
      </Modal>
      {/* <Dialog maxWidth="md" open={verifyError} sx={{ zIndex: 31323 }}> */}
      {/*   <DialogTitle>Update primary payment method</DialogTitle> */}
      {/*   <DialogContent dividers> */}
      {/*     Bank verification required before payment can be made */}
      {/*   </DialogContent> */}
      {/*   <DialogActions> */}
      {/*     <Button autoFocus onClick={() => setVerifyError(false)}> */}
      {/*       Cancel */}
      {/*     </Button> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      {/* if the CC is checked */}
      <Modal
        open={cardChange.length > 0}
        maxWidth="xs"
        title="Payment method change"
        subtitle={`
          A ${discount}% invoice discount from Zazmic will be applied when the
          payment method is Bank Transfer. Continue?
        `}
        onClose={methodChangePopupCancel}
        sx={{ zIndex: 32000 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => methodChangePopupCancel()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => methodChangePopupConfirm()}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
      {/* <Dialog maxWidth="xs" open={cardChange.length > 0} sx={{ zIndex: 32000 }}> */}
      {/*   <DialogTitle>Payment method change</DialogTitle> */}
      {/*   <DialogContent dividers> */}
      {/*     A {discount}% invoice discount from Zazmic will be applied when the */}
      {/*     payment method is Bank Transfer. Continue? */}
      {/*   </DialogContent> */}
      {/*   <DialogActions> */}
      {/*     <Button onClick={() => methodChangePopupCancel()}>Cancel</Button> */}
      {/*     <Button onClick={() => methodChangePopupConfirm()}>Confirm</Button> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      {/* if the ACH is checked */}
      <Modal
        open={achChange.length > 0}
        maxWidth="xs"
        title="Payment method change"
        subtitle={`
          Credit card payment method will remove the ${discount}% discount. This
          discount is valid only with Bank Transfer payment. Continue?
        `}
        onClose={methodChangePopupCancel}
        sx={{ zIndex: 32000 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => methodChangePopupCancel()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => methodChangePopupConfirm(true)}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
      {/* <Dialog maxWidth="xs" open={achChange.length > 0} sx={{ zIndex: 32000 }}> */}
      {/*   <DialogTitle>Payment method change</DialogTitle> */}
      {/*   <DialogContent dividers> */}
      {/*     Credit card payment method will remove the {discount}% discount. This */}
      {/*     discount is valid only with Bank Transfer payment. Continue? */}
      {/*   </DialogContent> */}
      {/*   <DialogActions> */}
      {/*     <Button onClick={() => methodChangePopupCancel()}>Cancel</Button> */}
      {/*     <Button onClick={() => methodChangePopupConfirm(true)}> */}
      {/*       Confirm */}
      {/*     </Button> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      {openLoadingSpinner && (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: 1400 }}
            open={openLoadingSpinner}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {/* dialog for two items */}
      <Modal
        open={extraSelectModal}
        maxWidth="md"
        fullScreen={isCustomerMobileView}
        title={
          isCustomerMobileView
            ? 'Auto payment'
            : 'Select payment method for auto payment'
        }
        subtitle={
          isCustomerMobileView
            ? 'Select payment method for auto payment'
            : undefined
        }
        sx={{ pt: isCustomerMobileView ? '76px' : 'auto', zIndex: 8 }}
      >
        <>
          <ModalContent>
            <RadioGroup
              aria-labelledby="payment-options"
              name="payment-options"
              onChange={(event) => {
                setExtraOption(event.target.value);
              }}
            >
              {paymentMethods.map((paymentMethod) => (
                <FlexCenterBetween key={paymentMethod?.id}>
                  <FormControlLabel
                    sx={{ marginRight: '20px' }}
                    checked={extraSelected === paymentMethod?.id}
                    value={paymentMethod?.id}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 22,
                          },
                        }}
                        style={{ fontSize: '1rem !important', opacity: 1 }}
                      />
                    }
                    label={<PaymentMethod method={paymentMethod} />}
                  />

                  {!isCustomerMobileView &&
                    paymentMethod.paymentMethod === 'card' && (
                      <span style={{ marginLeft: 'auto', color: '#484E56' }}>
                        Card processing fees apply
                      </span>
                    )}
                </FlexCenterBetween>
              ))}
            </RadioGroup>
          </ModalContent>
          <ModalActions>
            {extraSelected.length > 0 && (
              <ModalSubmitButton
                size="medium"
                variant="contained"
                onClick={() => paymentChangeEvent(extraSelected, true)}
              >
                Confirm
              </ModalSubmitButton>
            )}
          </ModalActions>
        </>
      </Modal>
      {/* <Dialog */}
      {/*   open={extraSelectModal} */}
      {/*   maxWidth="md" */}
      {/*   fullScreen={isCustomerMobileView} */}
      {/*   sx={{ pt: isCustomerMobileView ? '76px' : 'auto', zIndex: 8 }} */}
      {/* > */}
      {/*   {isCustomerMobileView ? ( */}
      {/*     <MobileDialogTitle> */}
      {/*       <Typography component="h2">Auto payment</Typography> */}
      {/*       <Typography component="h5"> */}
      {/*         Select payment method for auto-payment */}
      {/*       </Typography> */}
      {/*     </MobileDialogTitle> */}
      {/*   ) : ( */}
      {/*     <DialogTitle style={{ paddingLeft: '16px' }}> */}
      {/*       Select payment method for auto-payment */}
      {/*     </DialogTitle> */}
      {/*   )} */}
      {/*   <div style={{ padding: '16px' }}> */}

      {/*   </div> */}
      {/*   <div style={{ padding: '16px', textAlign: 'right' }}> */}
      {/*     {isCustomerMobileView ? ( */}
      {/*       extraSelected.length > 0 && ( */}
      {/*         <Button */}
      {/*           sx={{ padding: '6px', width: '100%', fontSize: '1.170rem' }} */}
      {/*           variant="contained" */}
      {/*           onClick={() => paymentChangeEvent(extraSelected, true)} */}
      {/*         > */}
      {/*           Confirm */}
      {/*         </Button> */}
      {/*       ) */}
      {/*     ) : ( */}
      {/*       <ConfirmButtonWrapper> */}
      {/*         {extraSelected.length > 0 && ( */}
      {/*           <Button */}
      {/*             variant="contained" */}
      {/*             onClick={() => paymentChangeEvent(extraSelected, true)} */}
      {/*           > */}
      {/*             Submit */}
      {/*           </Button> */}
      {/*         )} */}
      {/*       </ConfirmButtonWrapper> */}
      {/*     )} */}
      {/*   </div> */}
      {/* </Dialog> */}

      {/* dialog for verifying payment method */}
      <AccountNotVerifiedModal
        open={isNotVerifiedModal}
        onClose={handleClickNotVerified}
      />
    </FormControl>
  );
};

export default PaymentOptionRadio;

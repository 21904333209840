import styled from 'styled-components';
import logo from 'assets/images/logo.png';

type imgProps = {
  $width?: string;
};

export default styled.img.attrs({
  src: logo,
  alt: '',
})<imgProps>`
  width: ${(props) => props.$width || '120px'};
`;

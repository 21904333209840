export type statusType =
  | 'open'
  | 'overdue'
  | 'paid'
  | 'customer'
  | 'pending'
  | 'voided'
  | 'all';
export const sortOptions = [
  { key: 'Outstanding', value: 'open,overdue,payment_pending' },
  { key: 'Open', value: 'open' },
  { key: 'Overdue', value: 'overdue' },
  { key: 'Paid', value: 'paid' },
  { key: 'Pending', value: 'pending' },
  { key: 'Voided', value: 'voided' },
];

export const currencyIcon = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

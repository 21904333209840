import styled from 'styled-components';
import { MenuItem, ListItemIcon, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 5px 8px !important;
    gap: 8px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 0 !important;
  }
`;

const StyledItemText = styled(Typography)`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export { StyledMenuItem, StyledListItemIcon, StyledItemText };

import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledCurrencyButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;

    padding: 0;
  }
`;

export const StyledCompanyDetailsButton = styled(({ ...props }) => (
  <Button {...props} />
))`
  && {
    display: flex;
    padding: 4px 8px;

    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 3px;
    border: 0;

    color: #fff;

    text-align: justify;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    span {
      margin: 0;
    }
  }
`;

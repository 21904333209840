import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyledMenuItem,
  StyledMenuText,
  StyledTextField,
} from 'molecules/CompanySwitch/CompanySwitch.styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { light, neutrals } from 'utils/styles/color';
import { UserCompany } from 'store/user/userSlice';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { setCurrentCompany } from 'store/common/commonSlice';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

const CompanySwitch: FC<{ companies: UserCompany[] }> = ({ companies }) => {
  const navigate = useNavigate();
  const dispatch = useBaoDispatch();
  const { currentCompany } = useBaoSelector((state) => state.common);

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowDimensions();

  const [hover, setHover] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number | string>(1);

  const IconComponent = useMemo(() => {
    if (!hover) return <span style={{ width: '24px' }} />;
    return (
      <ArrowDropDownIcon
        onClick={() => setOpen(true)}
        sx={{ cursor: 'pointer', color: neutrals[800] }}
      />
    );
  }, [hover]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setHover(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: companyId } = event.target;

      setValue(companyId);

      const company = companies.find(
        (c) => c.company_hs_id === Number(companyId)
      );
      dispatch(setCurrentCompany(company as UserCompany));
      setHover(false);
      navigate('/dashboard');
    },
    [companies]
  );

  useEffect(() => {
    setValue(currentCompany?.company_hs_id || 1);
  }, [currentCompany]);

  return (
    <div ref={anchorEl} style={{ cursor: 'pointer' }}>
      <StyledTextField
        select
        value={value}
        id="select-company"
        variant="standard"
        defaultValue={1}
        // onMouseOver={() => {
        //   if (open) return;
        //   setHover(true);
        // }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onChange={handleSelect}
        $hovered={hover}
        $isMobile={width < 600}
        SelectProps={{
          open,
          onClose: handleClose,
          onOpen: handleOpen,
          IconComponent: () => IconComponent,
          MenuProps: {
            anchorEl: anchorEl.current,
            sx: {
              '.MuiPaper-root': {
                width: anchorEl && anchorEl.current?.offsetWidth,
              },
              '.MuiList-root': {
                paddingTop: '4px',
                paddingBottom: '4px',
              },
              '.MuiMenuItem-root:hover': {
                background: light[100],
              },
            },
          },
        }}
      >
        {companies.map((option) => (
          <StyledMenuItem
            key={option.company_hs_id}
            value={option.company_hs_id}
          >
            <StyledMenuText noWrap>{option.name}</StyledMenuText>
          </StyledMenuItem>
        ))}
      </StyledTextField>
    </div>
  );
};

export default CompanySwitch;

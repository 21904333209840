import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import Modal from 'molecules/Modal';
import {
  CloudOpsProjectsType,
  FormType,
  QuickbooksProjectType,
} from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Alert, Stack } from '@mui/material';
import { QuickbooksProjectAutoComplete } from 'molecules/ProjectAutoComplete/QuickbooksProjectAutoComplete';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';
import axios from 'axios';
import { WhiteBorderTextField } from 'pages/CompanyPage/CompanyPage.styles';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { invoiceInitialDetails } from 'pages/CompanyPage/helpers';
import { keys } from 'organisms/ProjectDialogs/utils';
import { InvoiceInputNameType } from 'organisms/ProjectDialogs/types';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { log } from 'utils/helpers/logger';

const CloudOpsProjectSyncDialog: FC<{
  companyHsId?: string;
  project: QuickbooksProjectType;
  currency?: keyof typeof currencyIcon;
  onClose: () => void;
  onSync?: () => void;
}> = ({ currency, companyHsId, onClose, onSync, project }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] =
    useState<CloudOpsProjectsType>();
  const [form, setForm] = useState<FormType>(invoiceInitialDetails);
  const [cloudOpsProjectDetails, setCloudOpsProjectDetails] = useState<
    string | CloudOpsProjectsType | null
  >(null);

  const onSubmit = async () => {
    setSubmitted(true);
    if (
      !cloudOpsProjectSelected ||
      !project ||
      !(cloudOpsProjectDetails && typeof cloudOpsProjectDetails === 'object')
    )
      return;

    try {
      handleSyncProjects();
    } catch (error) {
      if (error.response.status === 404) {
        handleSyncProjects();
      }
    }
  };

  const handleCloudOpsProjectChange = useCallback(async (selected) => {
    setSubmitted(false);

    setLoading(true);
    const cloudOpsProjectData = await getCloudOpsProjectData(selected);
    setLoading(false);

    updateDialogInputs({ ...selected, ...cloudOpsProjectData });
    setCloudOpsProjectSelected({ ...selected, ...cloudOpsProjectData });
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    const regExp = /^\d*\.?\d*$/;

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      !/^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = value.slice(0, -1);
      return;
    }

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      /^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = Number(value);
    }

    // value can be empty
    // value should be decimal or integer
    // value should be more or equal to 0
    // key should be included in keys
    if (value === '' && keys.includes(name)) {
      setForm((prev) => ({ ...prev, [name]: Number(value) }));
      return;
    }

    if ((!regExp.test(value) || Number(value) < 0) && keys.includes(name)) {
      event.target.value = '';
      return;
    }

    setForm((prev) => ({ ...prev, [name]: Number(value) }));
  }, []);

  const handleSaveProjectChanges = useCallback(async () => {
    try {
      if (
        Number(form.monthly_budget_in_hours) >= 0 &&
        Number(form.monthly_budget_in_hours) <= 1000
      ) {
        updateDialogInputs({
          minimum_monthly_budget: form.minimum_monthly_budget || '',
          monthly_budget_in_hours: form.monthly_budget_in_hours || '',
          over_budget_hourly_rate: form.over_budget_hourly_rate || '',
        });
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  }, [project, form]);

  const handleInputBlur = useCallback(
    (event) => {
      const {
        name,
        value,
      }: { name: InvoiceInputNameType; value: string | number } = event.target;

      if (value === '') {
        setForm((prev) => ({ ...prev, [name]: '0' }));
        event.target.value = currencyFormatter(
          Number.parseFloat('0'),
          currency || 'USD'
        );
      } else if (Number(form[name]) >= 0) {
        event.target.value = currencyFormatter(
          Number.parseFloat(form[name]),
          currency || 'USD'
        );
      }

      handleSaveProjectChanges();
    },
    [form, currency]
  );

  const updateDialogInputs = (details: Partial<CloudOpsProjectsType>) => {
    // If project 'id' isn't exists, then it's creation mode;
    if (!details || !details.id) return;

    const {
      monthly_budget_in_hours,
      minimum_monthly_budget,
      over_budget_hourly_rate,
    } = details;

    // Set form fields:
    setCloudOpsProjectDetails(details as CloudOpsProjectsType);
    setForm({
      monthly_budget_in_hours: monthly_budget_in_hours || '0',
      minimum_monthly_budget: minimum_monthly_budget || '0',
      over_budget_hourly_rate: over_budget_hourly_rate || '0',
    });
  };

  const onEmptyAction = () => {
    // eslint-disable-next-line no-console
    console.log('This method will never reached!');
  };

  const beforeClose = () => {
    setForm(invoiceInitialDetails);

    setCloudOpsProjectDetails(null);
  };

  async function handleSyncProjects() {
    try {
      const { status } = await syncQuickbooksWithStream();

      if (typeof onSync === 'function' && status) {
        onSync();
        beforeClose();
        return;
      }

      if (status) {
        beforeClose();
        onClose();
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  }

  async function syncQuickbooksWithStream() {
    if (
      !cloudOpsProjectSelected?.id_str ||
      !companyHsId ||
      !project?.quickbook_project_id
    )
      return { status: false, isNonExist: false };

    try {
      const existingPrj = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${cloudOpsProjectSelected.id_str}`
      );

      // If project has `quickbook_project_id` value, then it means
      // project already synchronized.
      if (existingPrj.data.quickbook_project_id) {
        setAlert({
          error: true,
          message: 'Project already synced, please select another project!',
        });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
        return { status: false, isNonExist: false };
      }

      await axios.put(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${cloudOpsProjectSelected.id_str}`,
        {
          ...project,
          ...cloudOpsProjectSelected,
          ...{
            minimum_monthly_budget: form.minimum_monthly_budget
              ? Number(form.minimum_monthly_budget)
              : 0,
            monthly_budget_in_hours: form.monthly_budget_in_hours
              ? Number(form.monthly_budget_in_hours)
              : 0,
            over_budget_hourly_rate: form.over_budget_hourly_rate
              ? Number(form.over_budget_hourly_rate)
              : 0,
          },
        }
      );

      return { status: true, isNonExist: false };
    } catch (error) {
      if (error.response.status !== 404) {
        setAlert({ error: true, message: error.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
        return { status: false, isNonExist: false };
      }

      // eslint-disable-next-line no-return-await
      return await createNewProject();
    }
  }

  async function createNewProject() {
    if (!cloudOpsProjectSelected?.id)
      return { status: false, isNonExist: false };

    try {
      const { id, type: projectType } = cloudOpsProjectSelected;
      const { quickbook_project_id } = project || {};

      await axios.post(`${process.env.REACT_APP_USER_SERVICE}/projects`, {
        company_hs_id: Number(companyHsId),
        project_id: typeof cloudOpsProjectSelected === 'object' ? id : 0,
        quickbook_project_id,
        type: projectType,
        ...{
          minimum_monthly_budget: form.minimum_monthly_budget
            ? Number(form.minimum_monthly_budget)
            : 0,
          monthly_budget_in_hours: form.monthly_budget_in_hours
            ? Number(form.monthly_budget_in_hours)
            : 0,
          over_budget_hourly_rate: form.over_budget_hourly_rate
            ? Number(form.over_budget_hourly_rate)
            : 0,
        },
      });

      return { status: true, isNonExist: true };
    } catch (err) {
      setAlert({ error: true, message: err.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);

      return { status: false, isNonExist: false };
    }
  }

  async function getCloudOpsProjectData(selectedProject: CloudOpsProjectsType) {
    if (!selectedProject?.id) return {};

    try {
      const cloudOpsProject = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${selectedProject.id_str}`
      );
      return cloudOpsProject.data;
    } catch (e) {
      log(e);
      return {};
    }
  }

  useEffect(() => {
    setCloudOpsProjectSelected(project as CloudOpsProjectsType);
  }, [project]);

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      transitionDuration={0}
      title="Sync with CloudOps project"
      subtitle={
        <>
          <span>
            QuickBooks projects automatically detected by Zazmic-Connect can be
            synced with CloudOps project that has not yet been synced with a
            QuickBooks project, ensuring full integration.
          </span>
          <span style={{ display: 'flex' }}>
            Once projects synced monthly CloudOps reports will be generated
            starting from the 1st of the current month. Subsequently,
            Zazmic-Connect will automatically generate invoices each following
            month.
          </span>
        </>
      }
      onClose={onClose}
    >
      <>
        <ModalContent sx={{ overflowY: 'hidden' }}>
          {alert.message && (
            <Alert
              sx={{
                width: 'auto !important',
                minWidth: 'auto !important',
                mb: '22px',
              }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          )}
          <Stack gap="22px" pt="5px">
            <QuickbooksProjectAutoComplete
              disabled
              submitted={submitted}
              onChange={onEmptyAction}
              project={project as QuickbooksProjectType}
              companyHsId={String(companyHsId)}
            />
            <ZStreamProjectAutoComplete
              submitted={submitted}
              project={project as CloudOpsProjectsType}
              disabled={false}
              onChange={handleCloudOpsProjectChange}
              loading={loading}
            />
            <Fragment key={cloudOpsProjectSelected?.id_str}>
              <WhiteBorderTextField
                fullWidth
                size="small"
                disabled={!isSuperAdmin} // If not super admin then disable
                name="monthly_budget_in_hours"
                defaultValue={form.monthly_budget_in_hours}
                onChange={handleInputChange}
                onBlur={handleSaveProjectChanges}
                error={
                  Number(form.monthly_budget_in_hours) < 0 ||
                  Number(form.monthly_budget_in_hours) > 1000
                }
                helperText={
                  Number(form.monthly_budget_in_hours) < 0 ||
                  Number(form.monthly_budget_in_hours) > 1000
                    ? 'Please enter a number between 0 and 1000.'
                    : ''
                }
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="given-name"
                margin="normal"
                label="Monthly budget in hours"
              />

              <WhiteBorderTextField
                fullWidth
                size="small"
                disabled={!isSuperAdmin} // If not super admin then disable
                name="minimum_monthly_budget"
                InputProps={{
                  inputProps: {
                    min: 0,
                    style: { textAlign: 'right' },
                  },
                }}
                defaultValue={(() => {
                  if (form.minimum_monthly_budget === '') {
                    return form.minimum_monthly_budget;
                  }
                  return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                    form.minimum_monthly_budget
                  ).toFixed(2)}`;
                })()}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={(e) => {
                  e.target.value =
                    Number(form.minimum_monthly_budget) > 0
                      ? form.minimum_monthly_budget
                      : '';
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  Number(form.minimum_monthly_budget) < 0 ||
                  Number(form.minimum_monthly_budget) > 100000
                }
                helperText={
                  Number(form.minimum_monthly_budget) < 0 ||
                  Number(form.minimum_monthly_budget) > 100000
                    ? 'Please enter a number between 0 and 100000.'
                    : ''
                }
                margin="normal"
                label="Base monthly Fee"
              />

              <WhiteBorderTextField
                fullWidth
                size="small"
                disabled={!isSuperAdmin} // If not super admin then disable
                name="over_budget_hourly_rate"
                InputProps={{
                  inputProps: {
                    min: 0,
                    style: { textAlign: 'right' },
                  },
                }}
                defaultValue={(() => {
                  if (form.over_budget_hourly_rate === '') {
                    return form.over_budget_hourly_rate;
                  }
                  return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                    form.over_budget_hourly_rate
                  ).toFixed(2)}`;
                })()}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={(e) => {
                  e.target.value =
                    Number(form.over_budget_hourly_rate) > 0
                      ? form.over_budget_hourly_rate
                      : '';
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  Number(form.over_budget_hourly_rate) < 0 ||
                  Number(form.over_budget_hourly_rate) > 10000
                }
                helperText={
                  Number(form.over_budget_hourly_rate) < 0 ||
                  Number(form.over_budget_hourly_rate) > 10000
                    ? 'Please enter a number between 0 and 10000.'
                    : ''
                }
                margin="normal"
                label="Over budget hourly rate"
              />
            </Fragment>
          </Stack>
        </ModalContent>
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Sync
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectSyncDialog;

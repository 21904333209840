import React, { FC } from 'react';
import {
  StyledFilterDetailsWrapper,
  StyledFilterDetailsRow,
  StyledFilterDetailsText,
} from 'molecules/Filter/Filter.styles';

const FilterDetails: FC<{
  $multipleRow?: boolean;
  show: boolean;
  data: { label: string; value: string | number }[];
}> = ({ show, data }) => {
  if (!show) return <div style={{ minWidth: '200px' }} />;

  return (
    <StyledFilterDetailsWrapper $multipleRow>
      {data.map(({ label, value }) => (
        <StyledFilterDetailsRow key={label}>
          <StyledFilterDetailsText>{label}:</StyledFilterDetailsText>
          <StyledFilterDetailsText>{value}</StyledFilterDetailsText>
        </StyledFilterDetailsRow>
      ))}
    </StyledFilterDetailsWrapper>
  );
};

export default FilterDetails;

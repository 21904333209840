import React, { FC } from 'react';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Stack } from '@mui/material';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import Modal from 'molecules/Modal';
import { UserRow } from 'organisms/CompanyDetailsCard/CompanyContacts';

const DeleteContactModal: FC<{
  open: boolean;
  user: UserRow;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ open, user, onSubmit, onClose }) => {
  const hasMultiple = (row: UserRow) => row?.company.length > 1;

  return (
    <Modal
      padding24
      open={open}
      maxWidth="md"
      title="Delete contact from Zazmic-Connect"
      subtitle={`
          When you delete a contact from Zazmic-Connect, it will remain in
          HubSpot and remain associated with its company, the 'Integrate with
          Zazmic-Connect' field will be set to 'No', and the contact will no
          longer appear in Zazmic-Connect. It's important to note that if this
          contact is the last contact associated with the company, Zazmic-Connect
          will not be able to send any notifications until another contact is
          designated, and auto-payment will be turned off.
        `}
      onClose={onClose}
    >
      <>
        <ModalContent>
          <Stack>
            <ModalContentText>
              Full name: {user?.name} {user?.surname}
            </ModalContentText>
            <ModalContentText>Email: {user?.email}</ModalContentText>
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <ModalContentText>
              Associated compan{hasMultiple(user) ? 'ies' : 'y'}:{' '}
              {user?.company?.map(({ name }) => name).join(', ')}
            </ModalContentText>
          </Stack>
        </ModalContent>
        <ModalActions $padding24>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Delete
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default DeleteContactModal;

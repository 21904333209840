import React from 'react';
import styled from 'styled-components';
import { reds } from 'utils/styles/color';

const Avatar = styled.div`
  background-color: ${reds[700]};
  width: 8px;
  height: 48px;
  border-radius: 2px;
`;
const CardAvatar = () => <Avatar />;

export default CardAvatar;

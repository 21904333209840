import React, { FC, useState, useCallback, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { snakeCase } from 'lodash-es';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { Stack, TablePagination, Box } from '@mui/material';
import { MobileInvoiceItem } from 'molecules/MobileInvoiceList/MobileInvoiceItem';
import { Row } from 'organisms/InvoicesTable/types';
import { setPage, storeInvoicesInfo } from 'store/invoices/invoicesSlice';
import { statusType } from 'utils/constants/invoiceStatus';
import InvoicesTablePaginationActions from 'organisms/InvoicesTable/InvoicesTablePaginationActions/InvoicesTablePaginationActions';
import { PaginationWrapper } from 'organisms/InvoicesTable/InvoicesTable.styles';
import loaderGif from 'assets/images/loaderGif.gif';
import { setInvoice } from 'store/payInvoice/payInvoiceSlice';

type InvoicesTableProps = {
  status: statusType;
  company?: string | null;
  search?: string | null;
  period?: number;
  customer?: boolean | null;
};

export const MobileInvoiceList: FC<InvoicesTableProps> = ({
  search,
  status,
  company,
  period,
  customer,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invoices = useBaoSelector((state) => state.invoices);
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loader, setLoader] = useState(true);
  const payInvoice = useBaoSelector((state) => state.payInvoice);

  const dispatch = useBaoDispatch();

  const searchParamsGet = new URLSearchParams(useLocation().search);
  const statusGet = searchParamsGet.get('status') as statusType;

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoader(true);
      setTimeout(() => {
        searchParams.set('page', newPage.toString());
        navigate(`?${searchParams}`);
        dispatch(setPage(newPage));
      }, 500);
    },
    [dispatch, navigate, searchParams]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setRowsPerPage(parseInt(event.target.value, 10));
      searchParams.set('page', '0');
      navigate(`?${searchParams}`);
      dispatch(setPage(0));
    },
    [dispatch, navigate, searchParams]
  );

  useEffect(() => {
    const payId = localStorage.getItem('pay');
    if (!payInvoice.open && payId) {
      const prevOpenInv = invoices.rows.find((inv) => inv.id === payId);
      if (prevOpenInv) {
        dispatch(setInvoice(prevOpenInv));
      }
    }
  }, [payInvoice, invoices]);

  useEffect(() => {
    const newPage = Number(searchParams.get('page')) || 0;
    let isAll = false;
    if (
      statusGet &&
      statusGet === 'all' &&
      location.state &&
      location.state.type &&
      location.state.type === 'all'
    ) {
      isAll = true;
    }

    dispatch(
      storeInvoicesInfo({
        customer,
        period,
        newPage,
        rowsPerPage,
        status: isAll ? 'all' : status,
        sort: `sort[${snakeCase('date')}]=desc`,
        company,
        search,
      })
    );
  }, [
    status,
    location,
    invoices.page,
    rowsPerPage,
    dispatch,
    searchParams,
    company,
    search,
    period,
    customer,
    statusGet,
  ]);

  useEffect(() => {
    if (invoices.rows && invoices.rows.length > 0) {
      setLoader(false);
    }

    if (
      invoices.rows &&
      invoices.rows.length === 0 &&
      Number(searchParams.get('page')) > 0 &&
      invoices.totalCount > 0
    ) {
      searchParams.set('page', '0');
      navigate(`?${searchParams}`);
    }

    setTimeout(() => {
      if (invoices && invoices.totalCount === 0) {
        setLoader(false);
      }
    }, 5000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  return (
    <Stack>
      {loader ? (
        <Box sx={{ textAlign: 'center', padding: '40px 0' }}>
          <img src={loaderGif} alt="loader" />
        </Box>
      ) : (
        <Stack>
          {invoices.rows.length ? (
            invoices.rows.map((invoice: Row) => (
              <MobileInvoiceItem key={invoice.id} invoice={invoice} />
            ))
          ) : (
            <Box sx={{ padding: '10px 16px' }}>No Invoices!</Box>
          )}
          <PaginationWrapper>
            <TablePagination
              labelDisplayedRows={() => null}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={invoices.totalCount ? invoices.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={invoices.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={InvoicesTablePaginationActions}
            />
          </PaginationWrapper>
        </Stack>
      )}
    </Stack>
  );
};

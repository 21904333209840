import React, { useCallback, useState } from 'react';
import { Tab } from '@mui/material';
import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import { StyledTabList } from 'molecules/Card/Card.styles';
import { StyledTabPanel } from 'organisms/CompanyInvoicesTabs/CompanyInvoicesTabs.styles';
import { TabContext, TabPanel } from '@mui/lab';
import CloudOpsReportPage from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin';
import SalesReportPage from 'organisms/SalesReport';

type TabType = 'salesRep' | 'cloudops';

const ReportsPage = () => {
  const [selectedTab, setSelectedTab] = useState<TabType>('cloudops');

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: TabType) => {
      setSelectedTab(newValue);
    },
    []
  );

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <HeadlessPageWrapper>
      <StyledPaper>
        <TabContext value={selectedTab}>
          <StyledTabList
            onChange={handleChangeTabs}
            aria-label="CloudOps and Sales Reports tabs"
            variant="fullWidth"
          >
            <Tab
              label="CloudOps"
              {...a11yProps(1)}
              className={selectedTab === 'cloudops' ? '.active' : ''}
              value="cloudops"
            />
            <Tab
              label="Sales Rep"
              {...a11yProps(0)}
              className={selectedTab === 'salesRep' ? '.active' : ''}
              value="salesRep"
            />
          </StyledTabList>
          <StyledTabPanel value="cloudops" sx={{ p: 0 }}>
            <CloudOpsReportPage />
          </StyledTabPanel>
          <TabPanel value="salesRep" sx={{ p: 0 }}>
            <SalesReportPage />
          </TabPanel>
        </TabContext>
      </StyledPaper>
    </HeadlessPageWrapper>
  );
};

export default ReportsPage;

import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const PaymentIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
  margin: ${({ theme }) => theme.spacing(3)} 0;
`;

export const PaymentInfoGrid = styled(Grid)`
  && {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

export const PaymentInfoTypography = styled(Typography)`
  && {
    overflow: hidden;
    color: ${neutrals[700]};
    text-align: right;
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &.MuiTypography-body2 {
    font-size: 1rem;
    font-weight: 600;
  }
`;

import styled from 'styled-components';
import {
  Box,
  ListSubheader,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const StyledTableRow = styled(TableRow)`
  && {
    height: 48px;
  }
`;

export const StyledTableHeadCell = styled(TableCell)`
  && {
    padding: 8px;

    overflow: hidden;
    color: ${neutrals[600]};
    text-overflow: ellipsis;

    /* New/TableHeaderDefault */
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    .v-stack {
      span {
        overflow: hidden;
        color: ${neutrals[600]};
        text-overflow: ellipsis;

        font-size: 0.625rem;
        font-weight: 300;
        line-height: 14px; /* 140% */
      }
    }
  }
`;

export const StyledListSubheader = styled(ListSubheader)`
  && {
    display: flex;
    gap: 16px;
    padding: 4px 8px;

    color: ${neutrals[400]};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 5px 24px;

    color: ${neutrals[700]};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const StyledSelectValue = styled(Box)<{
  $color?: string;
}>`
  && {
    height: 23px;

    span {
      color: ${neutrals[800]};
      font-family: Poppins, sans-serif;
      font-size: 0.803rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    span:last-of-type {
      color: ${({ $color }) => $color || neutrals[800]};
    }
  }
`;

import { StyledMonthDetails } from 'organisms/InvoiceChart/InvoiceChart.styles';
import { Box, Typography } from '@mui/material';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import React from 'react';
import {
  DebtorCompanySortType,
  ICompanyModelItem,
} from 'organisms/InvoiceChart/GlobalInvoiceChart';

export const DebtorCompanyDetails = ({
  details,
  currency,
  sortBy = 'highest_balance',
}: {
  details: ICompanyModelItem | null;
  currency?: string;
  sortBy?: DebtorCompanySortType;
}) => {
  if (!details) return null;

  const { total, highest_invoice_count, oldest_past_due_days, due_date } =
    details;

  const detailsArray = (() => {
    if (sortBy === 'highest_balance') {
      return [
        {
          label: 'Outstanding invoices',
          value: highest_invoice_count || '',
        },
        {
          label: 'Outstanding amount total',
          value: currencyFormatter(total || 0, currency),
        },
      ];
    }

    if (sortBy === 'highest_count') {
      return [
        {
          label: 'Total count of past due invoices',
          value: highest_invoice_count,
        },
      ];
    }

    return [
      {
        label: 'Due date',
        value: due_date || '',
      },
      {
        label: 'Days overdue',
        value: oldest_past_due_days,
      },
    ];
  })();

  return (
    <StyledMonthDetails $largeLabel sx={{ right: '12px !important' }}>
      {detailsArray.map(({ label, value }) => (
        <Box key={label}>
          <Typography variant="body2">{label}:</Typography>
          <Typography variant="body2">{value}</Typography>
        </Box>
      ))}
    </StyledMonthDetails>
  );
};

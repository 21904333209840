import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { neutrals } from 'utils/styles/color';

export const StyledTextField = styled(TextField)`
  && {
    input {
      font-family: Poppins, sans-serif;
      color: ${neutrals[700]} !important;
      font-size: 0.83rem;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;

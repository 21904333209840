import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { MobileCloudOpsItem } from './MobileCloudOpsReports.styles';

export const MobileCloudOpsReport: FC<{
  date: string;
  name: string;
  id: number;
  onDetails: () => void;
}> = ({ date, name, id, onDetails }) => (
  <MobileCloudOpsItem
    spacing={1}
    sx={{ boxShadow: '0px 1px 1px 0px #999', padding: '10px 16px' }}
  >
    <Box>
      <Box component="span">{date}</Box>
      <Button variant="text" size="medium" onClick={onDetails}>
        Details
      </Button>
    </Box>
    <Box>
      <Box component="span">{name}</Box>
      <Box component="span">#{id}</Box>
    </Box>
  </MobileCloudOpsItem>
);

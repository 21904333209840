import styled from 'styled-components';
import { Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const TableFilterWrapper = styled(Box)`
  && {
    display: flex;
    align-items: end;
    height: 26px;

    & > .MuiButtonBase-root {
      width: 24px;
      height: 24px;
      padding: 0;
      &:hover {
        background-color: #f6faff !important;
      }
    }

    .MuiInput-root {
      width: 100%;
      padding-right: 0 !important;
      padding-bottom: 0 !important;
      .MuiInput-input {
        ::placeholder {
          font-size: 0.75rem !important;
          transform: translateX(10%);
          color: ${neutrals[500]};
        }
      }
      .MuiInputAdornment-root {
        width: 16px;
        height: 16px;
        .MuiButtonBase-root {
          width: 16px;
          height: 16px;
        }
      }
    }

    .MuiInputBase-root.MuiInput-root:before {
      border-bottom: 1px solid ${palette.primary.main};
    }

    .MuiInputBase-root.MuiInput-root:after {
      border-bottom: 1px solid ${palette.primary.main};
    }

    .MuiInput-input.MuiInputBase-input {
      font-size: 1rem;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;

import styled from 'styled-components';
import { CardContent } from '@mui/material';

export const CustomModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  outline: none;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
`;

export const StyledNotificationContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 180px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0.1px solid #b7b7b7;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
  }
`;

export const StyledNotificationItem = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      display: block;
      width: 90px;
      min-width: 90px;
      color: #999;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    p {
      margin: 0;

      color: #484e56;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
`;

import styled from 'styled-components';
import { Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const ServicesWrapper = styled.div`
  width: 100%;

  .MuiTableCell-root {
    padding: 12px 16px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    &:first-child {
      width: 33%;
    }

    &:nth-child(2) {
      width: 55%;
    }

    &:last-child {
      width: 15%;
    }
  }

  .MuiTableBody-root .MuiTableRow-root:last-child {
    .MuiTableCell-root {
      border-bottom: none;
    }
  }
`;

export const StyledServiceTableTitle = styled(Typography)<{
  $right?: boolean;
}>`
  && {
    color: ${neutrals[500]};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    ${({ $right }) => $right && 'text-align: right;'}
  }
`;

export const StyledServiceTableText = styled(Typography)<{
  $right?: boolean;
}>`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
    text-wrap: initial;

    ${({ $right }) => $right && 'text-align: right;'}
  }
`;

import styled from 'styled-components';
import { Grid, Box } from '@mui/material';

export const InvoiceServiceDetailGrid = styled(Grid)`
  && {
    background: #fff;
    box-shadow: 0 1px 1px 0 #999;
    padding: 8px 16px;
    min-height: 56px;
    margin-bottom: 2px;
  }
`;

export const InvoiceServiceDescription = styled(Box)`
  && {
    max-height: 200px;
    overflow-y: auto;
  }
`;

import styled from 'styled-components';

export const DialogTitleWrapper = styled.div`
  .MuiDialogTitle-root {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 24px;
  }
`;

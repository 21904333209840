import styled from 'styled-components';

type FlexCenterTypes = {
  $flexWrap?: string;
};

export default styled.div<FlexCenterTypes>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: ${(props) => props.$flexWrap};
  border-bottom: 1px solid #e8e9eb;
  padding-bottom: 8px;
  padding-top: 8px;

  &:last-child {
    border-bottom: 0;
  }
`;

import React, { FC, useMemo, useState } from 'react';

import { Stack, Tooltip, IconButton } from '@mui/material';

import dayjs from 'dayjs';

import { ReactComponent as MainContactOutlinedIcon } from 'assets/icons/MainContactOutlined.svg';
import { ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import MainContactDialog from 'molecules/MainContactDialog';
import { StyledCellText2 } from 'molecules/Table/styles/Text.styles';
import { StyledCellContainer } from 'molecules/Table/styles';
import Table from 'molecules/Table';
import ButtonMenu from 'molecules/ButtonMenu';
import { getContactDotsOptions } from 'organisms/Companies/Companies';
import { palette } from 'utils/styles/variables';
import { UserCompany } from 'store/user/userSlice';

export interface UserRow {
  id: string;
  companyHsId: string;
  name: string;
  surname: string;
  email: string;
  last_logged_time: string;
  is_main: boolean;
  company: UserCompany[];
}

const CompanyContacts: FC<{
  company_hs_id: string;
  users: UserRow[];
  onChangePassword: (r: UserRow) => void;
  onDelete: (r: UserRow) => void;
  onUpdate: () => void;
}> = ({ company_hs_id, users, onChangePassword, onDelete, onUpdate }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const [selected, setSelected] = useState<UserRow | null>(null);

  const handleSelect = (row: UserRow, key: string) => {
    if (key === 'password') {
      onChangePassword(row);
    }

    if (key === 'delete') {
      onDelete(row);
    }

    if (key === 'main') {
      setSelected(row);
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'name',
        label: 'Full name',
        sortable: false,
        render: ({ name, surname }: UserRow) => (
          <StyledCellText2>
            {name} {surname}
          </StyledCellText2>
        ),
      },
      {
        key: 'email',
        label: 'Email',
        sortable: false,
        render: ({ email }: UserRow) => (
          <StyledCellText2>{email}</StyledCellText2>
        ),
      },
      {
        key: 'last_logged_time',
        label: 'Last logged',
        sortable: false,
        render: ({ last_logged_time }: UserRow) => (
          <StyledCellText2>
            {last_logged_time
              ? dayjs(dayjs(last_logged_time)).format('DD MMM YYYY')
              : ''}
          </StyledCellText2>
        ),
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        render: (row: UserRow) => {
          let is_main = false;
          const { company } = row;
          const rowCompany = company?.find(
            (item: UserCompany) =>
              Number(item.company_hs_id) === Number(company_hs_id)
          );

          if (rowCompany) {
            is_main = rowCompany.is_main;
          }

          return (
            <StyledCellContainer $right $hStack $size="small">
              {user.userType !== ADMIN ? (
                <>
                  {is_main && (
                    <Tooltip title="This contact information is used for integration with Quickbooks">
                      <span>
                        <IconButton disabled>
                          <MainContactOutlinedIcon
                            width="20px"
                            height="20px"
                            color={palette.success[500]}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  <ButtonMenu
                    options={getContactDotsOptions(is_main)}
                    onSelect={(k: string) => handleSelect(row, k)}
                  />
                </>
              ) : (
                <Stack pr={2}>
                  {is_main ? (
                    <Tooltip title="This contact information is used for integration with Quickbooks">
                      <MainContactOutlinedIcon
                        width="20px"
                        height="20px"
                        color={palette.success[500]}
                      />
                    </Tooltip>
                  ) : null}
                </Stack>
              )}
            </StyledCellContainer>
          );
        },
      },
    ],
    [company_hs_id]
  );

  return (
    <>
      <Table
        data={users}
        columns={columns}
        idKey="id"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        size="small"
      />

      {selected && (
        <MainContactDialog
          company_hs_id={company_hs_id}
          user={selected}
          onClose={() => {
            setSelected(null);
            onUpdate();
          }}
        />
      )}
    </>
  );
};

export default CompanyContacts;

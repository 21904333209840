import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PageWrapper from 'templates/PageWrapper/PageWrapper';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import Link from 'atoms/Link/Link';

const InvoicePdfPage = () => {
  const [pdf, setPdf] = useState('');
  const { invoiceQbID } = useParams();

  useEffect(() => {
    // todo add catch block
    axios
      .get(`${process.env.REACT_APP_REPORT_SERVICE}/invoices/report`, {
        params: {
          ids: invoiceQbID,
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' })
        );
        setPdf(url);
      });
  }, [invoiceQbID]);

  return (
    <PageWrapper title="Invoice">
      <Box display="flex" justifyContent="end">
        <iframe src={`${pdf}`} title="invoice" height="1000px" width="100%" />
        <Link to="/invoices" $color={red[500]}>
          <CloseIcon />
        </Link>
      </Box>
    </PageWrapper>
  );
};

export default InvoicePdfPage;

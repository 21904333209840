import styled from 'styled-components';
import { Stack, TablePagination, TextField, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

const StyledStackPerPage = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    height: 28px;
    padding: 10px 0;
    align-items: center;
    gap: 3px;
  }
`;

const StyledLabelPerPage = styled(Typography)`
  && {
    color: ${neutrals[400]};
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

const StyledSelectPerPage = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      height: 21px;
      input {
        border-radius: 6px;
        border: 1px solid ${neutrals[400]};
        background: #fff;
      }
      &:hover fieldset {
        border: 1px solid ${neutrals[400]};
      }
    }
    .MuiSelect-select {
      padding: 0 8px !important;
      padding-right: 19px !important;
      margin-top: 1px;

      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .MuiSvgIcon-root {
      right: -1px !important;
    }
  }
`;

const StyledTablePagination = styled(TablePagination)`
  && {
    height: 56px;
    width: 98%;
    padding: 0 12px !important;

    display: block;
    .MuiTablePagination-select:focus {
      background: transparent;
    }
    .MuiTablePagination-spacer {
      flex: none;
    }
    .MuiTablePagination-toolbar {
      height: 56px;
      padding: 0;
    }
  }
`;

export {
  StyledStackPerPage,
  StyledLabelPerPage,
  StyledSelectPerPage,
  StyledTablePagination,
};

import React, { FC, useCallback, useState } from 'react';

import { Alert, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import Modal from 'molecules/Modal';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';

import axios from 'axios';

import { ZStreamProjectSyncDialogProps } from 'organisms/ProjectDialogs/types';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';

import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import {
  StyledMenuItem,
  StyledSelect,
} from 'organisms/ProjectDialogs/CloudOpsProject.styles';
import { neutrals } from 'utils/styles/color';
import CloudOpsProjectTermsDialog from 'organisms/ProjectDialogs/CloudOpsProjectTermsDialog';

type projectType = 'CO' | 'NOT_CO';

const CloudOpsProjectDialog: FC<ZStreamProjectSyncDialogProps> = ({
  project,
  currency,
  companyHsId,
  onClose,
}) => {
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [showTermsDialog, setShowTermsDialog] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] = useState<
    string | CloudOpsProjectsType | null
  >(null);
  const [type, setType] = useState<projectType>();

  const handleCreateProject = useCallback(async () => {
    setFormSubmitted(true);
    if (
      cloudOpsProjectSelected &&
      typeof cloudOpsProjectSelected === 'object' &&
      type
    ) {
      try {
        await axios.post(`${process.env.REACT_APP_USER_SERVICE}/projects`, {
          company_hs_id: Number(companyHsId),
          project_id:
            typeof cloudOpsProjectSelected === 'object'
              ? cloudOpsProjectSelected?.id_str
              : 0,
          minimum_monthly_budget: 0,
          monthly_budget_in_hours: 0,
          over_budget_hourly_rate: 0,
          type,
        });

        setFormSubmitted(false);

        // setCloudOpsProjectSelected(null);

        if (type === 'CO') {
          setShowTermsDialog(true);
          return;
        }

        onClose(cloudOpsProjectSelected);
      } catch (err) {
        setAlert({ error: true, message: err.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
      }
    }
  }, [type, cloudOpsProjectSelected, companyHsId, onClose, project]);

  const handleTermsClose = () => {
    if (cloudOpsProjectSelected) {
      onClose(cloudOpsProjectSelected as CloudOpsProjectsType);
    }
  };

  if (showTermsDialog) {
    return (
      <CloudOpsProjectTermsDialog
        mode="edit"
        project={cloudOpsProjectSelected as CloudOpsProjectsType}
        onClose={handleTermsClose}
        currency={currency}
        companyHsId={companyHsId}
      />
    );
  }

  return (
    <Modal
      open
      maxWidth="sm"
      title="Add new project"
      subtitle="Select project and set its type."
      onClose={() => onClose()}
      transitionDuration={0}
    >
      <>
        <ModalContent
          $gap={16}
          sx={{ overflowY: 'hidden', pb: '8px !important' }}
        >
          {alert.message ? (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          ) : undefined}
          <ZStreamProjectAutoComplete
            disabled={false}
            submitted={formSubmitted}
            project={project}
            onChange={setCloudOpsProjectSelected}
          />
          <FormControl fullWidth error={formSubmitted && !type}>
            <InputLabel
              shrink
              id="demo-simple"
              sx={{
                color: `${neutrals[400]} !important`,
              }}
            >
              Type
            </InputLabel>
            <StyledSelect
              labelId="demo-simple"
              id="demo-simple"
              value={type}
              label="Type"
              displayEmpty
              onChange={(e) => setType(e.target.value as projectType)}
              input={<OutlinedInput value={type || ''} notched label="Type" />}
            >
              <StyledMenuItem value="CO">CloudOps</StyledMenuItem>
              <StyledMenuItem value="NOT_CO">Not CloudOps</StyledMenuItem>
            </StyledSelect>
          </FormControl>
        </ModalContent>

        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={handleCreateProject}>
            Add project
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectDialog;

import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { Alert, Paper, Modal, Typography, Grid, Stack } from '@mui/material';
import {
  HeadlessPageWrapper,
  MobilePageWrapper,
} from 'templates/PageWrapper/PageWrapper';
import FilterDetails from 'molecules/Filter/FilterDetails';
import InvoiceStatusSelect from 'atoms/StatusSelect/InvoiceStatusSelect';
import InvoicesTable from 'organisms/InvoicesTable/InvoicesTable';
import { CustomModal } from '../DashboardPage/DashboardPage.styles';
import { MobileInvoiceList } from 'molecules/MobileInvoiceList/MobileInvoiceList';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { StyledGridItem } from 'pages/InvoicesPage/InvoicesPage.styles';
import ButtonDateRangePicker from 'molecules/Calendar/ButtonDateRangePicker';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import CurrencyStatusSelect from 'atoms/StatusSelect/CurrencyStatusSelect';

import axios from 'axios';
import { useBaoSelector } from 'utils/hooks/redux';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { statusType } from 'utils/constants/invoiceStatus';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { debounce } from 'lodash-es';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';

const InvoicesPage = () => {
  const { width } = useWindowDimensions();
  const searchParams = new URLSearchParams(useLocation().search);
  const message = searchParams.get('payment') || '';

  const searchRef = useRef<HTMLInputElement | null>(null);

  const [search, setSearch] = useState<string | undefined>('');
  const [currency, setCurrency] = useState<string>('all');
  const [statusFilter, setStatusFilter] = useState<statusType>('all');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[null | Date, null | Date]>([
    null,
    null,
  ]);

  const { currentCompany } = useBaoSelector((state) => state.common);
  const { value: user } = useBaoSelector((state) => state.user);
  const invoices = useBaoSelector((state) => state.invoices);

  const isAdmin = user.userType === SUPER_ADMIN || user.userType === ADMIN;

  const invoiceDetails = useMemo(() => {
    const { totalCount, totalAmount } = invoices;
    return [
      { label: 'Invoices', value: totalCount ? Number(totalCount) : '0' },
      {
        label: 'Total',
        value: totalAmount
          ? currencyFormatter(Number(totalAmount), 'usd')
          : '$0.00',
      },
    ];
  }, [invoices]);

  const handleClose = useCallback(() => setOpen(false), []);

  /** debounce handler for two types: textfield and autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event?.target?.value || '');
    }, 1000),
    []
  );

  const onReset = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearch('');
  }, []);

  useEffect(() => {
    if (message === 'cancelled' || message === 'succeeded') {
      const url = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, url);
      setOpen(true);
      localStorage.removeItem('pay');
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    if (!search) {
      setCompanies([]);
      return;
    }

    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company`, {
        params: {
          name: search,
          limit: 5,
        },
      })
      .then(({ data }) => {
        if (data.companies) {
          setCompanies(
            data.companies.map(({ name }: { name: string }) => name)
          );
        } else {
          setCompanies([]);
        }
      });
  }, [search]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (width < 600 && !isAdmin) {
    return (
      <MobilePageWrapper
        title={
          <MobileInvoicesTitle variant="h2" my={3}>
            Invoices
          </MobileInvoicesTitle>
        }
      >
        <Grid container spacing={2}>
          <StyledGridItem
            item
            xs={12}
            sm={12}
            sx={{
              margin: '0 20px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              '.MuiInput-root, input': {
                height: '20px !important',
                fontSize: '1rem !important',
                svg: {
                  width: '12px',
                  height: '12px',
                },
              },
              '.MuiInputAdornment-root svg': {
                width: '12px',
                height: '12px',
              },
            }}
          >
            <InvoiceStatusSelect isComp onChange={(e) => setStatusFilter(e)} />
            <FilterAutocomplete
              isFreeSolo={!!search}
              value={search}
              options={[]}
              placeholder="Search by: Invoice #"
              tooltip="Filter by invoice"
              onChange={debouncedChangeHandler}
              onReset={onReset}
            />
          </StyledGridItem>
          {error && (
            <Grid item xs={12} sm={12}>
              <Alert severity="error" sx={{ margin: '10px 16px' }}>
                {error}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <MobileInvoiceList
              company={
                currentCompany ? String(currentCompany.company_hs_id) : null
              }
              status={statusFilter}
              search={search}
            />
          </Grid>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CustomModal>
            {message === 'cancelled' && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2 }}
              >
                Error
              </Typography>
            )}
            <Typography id="modal-modal-description">
              {message === 'cancelled'
                ? 'Payment was unsuccessful'
                : 'Payment is in progress'}
            </Typography>
          </CustomModal>
        </Modal>
      </MobilePageWrapper>
    );
  }

  const shouldShowInvoicesDetails = (() => {
    if (
      invoices.rows.length === 1 &&
      invoices.rows[0].invoiceQbNumber === search
    )
      return false;

    if (dateRange[0] || dateRange[1]) return true;

    if (statusFilter !== 'all') return true;

    return !!search;
  })();

  return (
    <HeadlessPageWrapper>
      <>
        {error && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error}
          </Alert>
        )}

        <Paper
          sx={{
            // FIXME: controls rendering of InvoiceStatusSelect dropdown
            position: 'relative',
            '.invoice-status-select .MuiPaper-root': {
              top: '46px !important',
              left: `${!dateRange[0] ? '88px' : '384px'} !important`,
            },
          }}
        >
          <StyledFilterWrapper $multipleRow>
            <Stack direction="row" gap={3} flexWrap="wrap" py="20px">
              <ButtonDateRangePicker
                tooltip="Filter by date"
                onChange={setDateRange}
              />

              <InvoiceStatusSelect
                isComp
                onChange={(e) => setStatusFilter(e)}
              />

              {isAdmin && (
                <CurrencyStatusSelect
                  shouldNoCurrencyHidden
                  onChange={setCurrency}
                />
              )}

              {!isAdmin ? (
                <FilterAutocomplete
                  isFreeSolo={!!search}
                  value={search}
                  options={[]}
                  placeholder="Search by: Invoice #"
                  tooltip="Filter by invoice"
                  onChange={debouncedChangeHandler}
                  onReset={onReset}
                />
              ) : (
                <FilterAutocomplete
                  isFreeSolo={!!search}
                  value={search}
                  options={companies}
                  placeholder="Search by: Company name, Invoice #"
                  tooltip="Filter by invoice # or company name"
                  onChange={debouncedChangeHandler}
                  onReset={onReset}
                />
              )}
            </Stack>

            <FilterDetails
              $multipleRow
              show={!invoices.isLoading && shouldShowInvoicesDetails}
              data={invoiceDetails}
            />
          </StyledFilterWrapper>

          <InvoicesTable
            search={search}
            status={statusFilter}
            dateRange={dateRange}
            currency={currency}
            onError={setError}
            company={
              currentCompany ? String(currentCompany.company_hs_id) : null
            }
            companyColumn={
              user.userType === SUPER_ADMIN || user.userType === ADMIN
            }
          />
        </Paper>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CustomModal>
            {message === 'cancelled' && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2 }}
              >
                Error
              </Typography>
            )}
            <Typography id="modal-modal-description">
              {message === 'cancelled'
                ? 'Payment was unsuccessful'
                : 'Payment is in progress'}
            </Typography>
          </CustomModal>
        </Modal>
      </>
    </HeadlessPageWrapper>
  );
};

export default InvoicesPage;

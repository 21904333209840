import React, { FC } from 'react';

import { Box } from '@mui/material';
import { AccountDetailContent } from './AccountDetail.styles';

export const AccountDetail: FC<{
  title: string;
  subtitle?: string;
  value: string;
}> = ({ title, subtitle, value }) => (
  <AccountDetailContent>
    <Box component="span">
      {title}:{subtitle && <Box component="span">{subtitle}</Box>}
    </Box>
    <Box component="span">{value}</Box>
  </AccountDetailContent>
);

import React, { useState, useCallback, useEffect } from 'react';

import Companies from 'organisms/Companies/Companies';
import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';

import axios from 'axios';
import { debounce, snakeCase } from 'lodash-es';
import { Order } from 'organisms/InvoicesTable/types';

type sortByType = 'user_type' | 'name' | 'email';

const ContactsPage = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState<string | undefined>('');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [update, setUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<sortByType>('name');

  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event.target.value);
    }, 300),
    []
  );

  const onReset = useCallback(() => {
    setSearch('');
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: sortByType
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    (async () => {
      await fetchPageData(page + 1);
    })();
  }, [limit, page, update, order, orderBy]);

  useEffect(() => {
    (async () => {
      setPage(0);
      await fetchPageData(1);
    })();
  }, [search]);

  async function fetchPageData(_page: number) {
    setLoading(true);
    const params: Record<string, unknown> = { search, page: _page, limit };

    if (orderBy) {
      params.sort = snakeCase(orderBy); // `sort[${snakeCase(orderBy)}]=${order}`;
      params.sort_order = order;
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/users?user_types=company_admin,company_viewer`,
      { params }
    );

    if (data.users) {
      setCount(data.count);
      setUsers(data.users);
    }
    setLoading(false);
  }

  return (
    <HeadlessPageWrapper>
      <StyledPaper>
        <StyledFilterWrapper>
          <FilterAutocomplete
            isFreeSolo={!!search}
            value={search}
            options={[]}
            placeholder="Search by: Name, Email, Company name"
            tooltip="Search by: Name, Email, Company name"
            onChange={debouncedChangeHandler}
            onReset={onReset}
          />
        </StyledFilterWrapper>
        <Companies
          users
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          loading={loading}
          setLimit={setLimit}
          setPage={setPage}
          update={update}
          setUpdate={setUpdate}
          companiesRow={users}
          totalCount={count}
          page={page}
        />
      </StyledPaper>
    </HeadlessPageWrapper>
  );
};

export default ContactsPage;

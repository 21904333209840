import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCompany, UserState } from 'store/user/userSlice';
import { getCompany, setCompany } from 'utils/helpers/storage';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';

/* export const fake_companies: UserCompany[] = [
  {
    company_hs_id: 1,
    name: 'The first company',
  },
  {
    company_hs_id: 2,
    name: 'Most boring company name',
  },
  {
    company_hs_id: 3,
    name: 'Company',
  },
  {
    company_hs_id: 4,
    name: 'The very very very long company name',
  },
  {
    company_hs_id: 5,
    name: 'The very very very very very very long company name',
  },
]; */

export interface CommonState {
  currentCompany: UserCompany | null;
}

// Define the initial state using that type
const initialState: CommonState = {
  currentCompany: null,
};
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCurrentCompany: (state, action: PayloadAction<UserCompany | null>) => {
      state.currentCompany = action.payload;
      if (!action.payload) return;
      setCompany(action.payload);
    },
    autoSetCurrentCompany: (state, action: PayloadAction<UserState>) => {
      if (
        !action.payload ||
        action.payload.userType === SUPER_ADMIN ||
        action.payload.userType === ADMIN
      )
        return;
      const { companies } = action.payload;
      const company = getCompany();

      if (!companies) return;

      if (checkSavedCompanyExist(company)) {
        state.currentCompany = company;
        return;
      }

      const [firstCompany] = companies;
      state.currentCompany = firstCompany;
      setCompany(firstCompany);

      function checkSavedCompanyExist(savedCompany: UserCompany | null) {
        if (!savedCompany) return false;
        return Boolean(
          companies.find((c) => c.company_hs_id === savedCompany?.company_hs_id)
        );
      }
    },
  },
});

export const { setCurrentCompany, autoSetCurrentCompany } = commonSlice.actions;

export default commonSlice.reducer;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Card, CardHeader, Box, Typography, Stack } from '@mui/material';
import PageWrapper, {
  MobilePageWrapper,
} from 'templates/PageWrapper/PageWrapper';
import PaymentOptionRadio from 'molecules/PaymentOptionRadio/PaymentOptionRadio';
import AddPaymentMethodDialog from 'organisms/AddPaymentMethodDialog/AddPaymentMethodDialog';
import { AntSwitch } from 'atoms/AntSwitch';
import { fetchPaymentMethods } from 'molecules/PaymentOptionRadio/helpers';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import PaymentMethod from 'molecules/PaymentMethod/PaymentMethod';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  Container,
  MobilePaymentSettingsContent,
  MobilePaymentTermsContent,
} from './PaymentPage.styles';
import {
  ContainedBtn,
  OutlinedBtn,
} from 'pages/AccountPage/AccountPage.styles';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import Link from 'atoms/Link/Link';

const PaymentPage = () => {
  const { width } = useWindowDimensions();

  const user = useBaoSelector((state) => state.user.value);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const [state, setState] = useState('');
  const [primary, primaryChange] = useState<string>('');
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [autoPayment, setAutoPayment] = useState<boolean>(false);
  const [paymentsLength, setPaymentsLeng] = useState<number>(0);
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const termsAndConditions = useCallback(
    () => (
      <>
        I confirm that I am authorized to initiate transfers from the designated
        accounts and I authorize such transactions from the designated accounts
        each month, via automatic electronic withdrawal or card payment. I will
        not dispute the scheduled transactions with my bank or card company;
        provided the transactions correspond to the terms indicated in this
        authorization form. <br /> <br />
        I understand that this authorization will remain in effect until I
        cancel it, and I agree to update my settings on the Zazmic-Connect for
        any changes in my account information or termination of this
        authorization at least 15 days prior to the next invoice due date. If
        the payment date falls on a weekend or holiday, I understand that the
        payment may be executed on the next business day. <br /> <br />
        In the case of an ACH transaction being rejected for Non-Sufficient
        Funds (NSF) I understand that Zazmic Inc. may at its sole discretion
        attempt to process the charge again within 30 days, and agree to an
        additional $50 charge for each attempt returned NSF which will be
        initiated as a separate transaction from the authorized recurring
        payment. I acknowledge that the origination of ACH transactions to my
        account must comply with the provisions of U.S. law. I also understand
        that the amount of the payments may vary each month and the monthly
        invoice from Zazmic, Inc will be the only notice of the AutoPay amount
        to be deducted. <br /> <br />
        In the case of payment via cards (E.g., credit card, debit card), I
        agree to pay for the card processing fees that Zazmic Inc. will add to
        the due charges. The card processing fees added by Zazmic are the fees
        due to the card processor and may include regular processing fees,
        conversion fees, international fees. <br /> <br />
        <b style={{ fontWeight: 500 }}>
          If you wish to cancel AutoPay you may do so in the Zazmic-Connect. If
          you have any questions regarding the Auto Payment program, please
          contact us at{' '}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to="mailto:finance@zazmic.com"
            $fontSize="0.875rem"
          >
            finance@zazmic.com
          </Link>
          .
        </b>
      </>
    ),
    []
  );

  const hideCaptcha = useCallback(() => {
    setDeleteDialog(false);
    setShowCaptcha(false);
  }, []);

  const showCaptchaModal = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setDeleteDialog(false);
    setShowCaptcha(true);
  }, []);

  const updateAuto = useCallback(() => {
    if (!currentCompany) return;

    axios
      .patch(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-payment-settings`,
        {
          is_auto_payment: !autoPayment,
          company_hs_id: currentCompany.company_hs_id,
        }
      )
      .then(({ data }) => {
        if (data) {
          setAutoPayment(!autoPayment);
        }
      });
    hideCaptcha();
  }, [hideCaptcha, autoPayment, currentCompany]);

  useEffect(() => {
    if (!currentCompany) return;

    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-settings`,
        {
          params: {
            company_hs_id: currentCompany.company_hs_id,
          },
        }
      )
      .then(({ data }) => {
        fetchPaymentMethods({ currentCompany, customer: true }).then((res) => {
          const mainPaymentMethod = res.filter((p: PMethod) => p.isMain) || [];
          setPaymentMethods(mainPaymentMethod);
          setPaymentsLeng(res.length);
          if (res.length === 0) {
            setAutoPayment(false);
          } else {
            setAutoPayment(data.is_auto_payment);
          }
        });
      });
  }, [addingPaymentMethod, state, primary, currentCompany]);

  if (width < 600 && !isSuperAdmin) {
    if (deleteDialog) {
      return (
        <MobilePageWrapper
          title={
            <MobileInvoicesTitle variant="h2" my={3}>
              {autoPayment ? 'Payment Settings' : 'Auto payment'}
            </MobileInvoicesTitle>
          }
        >
          <MobilePaymentTermsContent>
            {!autoPayment ? (
              <>
                <Typography component="h5">Auto payment</Typography>
                <Typography>
                  Please confirm if you wish to deactivate the automatic
                  payment.
                </Typography>
              </>
            ) : (
              <>
                <Typography component="h5">Terms and Conditions</Typography>
                <Typography>{termsAndConditions()}</Typography>
              </>
            )}
            <Stack direction="row" spacing={1} pt={2}>
              <OutlinedBtn
                variant="outlined"
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </OutlinedBtn>
              <ContainedBtn
                variant="contained"
                type="submit"
                onClick={showCaptchaModal}
              >
                {autoPayment ? 'Confirm' : 'Accept'}
              </ContainedBtn>
            </Stack>
          </MobilePaymentTermsContent>
        </MobilePageWrapper>
      );
    }

    return (
      <MobilePageWrapper
        title={
          <MobileInvoicesTitle variant="h2" my={3}>
            Payment settings
          </MobileInvoicesTitle>
        }
      >
        <MobilePaymentSettingsContent>
          <Box>
            <Typography>Auto payment</Typography>
            {paymentsLength !== 0 && paymentMethods.length > 0 && (
              <AntSwitch
                checked={autoPayment}
                onChange={(e) => {
                  e.preventDefault();
                  setDeleteDialog(true);
                }}
              />
            )}
          </Box>
          <Box>
            <PaymentOptionRadio
              onPrimaryChange={primaryChange}
              stateChanger={setState}
              dialogOpened={addingPaymentMethod}
              onDialogOpen={setAddingPaymentMethod}
            />
          </Box>
        </MobilePaymentSettingsContent>

        <AddPaymentMethodDialog
          addingPaymentMethod={addingPaymentMethod}
          onAddingPaymentMethod={setAddingPaymentMethod}
        />

        {showCaptcha ? (
          <CaptchaModal
            onComplete={updateAuto}
            onClose={hideCaptcha}
            className="mobile-modal"
          />
        ) : (
          <span />
        )}
      </MobilePageWrapper>
    );
  }

  return (
    <PageWrapper title="Payment settings">
      <Container>
        <Card sx={{ minHeight: '230px !important' }}>
          <Box sx={{ padding: '12px 16px 16px 16px' }}>
            <PaymentOptionRadio
              onPrimaryChange={primaryChange}
              stateChanger={setState}
              dialogOpened={addingPaymentMethod}
              onDialogOpen={setAddingPaymentMethod}
            />
          </Box>
        </Card>

        <Card sx={{ minHeight: '230px' }}>
          <CardHeader title="Auto Payment" avatar={<CardAvatar />} />
          <Box ml={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="auto"
              marginRight="16px"
              marginTop="20px"
              marginBottom="30px"
            >
              <Typography color={autoPayment ? '#3A8E16' : '#EF4444'}>
                {autoPayment ? 'ENABLED' : 'DISABLED'}{' '}
              </Typography>
              {paymentsLength !== 0 && paymentMethods.length > 0 && (
                <AntSwitch
                  checked={autoPayment}
                  onChange={(e) => {
                    e.preventDefault();
                    setDeleteDialog(true);
                  }}
                />
              )}
            </Box>
            <Box marginBottom="40px">
              {paymentsLength === 0 && (
                <Typography
                  marginRight="16px"
                  color="#484E56"
                  fontSize="12px"
                  fontWeight={400}
                  lineHeight="18px"
                >
                  Add payment method to be able to enable auto payment
                </Typography>
              )}
              {autoPayment &&
                paymentsLength > 0 &&
                paymentMethods.map((paymentMethod) => (
                  <PaymentMethod method={paymentMethod} />
                ))}
            </Box>
          </Box>
        </Card>
      </Container>

      <AddPaymentMethodDialog
        addingPaymentMethod={addingPaymentMethod}
        onAddingPaymentMethod={setAddingPaymentMethod}
      />

      <Modal
        open={deleteDialog}
        maxWidth={autoPayment ? 'sm' : 'lg'}
        title={
          autoPayment
            ? 'Disabling auto payment'
            : 'Auto payment terms and conditions'
        }
        subtitle={
          autoPayment
            ? `Disabling automatic payment for Zazmic-Connect won't initiate
               invoice payments on their due date. You can reactivate it later
               on the Payment settings page.`
            : 'To enable auto payment please read and accept payment terms and conditions'
        }
        onClose={() => setDeleteDialog(false)}
      >
        <>
          {!autoPayment && (
            <ModalContent>
              <ModalContentText>{termsAndConditions()}</ModalContentText>
            </ModalContent>
          )}
          <ModalActions>
            <ModalCloseButton
              autoFocus
              size="medium"
              variant="outlined"
              onClick={() => setDeleteDialog(false)}
            >
              {autoPayment ? 'Cancel' : 'Decline'}
            </ModalCloseButton>
            <ModalSubmitButton
              size="medium"
              variant="contained"
              onClick={showCaptchaModal}
            >
              {autoPayment ? 'Confirm' : 'Accept'}
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      {/* <Dialog maxWidth="md" open={deleteDialog}> */}
      {/*   <DialogTitle> */}
      {/*     {} */}
      {/*   </DialogTitle> */}
      {/*   {!autoPayment && ( */}
      {/*     <DialogContent dividers> */}
      {/*       <div */}
      {/*         style={{ */}
      {/*           minHeight: '490px', */}
      {/*           overflow: 'hidden', */}
      {/*         }} */}
      {/*       > */}
      {/*         {termsAndConditions()} */}
      {/*       </div> */}
      {/*     </DialogContent> */}
      {/*   )} */}
      {/*   <DialogActions> */}
      {/*     <CancelButtonWrapper> */}
      {/*       <Button */}
      {/*         variant="outlined" */}
      {/*         autoFocus */}
      {/*         style={{ borderColor: '#6f9fe9', color: '#6f9fe9' }} */}
      {/*         onClick={() => setDeleteDialog(false)} */}
      {/*       > */}
      {/*         Cancel */}
      {/*       </Button> */}
      {/*     </CancelButtonWrapper> */}
      {/*     <ConfirmButtonWrapper> */}
      {/*       <Button */}
      {/*         variant="contained" */}
      {/*         size="medium" */}
      {/*         onClick={showCaptchaModal} */}
      {/*       > */}
      {/*         {!autoPayment ? 'Accept' : 'Delete'} */}
      {/*       </Button> */}
      {/*     </ConfirmButtonWrapper> */}
      {/*   </DialogActions> */}
      {/* </Dialog> */}

      {showCaptcha ? (
        <CaptchaModal onComplete={updateAuto} onClose={hideCaptcha} />
      ) : (
        <span />
      )}
    </PageWrapper>
  );
};

export default PaymentPage;

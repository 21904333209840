import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import { UserCompany } from 'store/user/userSlice';

export const fetchPaymentMethods = ({
  currentCompany,
  invoiceId,
  customer,
}: {
  currentCompany?: UserCompany | null;
  invoiceId?: string;
  customer?: boolean;
}) => {
  const params: Record<string, unknown> = {};

  if (currentCompany) params.company_hs_id = currentCompany.company_hs_id;

  if (invoiceId) params.invoice_number = invoiceId;

  if (customer && !Object.keys(params).length) return Promise.resolve([]);

  // todo add catch block
  return axios
    .get(`${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-methods`, {
      params,
    })
    .then(({ data }) =>
      data.payment_methods.map((paymentMethod: Record<string, unknown>) =>
        mapKeys(paymentMethod, (v, k) => camelCase(k))
      )
    );
};

export const getCanadianExchangeRate = async (usdAmount: number) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BILLING_SERVICE}/exchange-rates/convert`,
    {
      params: {
        amount: usdAmount,
        from: 'USD',
        to: 'CAD',
      },
    }
  );

  return {
    canadianAmount: data.amount,
    exchangeRate: data.exchange_rate.rate,
  };
};

import styled, { css } from 'styled-components';
import { Box, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const Calendar = styled(Box)`
  && {
    width: calc(300px - 32px);
    padding: 16px;

    border-radius: 10px;
    background-color: #fff;
  }
`;

export const YearText = styled(Typography)`
  && {
    color: ${neutrals[700]};
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const Month = styled(Box).attrs<{ disabled: boolean; variant: string }>(
  (props) => ({
    // we can define static props
    type: 'text',

    // or we can define dynamic ones
    disabled: props.disabled || false,
    variant: props.variant || 'default',
  })
)`
  display: flex;
  align-items: center;

  flex: 1 0 21%; /* explanation below */
  text-align: center;
  border-radius: 20px;
  height: 25px;

  color: ${neutrals[700]};

  /* New/FilterText */
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  ${({ variant }: { variant: string; disabled: boolean }) =>
    variant === 'startEnd' &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary.main};
    `}

  ${({ variant }: { variant: string; disabled: boolean }) =>
    variant === 'start' &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary.main};
      border-top-right-radius: initial;
      border-bottom-right-radius: initial;
    `}

    ${({ variant }) =>
    variant === 'end' &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary.main};
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
    `}

    ${({ variant }) =>
    variant === 'selected' &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.secondary};
      border-radius: initial;
    `}

    ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          opacity: 0.5;
          &:hover {
            background-color: transparent;
          }
        `
      : css`
          cursor: pointer;
          opacity: 1;
        `}
`;

export const Arrow = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;
    width: 30px;

    font-weight: bold;
    font-size: 0.875rem;
    cursor: pointer;
  }
`;

export const Row = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
  }
`;

export const Flex = styled(Box)`
  && {
    display: flex;
  }
`;

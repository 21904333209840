import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data } from 'organisms/InvoicesTable/types';
import { InvoiceDefaultValue } from 'organisms/InvoicesTable/data';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import dayjs from 'dayjs';

export type paymentOptionType = 'creditCard' | 'ach';

type initialStateType = {
  invoice: Data[0];
  open: boolean;
  paymentOption: paymentOptionType;
  step: number;
  autoPayment: boolean;
  error?: string;
  loading: boolean;
};

// Define the initial state using that type
const initialState: initialStateType = {
  invoice: InvoiceDefaultValue,
  open: false,
  paymentOption: 'creditCard',
  step: 0,
  autoPayment: false,
  loading: false,
};

const setPayInvoice = createAsyncThunk(
  'payInvoice/setPayInvoice',
  async ({ invoiceQbNumber, id }: { invoiceQbNumber: string; id: string }) => {
    const { data } = await axios.get<Data[0]>(
      `${process.env.REACT_APP_BILLING_SERVICE}/invoices/${invoiceQbNumber}`
    );

    const { data: invoiceData } = await axios.get<{ state: string }>(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/invoice-status`,
      {
        params: { invoice_id: id },
      }
    );

    if (invoiceData.state) {
      data.status.state = invoiceData.state;
    }

    if (data.status.state === 'paid') {
      return new Error('Invoice is already paid');
    }

    const camelCasedInvoice = mapKeys(data, (v, k) => camelCase(k)) as Data[0];
    const d = new Date(Date.parse(camelCasedInvoice.date));
    return {
      ...camelCasedInvoice,
      date: dayjs(d.toString()).format('MMM DD, YYYY'),
      dueDate: dayjs(camelCasedInvoice.dueDate).format('MMM DD, YYYY'),
    };
  }
);

export const payInvoiceSlice = createSlice({
  name: 'payInvoice',
  initialState,
  reducers: {
    setInvoice: (state, action: PayloadAction<Data[0]>) => {
      state.invoice = action.payload;
      state.open = true;
    },
    setPaymentOption: (state, action: PayloadAction<paymentOptionType>) => {
      state.paymentOption = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setAutoPayment: (state, action: PayloadAction<boolean>) => {
      state.autoPayment = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    exitPayment: (state) => {
      state.open = false;
      state.step = 0;
      state.paymentOption = 'creditCard';
      state.autoPayment = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPayInvoice.fulfilled, (state, action) => {
      // eslint-disable-next-line
      state.invoice = action.payload as any;
    });
    builder.addCase(setPayInvoice.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});

export const {
  setInvoice,
  setPaymentOption,
  setStep,
  exitPayment,
  setAutoPayment,
  setLoading,
} = payInvoiceSlice.actions;

export { setPayInvoice };

export default payInvoiceSlice.reducer;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Stack } from '@mui/material';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import SalesReportTable, { SalesReportRow } from 'organisms/SalesReportTable';
import InvoiceStatusSelect from 'atoms/StatusSelect/InvoiceStatusSelect';
import { ExportCSVButton } from 'organisms/SalesReport/SalesReport.styles';
import { statusType } from 'utils/constants/invoiceStatus';
import { debounce } from 'lodash-es';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { getFilenameFromHeader } from 'organisms/InvoicesTable/helpers';
import Filter from 'organisms/Filter';
import { IFilterOption } from 'organisms/Filter/types';
import CurrencyStatusSelect from 'atoms/StatusSelect/CurrencyStatusSelect';

type filterApplyType = {
  invoice_date_from: string;
  invoice_date_to: string;
  close_date_from: string;
  close_date_to: string;
  company_hs_ids: string;
  accountant_ids: string;
  sales_rep_ids: string;
  invoice_status: string;
  status: string;
};
type FilterOptions = {
  company: { ID: string; Name: string }[];
  SalesRep: { ID: string; Name: string }[];
  Accountant: { ID: string; Name: string }[];
};

const fetchList = async (name: string) => {
  if (name === 'invoice_status') {
    return [
      { ID: 'paid', Name: 'Paid' },
      { ID: 'unpaid', Name: 'Unpaid' },
    ];
  }

  const response: AxiosResponse<FilterOptions> = await axios.get(
    `${process.env.REACT_APP_REPORT_SERVICE}/deal/search/filter`
  );

  let key: 'company' | 'SalesRep' | 'Accountant' | '' = '';
  switch (name) {
    case 'company_name':
      key = 'company';
      break;
    case 'sale_rep_name':
      key = 'SalesRep';
      break;
    case 'account_manager_name':
      key = 'Accountant';
      break;
    default:
      break;
  }

  if (!key) return [];

  return (response.data?.[key] || []) as { ID: string; Name: string }[];
};

const options: Array<IFilterOption> = [
  {
    code: 'sale_rep_name',
    title: 'Sales Rep name',
    active: true,
    fetchList,
  },
  {
    code: 'account_manager_name',
    title: 'Account manager',
    fetchList,
  },
  {
    code: 'company_name',
    title: 'Company name',
    fetchList,
  },
  { code: 'invoice_date', title: 'Invoice date' },
  // {
  //   code: 'invoice_status',
  //   title: 'Invoice status',
  //   fetchList,
  // },
  { code: 'close_date', title: 'Closed-won date' },
];

const SalesReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<{
    deals: SalesReportRow[];
    count: number;
  }>({ deals: [], count: 0 });

  const [search, setSearch] = useState<string | undefined>('');
  const [currency, setCurrency] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<statusType>('all');
  const [multiFilter, setMultiFilter] = useState<Partial<filterApplyType>>({});

  const sortRef = useRef<string>(`sort[invoice_date]=desc`);

  /** debounce handler for two types: textfield and autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event?.target?.value || '');
    }, 1000),
    []
  );

  const handleFilterChange = useCallback(
    (nextFilterValue: { [key: string]: string[] }) => {
      const filterApply = Object?.entries(nextFilterValue).reduce(
        (acc, [key, value]) => {
          if (key === 'invoice_date' && value && value[0] && value[1]) {
            acc.invoice_date_from = dayjs(dayjs(value[0])).format('YYYY-MM-DD');
            acc.invoice_date_to = dayjs(dayjs(value[1])).format('YYYY-MM-DD');
          }

          if (key === 'close_date' && value && value[0] && value[1]) {
            acc.close_date_from = dayjs(dayjs(value[0])).format('YYYY-MM-DD');
            acc.close_date_to = dayjs(dayjs(value[1])).format('YYYY-MM-DD');
          }

          if (key === 'company_name' && value[0]) {
            acc.company_hs_ids = value?.join(',');
          }

          if (key === 'account_manager_name' && value[0]) {
            acc.accountant_ids = value?.join(',');
          }

          if (key === 'sale_rep_name' && value[0]) {
            acc.sales_rep_ids = value?.join(',');
          }

          if (key === 'invoice_status' && value[0]) {
            acc.status = value?.join(',');
          }

          return acc;
        },
        {} as filterApplyType
      );

      if (JSON.stringify(filterApply) === JSON.stringify(multiFilter)) return;

      setMultiFilter(filterApply);
    },
    [search, currency, statusFilter, multiFilter]
  );

  useEffect(() => {
    fetchPageData({ page: 1, limit: 20 }, sortRef.current);
  }, [search, currency, statusFilter, multiFilter]);

  const handleExportCsv = async () => {
    const response: AxiosResponse<{
      deals: SalesReportRow[];
      count: number;
    }> = await axios.get(
      `${process.env.REACT_APP_REPORT_SERVICE}/deal/search`,
      {
        params: {
          ...prepareParams(),
          export_csv: true,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );

    const filename = getFilenameFromHeader(response);
    const link = document.createElement('a');
    link.href = response.request?.responseURL;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const fetchPageData = async (
    pagination: { page: number; limit: number },
    sort?: string
  ) => {
    try {
      sortRef.current = sort || '';
      setLoading(true);
      const response: AxiosResponse<{
        deals: SalesReportRow[];
        count: number;
      }> = await axios.get(
        `${process.env.REACT_APP_REPORT_SERVICE}/deal/search?${sort || ''}`,
        {
          params: {
            ...prepareParams(),
            page: pagination.page || 1,
            per_page: pagination.limit || 20,
          },
        }
      );

      setData(response?.data || { deals: [], count: 0 });
      return response?.data || { deals: [], count: 0 };
    } finally {
      setLoading(false);
    }
  };

  function prepareParams() {
    const [region, selectedCurrency] = currency?.split('-');
    return {
      ...(search?.trim() ? { search } : {}),
      ...(statusFilter !== 'all' ? { status: statusFilter } : {}),
      ...(multiFilter || {}),
      currency: selectedCurrency === 'all' ? undefined : selectedCurrency,
      region: region || undefined,
    };
  }

  const isMultiFilterFilled = (() => {
    if (!multiFilter) return false;
    return !Object.values(multiFilter).every((value) => {
      if (Array.isArray(value) && value.length === 0) return true;
      if (typeof value === 'string' && value === '') return true;
      return value === undefined;
    });
  })();

  return (
    <StyledPaper
      sx={{
        // FIXME: controls rendering of InvoiceStatusSelect dropdown
        position: 'relative',
        '.invoice-status-select .MuiPaper-root': {
          top: '45px !important',
          left: `${isMultiFilterFilled ? 110 : 88}px !important`,
        },
      }}
    >
      <StyledFilterWrapper>
        <Stack direction="row" gap={3} flexWrap="wrap" py="20px">
          <Filter options={options} onChange={handleFilterChange} />

          <InvoiceStatusSelect
            isComp
            options={[
              { key: 'Paid', value: 'paid' },
              { key: 'Unpaid', value: 'unpaid' },
            ]}
            onChange={setStatusFilter}
          />

          <CurrencyStatusSelect shouldNoCurrencyHidden onChange={setCurrency} />

          <FilterAutocomplete
            isFreeSolo={!!search}
            value={search}
            options={[]}
            placeholder="Search by: Sales rep name, Account manager, Company name, Invoice #"
            tooltip="Search by: Sales rep name, Account manager, Company name, Invoice #"
            onChange={debouncedChangeHandler}
            onReset={() => setSearch('')}
          />
        </Stack>
        <ExportCSVButton onClick={handleExportCsv}>
          Download CSV
        </ExportCSVButton>
      </StyledFilterWrapper>

      <SalesReportTable
        loading={loading}
        data={data}
        onChange={fetchPageData}
      />
    </StyledPaper>
  );
};

export default SalesReport;

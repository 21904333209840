import React, { FC } from 'react';
import { Card } from '@mui/material';
import {
  StyledCardContent,
  StyledCardHeader,
} from 'molecules/Card/Card.styles';
import type { BlockProps } from './types';

const Block: FC<BlockProps> = ({
  avatar,
  title,
  children,
  action,
  ...props
}) => (
  <Card {...props}>
    <StyledCardHeader
      component="div"
      avatar={avatar}
      title={title}
      action={action}
    />
    <StyledCardContent>{children}</StyledCardContent>
  </Card>
);

export default Block;

export const ADMIN = 'admin';
export const SUPER_ADMIN = 'superadmin';
export const roles = [ADMIN, SUPER_ADMIN] as const;

export function getRoleLabel(role: typeof roles[keyof typeof roles]) {
  switch (role) {
    case ADMIN:
      return 'Admin';
    case SUPER_ADMIN:
      return 'Super Admin';
    default:
      return '';
  }
}

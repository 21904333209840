import styled from 'styled-components';
import { Box, Stack, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';

const StyledFilterWrapper = styled(Stack)<{
  $multipleRow?: boolean;
}>`
  && {
    height: ${({ $multipleRow }) => ($multipleRow ? 'auto' : '64px')};

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;

    ${({ $multipleRow }) =>
      $multipleRow && {
        alignItems: 'start',
      }}
  }
`;

const StyledFilterDetailsWrapper = styled(Stack)<{
  $multipleRow?: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    gap: 5px;

    ${({ $multipleRow }) =>
      $multipleRow && {
        padding: '16px 0',
      }}
  }
`;

const StyledFilterDetailsRow = styled(Box)`
  && {
    display: flex;
    gap: 5px;

    p:first-of-type {
      width: 150px;
      text-align: right;
    }

    p:last-of-type {
      flex: 1;
      text-align: left;
    }
  }
`;

const StyledFilterDetailsText = styled(Typography)`
  && {
    color: ${neutrals[700]};
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export {
  StyledFilterWrapper,
  StyledFilterDetailsWrapper,
  StyledFilterDetailsRow,
  StyledFilterDetailsText,
};

import React, { FC } from 'react';
import TableBody from '@mui/material/TableBody';
import { ITableBodyProps } from '../types';
import TableRow from 'molecules/Table/TableRow';
import EmptyRow from 'molecules/Table/TableRow/EmptyRow';
import LoadingRow from 'molecules/Table/TableRow/LoadingRow';

const CustomTableBody: FC<ITableBodyProps> = ({
  loading,
  emptyText,
  data,
  ...props
}) => {
  if (loading) return <LoadingRow />;

  return (
    <TableBody>
      {data.length > 0 ? (
        data.map((row, index) => (
          <TableRow {...props} data={row} index={index} />
        ))
      ) : (
        <EmptyRow text={emptyText} />
      )}
    </TableBody>
  );
};

export default CustomTableBody;

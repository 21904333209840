import React, { useState, useCallback, useRef } from 'react';

import { Grid, Stack } from '@mui/material';
import PageWrapper from 'templates/PageWrapper/PageWrapper';
import LogTable, { initialValue } from 'organisms/LogTable';
import ButtonDateRangePicker from 'molecules/Calendar/ButtonDateRangePicker';

import axios from 'axios';
import debounce from 'lodash-es/debounce';
import dayjs from 'dayjs';

import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import Block from 'molecules/Card';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';

interface IParams {
  email?: string;
  page: number;
  limit: number;
  date_from?: string;
  date_to?: string;
}

const LogsPage = () => {
  const [dateRange, setDateRange] = useState<[null | Date, null | Date]>([
    null,
    null,
  ]);
  const [email, setEmail] = useState<string>('');

  const searchRef = useRef<HTMLInputElement | null>(null);

  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setEmail(event.target.value);
    }, 300),
    []
  );

  const onReset = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setEmail('');
  }, []);

  const fetchPageData = useCallback(
    async (filter: typeof initialValue) => {
      const { page, limit } = filter;

      const params: IParams = { email, page, limit };

      if (dateRange[0] && dateRange[1]) {
        params.date_from = dayjs(dayjs(dateRange[0])).format('YYYY-MM-DD');
        params.date_to = dayjs(dayjs(dateRange[1])).format('YYYY-MM-DD');
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_NOTIFICATION_SERVICE}/emails/filter`,
        { params }
      );
      const { emails: logs, count } = data;

      return { logs, count };
    },
    [email, dateRange]
  );

  return (
    <PageWrapper title="Logs">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Block
            avatar={<CardAvatar />}
            title="Notifications"
            sx={{ '.MuiCardContent-root': { p: '0 !important' } }}
          >
            <>
              <StyledFilterWrapper>
                <Stack direction="row" gap={3}>
                  <ButtonDateRangePicker
                    tooltip="Select date range"
                    onChange={setDateRange}
                  />

                  <FilterAutocomplete
                    isFreeSolo={!!email}
                    value={email}
                    options={[]}
                    placeholder="Search by: Email"
                    tooltip="Search by: Email"
                    onChange={debouncedChangeHandler}
                    onReset={onReset}
                  />
                </Stack>
              </StyledFilterWrapper>

              <LogTable fetch={fetchPageData} email={email} range={dateRange} />
            </>
          </Block>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default LogsPage;

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid, IconButton, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MobilePageWrapper } from 'templates/PageWrapper/PageWrapper';
import {
  StyledInvoiceDetailsTitle,
  StyledTitle,
  StyledActionStack,
  StyledGridItem,
  StyledPayButton,
} from './InvoiceDetailsPage.styles';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import InvoiceServiceDetail from 'molecules/InvoiceServiceDetail/InvoiceServiceDetail';

import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { Row } from 'organisms/InvoicesTable/types';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  getServiceName,
  handleDownloadInvoice,
} from 'organisms/InvoicesTable/helpers';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';

const InvoiceDetailsPage = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams() as { invoiceId: string };

  const [invoice, setInvoice] = useState<Row | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { value: user } = useBaoSelector((state) => state.user);
  const { width } = useWindowDimensions();

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const onDownload = useCallback(async () => {
    if (!invoice || !invoice?.invoiceQbId) return;

    try {
      setLoading(true);

      await handleDownloadInvoice([invoice.invoiceQbId]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [invoice]);

  const onPay = useCallback(() => {
    if (!invoiceId) return;

    navigate(`/mobile-payment/${invoiceId}`);
  }, [navigate, invoiceId]);

  const handleGoBack = useCallback(() => {
    navigate('/invoices');
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (!invoiceId) return null;

      const response = await axios.get(
        `${process.env.REACT_APP_BILLING_SERVICE}/invoices/${invoiceId}`
      );

      const camelCasedInvoice = mapKeys(response.data, (v, k) =>
        camelCase(k)
      ) as Row;

      setInvoice(camelCasedInvoice);
      return null;
    })();
  }, [invoiceId]);

  useEffect(() => {
    if (width >= 600) {
      navigate(-1);
    }
  }, [navigate, width]);

  const isPayVisible = (() => {
    if (!invoice?.status.state) return false;

    return (
      user.userType !== SUPER_ADMIN &&
      user.userType !== ADMIN &&
      invoice?.status.state !== 'voided' &&
      invoice?.status.state !== 'payment_pending' &&
      invoice?.status.state !== 'paid'
    );
  })();

  return (
    <MobilePageWrapper>
      <StyledInvoiceDetailsTitle>
        <StyledTitle direction="row">
          <IconButton onClick={handleGoBack} sx={{ padding: '0' }}>
            <ArrowIcon />
            Back
          </IconButton>
          <MobileInvoicesTitle variant="h2">#{invoiceId}</MobileInvoicesTitle>
        </StyledTitle>

        <Grid container rowSpacing={1}>
          <StyledGridItem item xs={8}>
            Subtotal
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            {!Number.isNaN(Number(invoice?.subtotal))
              ? currencyFormatter(Number(invoice?.subtotal), invoice?.currency)
              : ''}
          </StyledGridItem>

          <StyledGridItem item xs={8}>
            Zazmic Discount
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            {!Number.isNaN(Number(invoice?.zazmicDiscount))
              ? currencyFormatter(
                  Number(invoice?.zazmicDiscount),
                  invoice?.currency
                )
              : ''}
          </StyledGridItem>

          {invoice?.status.state === 'paid' &&
            Number(invoice?.transactionFee) > 0 && (
              <>
                <StyledGridItem item xs={8}>
                  Payment Processing Fee
                </StyledGridItem>
                <StyledGridItem item xs={4}>
                  {!Number.isNaN(Number(invoice?.transactionFee))
                    ? currencyFormatter(
                        Number(invoice?.transactionFee),
                        invoice?.currency
                      )
                    : ''}
                </StyledGridItem>
              </>
            )}

          <StyledGridItem item xs={8}>
            Tax
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            {!Number.isNaN(Number(invoice?.totalTaxAmount))
              ? currencyFormatter(
                  Number(invoice?.totalTaxAmount),
                  invoice?.currency
                )
              : ''}
          </StyledGridItem>

          <StyledGridItem item xs={8}>
            Invoice total
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            {!Number.isNaN(Number(invoice?.invoiceTotal))
              ? currencyFormatter(
                  Number(invoice?.invoiceTotal),
                  invoice?.currency
                )
              : ''}
          </StyledGridItem>

          <StyledGridItem item xs={8}>
            Payments/Credits
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            {!Number.isNaN(Number(invoice?.paymentsCredits))
              ? currencyFormatter(
                  Number(invoice?.paymentsCredits),
                  invoice?.currency
                )
              : ''}
          </StyledGridItem>

          <StyledGridItem
            item
            xs={8}
            style={{ fontWeight: 600, fontSize: '0.875rem' }}
          >
            Amount Due
          </StyledGridItem>
          <StyledGridItem
            item
            xs={4}
            style={{ fontWeight: 600, fontSize: '0.875rem' }}
          >
            {!Number.isNaN(Number(invoice?.amountDue))
              ? currencyFormatter(Number(invoice?.amountDue), invoice?.currency)
              : ''}
          </StyledGridItem>
        </Grid>

        <StyledActionStack direction="row">
          <LoadingButton
            aria-label="download"
            onClick={onDownload}
            loading={loading}
          >
            <PdfIcon width={32} height={32} />
          </LoadingButton>
          {isPayVisible && (
            <StyledPayButton
              variant="contained"
              size="small"
              color="secondary"
              onClick={onPay}
            >
              Pay
            </StyledPayButton>
          )}
        </StyledActionStack>
      </StyledInvoiceDetailsTitle>

      <Box>
        {(invoice?.services || []).map((service) => (
          <InvoiceServiceDetail key={service.name} service={service} />
        ))}
      </Box>

      <Grid container rowSpacing={1} sx={{ padding: '24px 16px' }}>
        <StyledGridItem item xs={12} sx={{ padding: '20px 0' }}>
          {invoice?.timeLogsLink &&
            (invoice?.companyDisableAccessToZstream === false ||
              isSuperAdmin) && (
              <a
                href={invoice?.timeLogsLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#6F9FE9',
                  textTransform: 'capitalize',
                  fontSize: '12px',
                  fontWeight: 400,
                  textDecoration: 'underline',
                }}
              >
                {`View Tasks ${getServiceName(invoice.timeLogsLink)}`}
              </a>
            )}
        </StyledGridItem>
        <StyledGridItem item xs={12} sx={{ padding: '20px 0' }}>
          {invoice?.invoiceType === 'CO' && (
            <a
              href="https://observability.zazmic.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#6F9FE9',
                textTransform: 'capitalize',
                fontSize: '12px',
                fontWeight: 400,
                textDecoration: 'underline',
              }}
            >
              Observability
            </a>
          )}
        </StyledGridItem>
      </Grid>
    </MobilePageWrapper>
  );
};

export default InvoiceDetailsPage;

import styled from 'styled-components';
import { Button, Stack } from '@mui/material';
import { palette } from 'utils/styles/variables';

export const PaginationWrapper = styled.div`
  .MuiTablePagination-input {
    color: #595f69;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin-right: auto;

    &:hover {
      border-color: #fcde9b;
    }
  }
  .MuiTablePagination-selectLabel {
    color: #999999;
    font-size: 0.75rem;
    font-weight: 500;
  }
  .MuiTablePagination-spacer {
    flex: none;
  }
  .MuiTablePagination-toolbar {
    padding: 15px 20px;
  }
  .MuiTableRow-root {
    background: red;
  }
`;

export const ActionContainerStyled = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 24px;
  }
`;

export const PayButtonStyled = styled(Button)`
  && {
    border-radius: 3px;
    background: ${palette.primary.main};

    color: #fff;
    text-align: justify;

    padding: 2px 24px;

    /* New/ButtonText */
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
  }
`;

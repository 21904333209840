import React, { FC } from 'react';
import Modal from 'molecules/Modal';
import { Stack } from '@mui/material';

const CloudOpsProjectInfoDialog: FC<{
  onClose?: () => void;
}> = ({ onClose }) => (
  <Modal
    open
    maxWidth="sm"
    padding24
    title="Project statuses guide"
    transitionDuration={0}
    subtitle={
      <Stack gap={2} pb="24px">
        <span>
          <b style={{ fontWeight: 400 }}>Active status:</b> Zazmic-Connect will
          generate CloudOps monthly reports and then trigger invoice creation
          according to them once a month. Invoices will be created based on the
          approved report starting on the 5th day of the following month. For
          example, for a July report, the invoice will be created from August
          5th based on the approved logged time.
        </span>
        <span>
          <b style={{ fontWeight: 400 }}>Deactivated status:</b> Zazmic-Connect
          will stop generating CloudOps monthly reports and will delete report
          for current month. Previous months reports will stay.
        </span>
      </Stack>
    }
    onClose={onClose}
  >
    <></>
  </Modal>
);

export default CloudOpsProjectInfoDialog;

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { Form } from 'pages/AuthPage/AuthPage.styles';
import { validEmail } from 'utils/helpers/formValidators';
import { palette } from 'utils/styles/variables';
import Link from 'atoms/Link/Link';
import { AlertContainer } from 'utils/styles/AlertWrapper';
import axios from 'axios';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

const ForgotForm = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState({
    value: '',
    error: '',
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState({
    value: '',
    error: false,
  });

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    let { error } = email;
    if (validEmail(newEmail)) {
      error = '';
    }
    setEmail((prevState) => ({
      ...prevState,
      value: newEmail,
      error,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validEmail(email.value)) {
      return setEmail((prevState) => ({
        ...prevState,
        error: 'Please enter valid email address!',
      }));
    }
    if (success) {
      navigate('/signin');
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_USER_SERVICE}/forgot-password`,
          {
            email: email.value,
          }
        );
        setSuccess(true);
        setApiResponse({ value: response.data.message, error: false });
      } catch (err) {
        setSuccess(false);
        setApiResponse({
          value: err.response.data.message,
          error: true,
        });
      }
    }

    // todo remove this return statement and add API call
    return false;
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {state?.errorType && (
        <AlertContainer>
          <Alert severity="error">{state.errorType.replaceAll('_', ' ')}</Alert>
        </AlertContainer>
      )}

      {apiResponse.value && (
        <AlertContainer>
          <Alert severity={apiResponse.error ? 'error' : 'success'}>
            {apiResponse.value}
          </Alert>
        </AlertContainer>
      )}
      <TextField
        error={!!email.error}
        autoComplete="email"
        fullWidth
        variant="outlined"
        label="Email"
        placeholder="Type your email"
        margin="normal"
        type="email"
        onChange={handleEmailChange}
        helperText={email.error}
        disabled={success}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ margin: '40px 0 32px' }}
      >
        Continue
      </Button>
      {!success && (
        <Typography
          textAlign={width < 600 ? 'right' : 'center'}
          variant="body1"
          fontSize="0.875rem"
          color="#999999"
          className="form-footer"
        >
          I remembered my password!{' '}
          <Link to="/signin" $color={palette.primary.main}>
            Sign in
          </Link>
        </Typography>
      )}
    </Form>
  );
};

export default ForgotForm;

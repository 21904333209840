import styled from 'styled-components';

type Circle = {
  $size?: number;
  $bgColor?: string;
  $color?: string;
  $margin?: string;
};

export default styled.div<Circle>`
  min-width: ${(props) => (props.$size ? `${props.$size}px` : '40px')};
  height: ${(props) => (props.$size ? `${props.$size}px` : '40px')};
  margin: ${(props) => (props.$margin ? props.$margin : 0)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgColor || '#FEDEDE'};
  color: ${(props) => props.$color || '#FC3636'};
  font-weight: 500;
  font-size: 0.875rem;
`;

import React, { FC, useCallback, useContext } from 'react';

import {
  StyledFilterRow,
  StyledFilterTitle,
} from 'organisms/Filter/Filter.styles';
import { Stack, Typography } from '@mui/material';

import { FilterContext } from 'organisms/Filter';

import { ReactComponent as FilterFilledIcon } from 'assets/icons/filter-filled.svg';

const FilterOptions: FC = () => {
  const context = useContext(FilterContext);
  const { filter, options, setOptions } = context;

  const handleOptionChange = useCallback(
    (code: (typeof options)[number]['code']) => {
      setOptions(
        options.map((item) => ({ ...item, active: item.code === code }))
      );
    },
    [options]
  );

  if (!context) {
    throw new Error('FilterOptions must be used within a FilterProvider');
  }

  const checkIsFilled = (value: unknown) => {
    if (!value) return false;
    return !(Array.isArray(value) && !value?.length);
  };

  return (
    <Stack>
      <StyledFilterTitle>
        <Typography component="span">Filter Options</Typography>
      </StyledFilterTitle>
      <Stack p="8px 0" gap="4px">
        {options?.map((item) => (
          <StyledFilterRow
            key={item.code}
            $active={item.active}
            onClick={() =>
              handleOptionChange(item.code as (typeof options)[number]['code'])
            }
          >
            <Typography component="span">{item.title}</Typography>
            {checkIsFilled(filter?.[item.code]) && <FilterFilledIcon />}
          </StyledFilterRow>
        ))}
      </Stack>
    </Stack>
  );
};

export default FilterOptions;

import styled from 'styled-components';
import { Button, Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const StyledText = styled(Box)`
  && {
    color: ${neutrals[600]};
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: capitalize;
  }
`;

export const StyledButton = styled(Button)`
  && {
    display: flex;
    min-width: 104px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border: 1px solid #6f9fe9;
    background-color: #6f9fe9;

    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    border-radius: 3px;

    &:hover {
      background-color: #8aafe9;
      border-color: #8aafe9;
    }

    &:disabled {
      background: #e8e9eb;
      border-color: #e8e9eb;
    }
  }
`;

export const TextButton = styled(Button)`
  && {
    padding: 0;

    color: ${palette.primary.main};
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 15.5px;
    text-decoration-line: underline;
    &:hover {
      background-color: transparent;
    }
  }
`;

import styled from 'styled-components';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import React from 'react';
import { palette } from 'utils/styles/variables';

export const StyledRow = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
  }
`;

export const StyledRowText = styled(Typography)<{
  $color?: string;
  $bold?: boolean;
  $fontSize?: string;
  $lineHeight?: string;
}>`
  && {
    display: flex;
    align-items: end;
    gap: 5px;

    color: ${({ $color }) => $color || neutrals[800]};

    font-family: Poppins, sans-serif;
    font-size: ${({ $fontSize }) => $fontSize || '13px'};
    font-style: normal;
    font-weight: 400;
    line-height: ${({ $lineHeight }) => $lineHeight || '18px'};

    ${({ $bold }) =>
      $bold && {
        fontWeight: 500,
      }}
  }
`;

export const StyledTextButton = styled(({ ...otherProps }) => (
  <Button {...otherProps} />
))`
  && {
    width: fit-content;
    padding: 0;
    justify-content: flex-end;

    color: ${palette.primary.main};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration-line: underline;

    &:hover {
      background-color: transparent;
      text-decoration-line: underline;
    }
  }
`;

export const DialogSectionTitle = styled(Typography)<{ $custom?: boolean }>`
  && {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${neutrals[800]};
    line-height: 20px;
    white-space: pre-line;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-family: Poppins, sans-serif;
    color: ${neutrals[700]};
    font-size: 1rem;
    height: 28px;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    height: 40px;
    .MuiSelect-select {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 0.875rem;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${palette.primary.main} !important;
    }
  }
`;

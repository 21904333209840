import styled from 'styled-components';
import { Stack } from '@mui/material';

export const AccountDetailContent = styled(Stack)`
  && {
    box-shadow: 0 1px 1px 0 #999;
    padding: 10px 16px;
    min-height: 50px;

    && > span:first-of-type {
      color: var(--greys-gr-mid-45, #676c73);
      font-size: 0.75rem;
      font-weight: 500;

      & > span {
        display: block;
        color: var(--greys-gr-mid-45, #676c73);
        font-size: 0.65rem;
        font-weight: 400;
      }
    }

    && > span:last-of-type {
      color: #484e56;
      text-align: right;
      font-size: 0.875rem;
      font-weight: 500;
      padding-top: 10px;
      line-break: anywhere;
    }
  }
`;

export const DesktopAccountDetailRow = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    margin-bottom: 8px;
    border-bottom: 1px solid #e8e9eb;

    && > span:first-of-type {
      width: 150px;
      color: var(--Greys-Gr_Mid45, #676c73);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */

      & > span {
        display: block;
        color: var(--greys-gr-mid-45, #676c73);
        font-size: 0.57rem;
        font-weight: 400;
      }
    }
    && > span:last-of-type {
      color: #484e56;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      line-break: anywhere;
    }
  }
`;

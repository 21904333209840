import styled from 'styled-components';
import { Button } from '@mui/material';

export const ExportCSVButton = styled(Button)`
  && {
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 15.5px;
    text-align: left;
    text-decoration-line: underline;
    padding: 0;
  }
`;

import React, { FC } from 'react';

import { Stack, Box } from '@mui/material';

import { Report, ReportData } from 'pages/CloudOpsReport/types';
import { MobileCloudOpsReport } from 'molecules/MobileCloudOpsReports/MobileCloudOpsReport';
import { months } from 'utils/constants/common';

export const MobileCloudOpsReports: FC<{
  data?: ReportData;
  onDetails: (r: Report) => void;
}> = ({ data, onDetails }) => (
  <Stack direction="column">
    {data && data.projects_reports.length ? (
      data.projects_reports.map((report) => {
        const { id, company_name, month, year } = report;
        return (
          <MobileCloudOpsReport
            id={id}
            name={company_name}
            date={`${months[month - 1]}, ${year}`}
            onDetails={() => onDetails(report)}
          />
        );
      })
    ) : (
      <Box sx={{ padding: '10px 16px' }}>No CloudOps Reports!</Box>
    )}
  </Stack>
);

import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import {
  autoSetCurrentCompany,
  setCurrentCompany,
} from 'store/common/commonSlice';
import { UserCompany } from 'store/user/userSlice';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';

const Redirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company_hs_id = searchParams.get('company_hs_id');

  const dispatch = useBaoDispatch();
  const { value: user } = useBaoSelector((state) => state.user);

  const isAnyAdmin = user?.userType === SUPER_ADMIN || user?.userType === ADMIN;

  useEffect(() => {
    try {
      if (!company_hs_id || isAnyAdmin) navigateToDashboard();

      const found: UserCompany | undefined = user?.companies?.find(
        (c) => Number(c.company_hs_id) === Number(company_hs_id)
      );

      if (!found || !found.company_hs_id) navigateToDashboard();

      dispatch(setCurrentCompany(found as UserCompany));
      navigate('/dashboard');
    } catch {
      navigateToDashboard();
    }
  }, [company_hs_id, user, isAnyAdmin]);

  function navigateToDashboard() {
    dispatch(autoSetCurrentCompany(user));
    navigate('/dashboard');
    // eslint-disable-next-line
    return;
  }

  return <></>;
};

export default Redirect;

import React from 'react';
import { StyledMonthDetails } from 'organisms/InvoiceChart/InvoiceChart.styles';
import { Box, Typography } from '@mui/material';
import { CompanyChartState } from 'organisms/InvoiceChart/GlobalInvoiceChart';

export const MonthCompanyDetails = ({
  details,
  isNew,
}: {
  details: CompanyChartState | null;
  isNew: boolean;
}) => {
  if (!details) return null;

  const { total_companies, companies_with_invoices } = details;

  return (
    <StyledMonthDetails $largeLabel>
      <Box>
        <Typography variant="body2">
          {isNew ? 'New companies' : 'Companies'}:
        </Typography>
        <Typography variant="body2">{total_companies ?? 0}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          {isNew ? 'New companies' : 'Companies'} with invoices:
        </Typography>
        <Typography variant="body2">{companies_with_invoices ?? 0}</Typography>
      </Box>
    </StyledMonthDetails>
  );
};

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Divider, TextField } from '@mui/material';
import { ModalSubmitButton } from 'molecules/Modal/Modal.styles';

type paymentInfoType = {
  name: string;
  roatingNumber: string;
  accountNumber: string;
};

const Step2 = () => {
  const stripe = useStripe();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentInfo, setPaymentInfo] = useState<paymentInfoType>({
    name: '',
    roatingNumber: '',
    accountNumber: '',
  });

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: keyof paymentInfoType
  ) => {
    setPaymentInfo((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        autoComplete="cc-name"
        fullWidth
        variant="outlined"
        label="Account holders name"
        margin="normal"
        onChange={(e) => handleChange(e, 'name')}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Roating Number"
        margin="normal"
        onChange={(e) => handleChange(e, 'roatingNumber')}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Account Number"
        margin="normal"
        onChange={(e) => handleChange(e, 'accountNumber')}
      />
      <Divider sx={{ my: 3 }} />
      <ModalSubmitButton
        fullWidth
        variant="contained"
        type="submit"
        disabled={!stripe}
      >
        Start
      </ModalSubmitButton>
    </form>
  );
};

export default Step2;

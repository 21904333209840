import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { setAutoPayment, setStep } from 'store/payInvoice/payInvoiceSlice';
import { useBaoDispatch } from 'utils/hooks/redux';
import { ModalSubmitButton } from 'molecules/Modal/Modal.styles';

const Step1 = () => {
  const dispatch = useBaoDispatch();
  const [confirmed, setConfirmed] = useState(false);

  const handleClick = () => {
    dispatch(setStep(2));
  };

  return (
    <>
      <Typography variant="body2" color="#565148" mb={2}>
        You have chosen to pay through a bank, please read the process: <br />
        <br /> 1. The data that you enter to pay for your payment will not be
        stored on our server without your knowledge. <br />
        <br /> 2. The payment amount, which is <strong>$0.32</strong>, is
        intended for the synchronization of the service, and after the
        connection with the bank is established, it will be transferred to your
        account within <strong>7 working days</strong>.
        <br />
        <br />
        3. When paying through a bank, your payment will be closed within{' '}
        <strong>7 business days</strong>.
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox onChange={(e) => setConfirmed(e.target.checked)} />
          }
          label="I confirm you take all my money from a bank account"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => dispatch(setAutoPayment(e.target.checked))}
            />
          }
          label="Save this method for Auto Payment"
        />
      </FormGroup>
      <Divider sx={{ my: 3 }} />
      <ModalSubmitButton
        fullWidth
        variant="contained"
        disabled={!confirmed}
        onClick={handleClick}
      >
        Start
      </ModalSubmitButton>
    </>
  );
};

export default Step1;

export const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatter = (amount: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.trim() || 'USD',
  });

  if (!currency) return '';

  return formatter.format(amount);
};
export const currency = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatLargeNumber = (num: number, currencyName: string) => {
  // Suffixes for thousands, millions, billions, trillions, etc.
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const prefixes = { USD: '$', EUR: '€', GBP: '£', CAD: '$' };
  const prefix = prefixes[currencyName as keyof typeof prefixes];

  if (!num) return '0';

  // Return the formatted number as string if less than 1 million
  if (num < 1000) return `${prefix}${num}`;

  // Determine the appropriate suffix
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < suffixes.length; i++) {
    if (num < 1000 ** (i + 1)) {
      const formattedNum = num / 1000 ** i;
      if (Number.isInteger(formattedNum)) {
        return `${prefix}${formattedNum}${suffixes[i]}`;
      }

      return `${prefix}${formattedNum.toFixed(1)}${suffixes[i]}`;
    }
  }

  return currencyFormatter(num, currencyName); // Fallback, though it should never reach this point with the given suffix list
};

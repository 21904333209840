import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { validEmail } from 'utils/helpers/formValidators';
import { Form } from 'pages/AuthPage/AuthPage.styles';
import { palette } from 'utils/styles/variables';
import Link from 'atoms/Link/Link';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { AlertContainer } from 'utils/styles/AlertWrapper';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

type FormProps = {
  companyName: { value: string; error: string };
  email: { value: string; error: string };
  firstName: { value: string; error: string };
  lastName: { value: string; error: string };
};

const RequestForm = () => {
  const { width } = useWindowDimensions();
  const { state } = useLocation();
  const [form, setForm] = useState<FormProps>({
    companyName: { value: '', error: '' },
    email: { value: '', error: '' },
    firstName: { value: '', error: '' },
    lastName: { value: '', error: '' },
  });
  const [apiResponse, setApiResponse] = useState({
    value: '',
    submitted: false,
    error: false,
  });
  const [checkingEmail, setCheckingEmail] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const updatedForm = cloneDeep(form);
    let error = false;
    if (!validEmail(form.email.value)) {
      updatedForm.email.error = 'Please enter valid email address!';
      error = true;
    }
    if (!form.companyName.value || !form.companyName.value.trim()) {
      updatedForm.companyName.error = 'Please enter Company Name';
      error = true;
    }
    if (!form.firstName.value || !form.firstName.value.trim()) {
      updatedForm.firstName.error = 'Please enter your first name';
      error = true;
    }
    if (!form.lastName.value || !form.lastName.value.trim()) {
      updatedForm.lastName.error = 'Please enter your last name';
      error = true;
    }
    setForm(updatedForm);
    if (error) {
      return false;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/request-account`,
        {
          company_name: form.companyName.value,
          email: form.email.value,
          first_name: form.firstName.value,
          last_name: form.lastName.value,
        }
      );

      return setApiResponse({
        value: response.data.message,
        error: false,
        submitted: true,
      });
    } catch (err) {
      return setApiResponse({
        value: err.response.data.message,
        submitted: false,
        error: true,
      });
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof FormProps
  ) => {
    const updatedForm = cloneDeep(form);
    const newValue = e.target.value;
    if (field === 'email') {
      if (validEmail(newValue)) {
        updatedForm.email.error = '';
      }
    } else if (field === 'companyName') {
      if (newValue) {
        updatedForm.companyName.error = '';
      }
    } else if (field === 'firstName') {
      if (newValue) {
        updatedForm.firstName.error = '';
      }
    } else if (field === 'lastName') {
      if (newValue) {
        updatedForm.lastName.error = '';
      }
    }
    updatedForm[field].value = newValue;
    setForm(updatedForm);
  };

  const submitCheckEmail = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const email = e.target?.value;
    const updatedForm = cloneDeep(form);

    if (email) {
      if (!validEmail(form.email.value)) {
        updatedForm.email.error = 'Please enter valid email address!';
        setForm(updatedForm);
      }

      setCheckingEmail(true);

      try {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get(
          `${process.env.REACT_APP_USER_SERVICE}/get?email=${encodedEmail}`
        );

        if (response.status === 200) {
          updatedForm.email.error =
            'Account with provided email already exists in Zazmic-Connect!';
          setForm(updatedForm);
        }

        setCheckingEmail(false);
        return null;
      } catch (err) {
        setCheckingEmail(false);

        if (err.response.status === 404) {
          return setApiResponse({
            value: '',
            submitted: false,
            error: false,
          });
        }

        return setApiResponse({
          value: 'Error checking e-mail',
          submitted: false,
          error: true,
        });
      }
    }

    return null;
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {state?.errorType && (
        <AlertContainer>
          <Alert severity="error">{state.errorType.replaceAll('_', ' ')}</Alert>
        </AlertContainer>
      )}
      {apiResponse.value && (
        <AlertContainer>
          <Alert severity={apiResponse.error ? 'error' : 'success'}>
            {apiResponse.value}
          </Alert>
        </AlertContainer>
      )}
      <TextField
        disabled={apiResponse.submitted}
        error={!!form.companyName.error}
        autoComplete="organization"
        fullWidth
        variant="outlined"
        label="Company name *"
        margin="normal"
        onChange={(e) => handleChange(e, 'companyName')}
        helperText={form.companyName.error}
        value={form.companyName.value}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextField
          disabled={apiResponse.submitted || checkingEmail}
          error={!!form.email.error}
          autoComplete="email"
          fullWidth
          variant="outlined"
          label="Email *"
          placeholder="Type your email"
          margin="normal"
          type="email"
          onChange={(e) => handleChange(e, 'email')}
          helperText={form.email.error}
          value={form.email.value}
          onBlur={submitCheckEmail}
        />
        {checkingEmail && (
          <CircularProgress
            size={24}
            sx={{
              marginLeft: '10px',
            }}
          />
        )}
      </div>
      <TextField
        disabled={apiResponse.submitted}
        error={!!form.firstName.error}
        autoComplete="given-name"
        fullWidth
        variant="outlined"
        label="First name *"
        margin="normal"
        onChange={(e) => handleChange(e, 'firstName')}
        helperText={form.firstName.error}
        value={form.firstName.value}
      />
      <TextField
        disabled={apiResponse.submitted}
        error={!!form.lastName.error}
        autoComplete="family-name"
        fullWidth
        variant="outlined"
        label="Last name *"
        margin="normal"
        onChange={(e) => handleChange(e, 'lastName')}
        helperText={form.lastName.error}
        value={form.lastName.value}
      />
      {!apiResponse.submitted && (
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ margin: '40px 0 32px' }}
        >
          Request an account
        </Button>
      )}

      <Typography
        textAlign={width < 600 ? 'right' : 'center'}
        variant="body1"
        fontSize="0.875rem"
        color="#999999"
        className="form-footer"
      >
        Already have an account?{' '}
        <Link to="/signin" $color={palette.primary.main}>
          Sign in
        </Link>
      </Typography>
    </Form>
  );
};

export default RequestForm;

import React, { MouseEvent, FC } from 'react';
import { Stack, Box, TableHead, TableSortLabel } from '@mui/material';
import { ITableHeadProps } from '../types';
import { StyledTableHeadCell, StyledTableRow } from 'molecules/Table/styles';
import { visuallyHidden } from '@mui/utils';
import {
  StyledGreyText,
  StyledHeadText,
} from 'molecules/Table/styles/Text.styles';

const CustomTableHead: FC<ITableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
  columns,
  borderless,
}) => {
  const createSortHandler =
    (property: string | number) => (event: MouseEvent<unknown>) => {
      if (onRequestSort) onRequestSort(event, property);
    };

  return (
    <TableHead>
      <StyledTableRow $headerRow>
        {columns.map(({ key, width, label, sortable, greyText }) => (
          <StyledTableHeadCell
            key={key}
            sortDirection={orderBy === key ? order : false}
            sx={{ width }}
            $borderless={borderless}
          >
            <Stack className="v-stack">
              <Box>
                {sortable ? (
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : 'asc'}
                    onClick={createSortHandler(key)}
                  >
                    <StyledHeadText>{label}</StyledHeadText>
                    {orderBy === key ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <StyledHeadText>{label}</StyledHeadText>
                )}
              </Box>
              {greyText && (
                <StyledGreyText component="span">{greyText}</StyledGreyText>
              )}
            </Stack>
          </StyledTableHeadCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export default CustomTableHead;

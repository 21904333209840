import React, { FC } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import { getServiceName } from 'organisms/InvoicesTable/helpers';
import {
  ServicesWrapper,
  StyledServiceTableText,
  StyledServiceTableTitle,
} from './Services.styles';
import { StyledDetailsContainer } from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';
import Link from 'atoms/Link/Link';
import { StyledDetailsText } from 'molecules/Table/styles/Text.styles';

import { roundUp } from 'utils/helpers/formValidators';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { useBaoSelector } from 'utils/hooks/redux';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { Row } from '../types';

interface IProps {
  row: Row;
}

const Services: FC<IProps> = ({ row }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;
  const isAnyAdmin = user.userType === SUPER_ADMIN || user.userType === ADMIN;

  return (
    <ServicesWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <StyledServiceTableTitle>Services</StyledServiceTableTitle>
            </TableCell>
            <TableCell sx={{ maxWidth: '620px' }}>
              <StyledServiceTableTitle>Description</StyledServiceTableTitle>
            </TableCell>
            <TableCell>
              <StyledServiceTableTitle $right>Amount</StyledServiceTableTitle>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.services.map((item) => (
            <TableRow key={`service-${item.amount}`}>
              <TableCell>
                <StyledServiceTableText>{item.name}</StyledServiceTableText>
              </TableCell>
              <TableCell>
                <StyledServiceTableText>
                  {item.description?.substring(0, 4000)}
                  {item.description?.length > 4000 ? '...' : ''}
                </StyledServiceTableText>
              </TableCell>
              <TableCell>
                <StyledServiceTableText $right>
                  {item.amount
                    ? currencyFormatter(
                        Number(roundUp(item.amount)),
                        row.currency
                      )
                    : '$0.00'}
                </StyledServiceTableText>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ p: '0 !important' }} colSpan={100}>
              <Grid container spacing={0} sx={{ p: '8px 24px' }}>
                <Grid item xs>
                  <StyledDetailsContainer $firstCol>
                    {row.stripeLink && isAnyAdmin && (
                      <Link
                        to={row.stripeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View payment in Stripe
                      </Link>
                    )}
                    {row.timeLogsLink &&
                      (!row.companyDisableAccessToZstream || isSuperAdmin) && (
                        <Link
                          to={row.timeLogsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`View Tasks ${getServiceName(row.timeLogsLink)}`}
                        </Link>
                      )}
                    {row.invoiceType === 'CO' && (
                      <Link
                        to="https://observability.zazmic.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Observability
                      </Link>
                    )}
                  </StyledDetailsContainer>
                </Grid>
                <Grid item xs="auto">
                  <StyledDetailsContainer>
                    <StyledDetailsText>Subtotal</StyledDetailsText>
                    <StyledDetailsText>Zazmic Discount</StyledDetailsText>
                    <StyledDetailsText>Tax</StyledDetailsText>
                    {row.status.state === 'paid' &&
                      Number(row.transactionFee) > 0 && (
                        <StyledDetailsText>
                          Payment Processing Fee
                        </StyledDetailsText>
                      )}
                    <StyledDetailsText $dividerTop $bold>
                      Total
                    </StyledDetailsText>
                    {Number(row.paymentsCredits) > 0 &&
                      Number(row.paymentsCredits) <
                        Number(row.invoiceTotal) && (
                        <StyledDetailsText>Payments/Credits</StyledDetailsText>
                      )}
                    <StyledDetailsText $bold>Amount Due</StyledDetailsText>
                  </StyledDetailsContainer>
                </Grid>
                <Grid item xs="auto" minWidth={120}>
                  <StyledDetailsContainer>
                    <StyledDetailsText>
                      {currencyFormatter(Number(row.subtotal), row.currency)}
                    </StyledDetailsText>
                    <StyledDetailsText>
                      {currencyFormatter(
                        Number(row.zazmicDiscount),
                        row.currency
                      )}
                    </StyledDetailsText>
                    <StyledDetailsText>
                      {currencyFormatter(
                        Number(row.totalTaxAmount),
                        row.currency
                      )}
                    </StyledDetailsText>
                    {row.status.state === 'paid' &&
                      Number(row.transactionFee) > 0 && (
                        <StyledDetailsText>
                          {currencyFormatter(
                            Number(row.transactionFee),
                            row.currency
                          )}
                        </StyledDetailsText>
                      )}
                    <StyledDetailsText $dividerTop $bold>
                      {currencyFormatter(
                        Number(row.invoiceTotal),
                        row.currency
                      )}
                    </StyledDetailsText>
                    {Number(row.paymentsCredits) > 0 &&
                      Number(row.paymentsCredits) <
                        Number(row.invoiceTotal) && (
                        <StyledDetailsText>
                          {currencyFormatter(
                            Number(row.paymentsCredits),
                            row.currency
                          )}
                        </StyledDetailsText>
                      )}
                    <StyledDetailsText $bold>
                      {currencyFormatter(Number(row.amountDue), row.currency)}
                    </StyledDetailsText>
                  </StyledDetailsContainer>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ServicesWrapper>
  );
};

export default Services;

import React, { FC, useState } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IconTextFieldWrapper } from '../IconTextField/IconTextField.styles';
import { StyledTextField } from 'molecules/Calendar/Calendar.styles';

import dayjs from 'dayjs';
import { palette } from 'utils/styles/variables';

import { CalendarValue } from 'molecules/Calendar/index';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const ButtonDateRangePicker: FC<{
  tooltip?: string;
  onChange: (v: CalendarValue) => void;
}> = ({ tooltip, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<[null | Date, null | Date]>([null, null]);
  const [isBothSelected, setIsBothSelected] = useState<boolean>(false);

  const isFilled = !!(range[0] && range[1]);

  const reset = () => {
    setIsBothSelected(false);
    setRange([null, null]);
    onChange([null, null]);
  };

  const handleClickIcon = () => {
    setOpen((isOpen) => !isOpen);
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !isFilled));

    reset();
  };

  const handleClose = () => {
    setOpen((isOpen) => (isOpen ? false : !isFilled));

    if (range[0] && range[1]) {
      onChange(range);
    }
  };

  return (
    <IconTextFieldWrapper>
      <Tooltip title={tooltip || 'Filter by date'}>
        <IconButton
          aria-label="close"
          onClick={handleClickIcon}
          sx={{
            p: '0 !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disabled={open}
        >
          <CalendarIcon color={palette.primary.main} />
        </IconButton>
      </Tooltip>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          disableFuture
          disableMaskedInput
          disableCloseOnSelect
          disableAutoMonthSwitching
          desktopModeMediaQuery="(min-width: 600px)"
          // allowSameDateSelection
          open={open}
          calendars={1}
          value={range}
          onChange={(ranges) => {
            // Dangerous logic, better update mui or use another lib
            const [from, to] = ranges;
            let toDate = to;
            let fromDate = from;

            // If range from and to are both selected, then reset
            if (isBothSelected) {
              reset();
              return;
            }

            // if to not selected and from is before previous from:
            if (from && !to && dayjs(from).isBefore(dayjs(range[0]))) {
              setRange([from, range[0]]);
              setIsBothSelected(true);
              return;
            }

            if (isFilled && from && !to) {
              setRange([range[0], from]);
              setIsBothSelected(true);
              return;
            }

            setIsBothSelected(Boolean(from && to));

            if (!from) fromDate = to;
            if (!to) toDate = from;

            setRange([fromDate, toDate]);
          }}
          PopperProps={{
            className: 'calendar-paper',
            placement: 'bottom-start',
          }}
          onClose={handleClose}
          renderInput={(startProps, endProps) => {
            const { value: startValue, ...restStartInputProps } =
              startProps.inputProps || {};
            const endValue = endProps?.inputProps?.value;

            const formattedStart = startValue
              ? dayjs(dayjs(startValue)).format('MMM DD, YYYY')
              : '';
            const formattedEnd = endValue
              ? dayjs(dayjs(endValue)).format('MMM DD, YYYY')
              : '';

            return (
              <StyledTextField
                {...startProps}
                label=""
                size="small"
                variant="standard"
                onClick={() => setOpen((prev) => !prev)}
                sx={{
                  width: 296,
                  display: open || isFilled ? 'flex' : 'none',
                  input: {
                    p: '0 !important',
                    textAlign: 'center',
                  },
                }}
                inputProps={{
                  ...restStartInputProps,
                  placeholder: 'Select period',
                  readOnly: true,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: isFilled ? (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          handleClick();
                        }}
                      >
                        <CloseIcon width={16} height={16} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                value={
                  formattedStart && formattedEnd
                    ? `${formattedStart} - ${formattedEnd}`
                    : ''
                }
              />
            );
          }}
        />
      </LocalizationProvider>
    </IconTextFieldWrapper>
  );
};

export default ButtonDateRangePicker;

import axios from 'axios';
import { getCompany } from 'utils/helpers/storage';

export const getBrowserConfig = () => {
  const { expiresAt } = JSON.parse(localStorage.getItem('user') || '{}');

  return {
    timestamp: +new Date() * 1000 * 1000,
    initiator: window.location.href,
    expiresAt,
    currentCompany: getCompany()?.company_hs_id || 'not_set',
  };
};

export const log = (v: unknown) => {
  // eslint-disable-next-line no-console
  console.log(v);
};

export const httpLogger = (logs: unknown[]) => {
  if (!logs || logs.length === 0) return;

  logs.forEach((item) => {
    axios.get(`${process.env.REACT_APP_USER_SERVICE}/logs/frontend`, {
      params: { message: JSON.stringify(item) },
    });
  });
};

// == Uncomment this if you want to send logs to Loki ==
// const payload = {
//   streams: [
//     {
//       stream: {
//         frontend: 'network',
//       },
//       values: logList,
//     },
//   ],
// };

// axios.post('https://boa-logs.zazmic.io/api/v1/push', payload, {
//   auth: {
//     username: 'boa-user',
//     password: 'wl9lo3G4fSYT',
//   },
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

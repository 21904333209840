/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Paper, Tab, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useBaoSelector } from 'utils/hooks/redux';
import { camelCase, mapKeys } from 'lodash-es';
import dayjs from 'dayjs';
import {
  MainBlock,
  NotificationRow,
  Wrapper,
} from './CompanyNotificationsActivityCard.styles';
import { StyledTabList } from 'molecules/Card/Card.styles';

export type notificationsListType = [
  { id: string; subject: string; createdAt: string }
];

type IUser = {
  name: string;
  surname: string;
  email: string;
};

type activitiesType = [
  {
    created_at: string;
    user: IUser;
  }
];

const CompanyNotificationsActivityCard = ({
  companyHsId,
}: {
  companyHsId: string;
}) => {
  const userId = useBaoSelector((state) => state.user.value.userId);
  const [selectedTab, setSelectedTab] = useState('0');
  const [notifications, setNotifications] = useState<{
    count: number;
    list: notificationsListType;
  }>({
    count: 0,
    list: [{ id: '', subject: '', createdAt: '' }],
  });
  const [activities, setActivities] = useState<activitiesType>([
    {
      created_at: '',
      user: {
        email: '',
        name: '',
        surname: '',
      },
    },
  ]);

  useEffect(() => {
    if (selectedTab === '1') {
      axios
        .get(`${process.env.REACT_APP_USER_SERVICE}/login-activities`, {
          params: {
            company_hs_id: companyHsId,
            limit: 20,
            page: 1,
          },
        })
        .then(({ data }: { data: { login_activities: activitiesType } }) => {
          setActivities(data.login_activities);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_NOTIFICATION_SERVICE}/emails`, {
          params: {
            company_hs_id: companyHsId,
            limit: 20,
            page: 1,
          },
        })
        .then(({ data }) => {
          setNotifications({
            count: data.count,
            list: data.emails.map((notification: notificationsListType[0]) =>
              mapKeys(notification, (v, k) => camelCase(k))
            ),
          });
        });
    }
  }, [selectedTab, userId, companyHsId]);

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Wrapper>
      <Paper sx={{ pt: '16px !important' }}>
        <TabContext value={selectedTab}>
          <StyledTabList
            onChange={handleChangeTabs}
            aria-label="Notifications and login activity tabs"
            variant="fullWidth"
          >
            <Tab
              label="Notifications"
              {...a11yProps(0)}
              className={selectedTab === '0' ? '.active' : ''}
              value="0"
            />
            <Tab
              label="Login activity"
              {...a11yProps(1)}
              className={selectedTab === '1' ? '.active' : ''}
              value="1"
            />
          </StyledTabList>
          <TabPanel value="0">
            <MainBlock>
              {notifications.list.map((notification) => (
                <NotificationRow key={notification.id} sx={{ width: '96%' }}>
                  <Typography variant="body2">
                    {dayjs(notification.createdAt).format('MMM DD, YYYY')}
                  </Typography>

                  <Typography variant="body2">
                    {notification.subject}
                  </Typography>
                </NotificationRow>
              ))}
            </MainBlock>
          </TabPanel>
          <TabPanel value="1">
            <MainBlock>
              {activities &&
                activities.length > 0 &&
                activities.map((activity, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <NotificationRow key={index}>
                    <Typography variant="body2">
                      {dayjs(activity.created_at).format('MMM DD, YYYY')}
                    </Typography>
                    <Box>
                      <Typography variant="caption">
                        {activity.user.name} {activity.user.surname}
                      </Typography>
                      <Typography variant="caption">
                        {activity.user.email}
                      </Typography>
                    </Box>
                  </NotificationRow>
                ))}
            </MainBlock>
          </TabPanel>
        </TabContext>
      </Paper>
    </Wrapper>
  );
};

export default CompanyNotificationsActivityCard;

import React, { FC } from 'react';

import { Box } from '@mui/material';

import { ReactComponent as NoContactIcon } from 'assets/icons/no-contact.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { neutrals } from 'utils/styles/color';

const ContactSvgIcon: FC<{ count?: number }> = ({ count }) => {
  if (!count)
    return (
      <Box width="20px">
        <NoContactIcon />
      </Box>
    );

  return (
    <Box style={{ position: 'relative', width: '20px' }}>
      <ContactIcon />
      <span
        style={{
          position: 'absolute',
          top: '7px',
          left: '14px',
          fontSize: '8px',
          color: neutrals[400],
          fontWeight: 400,
        }}
      >
        {count}
      </span>
    </Box>
  );
};

export default ContactSvgIcon;

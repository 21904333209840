import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;
  gap: 16px;

  img,
  svg {
    width: 30px;
    height: 30px;
  }
`;

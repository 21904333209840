import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const ModalContentText = styled(Typography)`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

const ModalContentTextButton = styled((props) => (
  <Button variant="text" {...props} />
))`
  && {
    color: ${palette.primary.main};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration-line: underline;

    &:hover {
      background-color: transparent;
    }
  }
`;

export { ModalContentText, ModalContentTextButton };

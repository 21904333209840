import styled from 'styled-components';
import { Grid, Box } from '@mui/material';

export const StyledGridItem = styled(Grid)`
  && {
    & .MuiInputBase-formControl {
      height: 40px;
      font-size: 1.35rem;
    }

    & .MuiFormLabel-filled.MuiInputLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }
    & .MuiInputLabel-outlined {
      transform: translate(14px, 12px) scale(1);
    }
    & .Mui-focused.MuiInputLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }

    & .MuiFormHelperText-root {
      font-size: 0.875rem;
    }

    & .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 0 4px;
    }

    .status-select,
    .invoice-search-field {
      width: calc(100% - 32px);
      margin: 10px 16px;
    }
  }
`;

export const StyledInvoicesBox = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 12px 24px;
  }
`;

import React, {
  SyntheticEvent,
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import {
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  BoxProps,
} from '@mui/material';
import { TableFilterWrapper } from './TableFilter.styles';

import { neutrals, reds } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

// eslint-disable-next-line
// @ts-ignore
interface FilterAutocompleteProps extends BoxProps {
  value?: string;
  options: string[];
  label?: string;
  tooltip: string;
  placeholder: string;
  isFreeSolo: boolean;
  onChange: (event: SyntheticEvent, value: string | null) => void;
  onReset: () => void;
}

export const FilterAutocomplete: FC<FilterAutocompleteProps> = ({
  value,
  options,
  tooltip,
  placeholder,
  isFreeSolo,
  onChange,
  onReset,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setTimeout(() => {
      setOpen(true);
      setShow(true);
      inputRef.current?.focus();
    }, 120);
  }, [value]);

  const handleReset = useCallback(() => {
    setOpen(false);
    setShow(false);
    onReset();
  }, [value]);

  const handleChange = useCallback(
    (event, val) => {
      setShow(false);
      onChange(event, val);
    },
    [onChange]
  );

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <TableFilterWrapper {...props}>
      <Tooltip title={tooltip}>
        <IconButton
          aria-label="filter-icon"
          onClick={handleClick}
          onMouseDown={handleClick}
          sx={{
            p: '0 !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disabled={open}
        >
          <SearchIcon width={24} height={24} color={palette.primary.main} />
        </IconButton>
      </Tooltip>
      <Autocomplete
        open={show}
        freeSolo={isFreeSolo}
        autoFocus
        openOnFocus
        disablePortal
        disableClearable
        size="small"
        sx={{
          width: 280,
          display: open || Boolean(value) ? 'flex' : 'none',
        }}
        noOptionsText={placeholder}
        value={value}
        options={options || []}
        onChange={handleChange}
        onInputChange={onChange}
        onOpen={() => {
          setShow(true);
        }}
        ListboxProps={{
          style: {
            fontSize: '0.813rem',
            color: neutrals[700],

            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
          },
        }}
        onClose={(e: SyntheticEvent, reason) => {
          if (reason === 'toggleInput') return;
          // if textfield still has focus
          if (document.activeElement === inputRef.current) return;

          if (
            !value &&
            typeof (e.target as Partial<{ value: unknown }>)?.value !== 'number'
          ) {
            handleReset();
            return;
          }

          setShow(false);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            sx={{
              input: {
                padding: '0 4px !important',
                fontSize: '0.813rem !important',
              },
              '.MuiButtonBase-root': {
                padding: 0,
              },
              svg: {
                width: '16px',
                height: '16px',
              },
              'svg path': {
                fill: `${reds[700]} !important`,
              },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              inputRef,
              endAdornment: (
                <InputAdornment position="end">
                  {value ? (
                    <IconButton
                      edge="end"
                      sx={{ padding: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        handleReset();
                      }}
                    >
                      <CloseIcon
                        color={palette.error.main}
                        width={16}
                        height={16}
                      />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableFilterWrapper>
  );
};

/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

// const isMobileSafari =
//   /iP(ad|od|hone)/i.test(window.navigator.userAgent) &&
//   !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

// ==== BE call methods ====
export const getFilenameFromHeader = (response: any) => {
  let filename = 'invoice.pdf';
  const disposition = response.headers['Content-Disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename += '.pdf';
    }
  }
  return filename;
};

export const handleDownloadInvoice = async (
  quickbooksIDs: readonly string[],
  order?: string,
  sort?: 'asc' | 'desc'
) => {
  // todo add try/catch block
  // try {
  const response = await axios.get(
    `${process.env.REACT_APP_REPORT_SERVICE}/invoices/report`,
    {
      params: {
        ids: quickbooksIDs.toString(),
        order,
        sort,
      },
      responseType: 'blob',
    }
  );
  const filename = getFilenameFromHeader(response);
  const link = document.createElement('a');
  link.href = response.request?.responseURL;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

/**
 * Define the service name based on the given service link.
 *
 * @param {string} serviceLink - The link of the service
 * @return {string} The service name determined from the link
 */
export const getServiceName = (serviceLink: string) => {
  if (serviceLink.includes('zoho')) return 'in Zoho';
  if (serviceLink.includes('zstream')) return 'in ZStream';

  return '';
};

import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { useBaoSelector } from 'utils/hooks/redux';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';

import InvoicesTable from 'organisms/InvoicesTable/InvoicesTable';
import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import GlobalInvoiceChart from 'organisms/InvoiceChart/GlobalInvoiceChart';
import Block from 'molecules/Card';
import InvoiceTableStat from 'molecules/InvoiceTableStat';

const SuperAdminDashboardPage = () => {
  const [error, setError] = useState('');

  const { totalCount, totalAmount } = useBaoSelector((state) => state.invoices);
  const { value: user } = useBaoSelector((state) => state.user);

  const isAdmin = user.userType === SUPER_ADMIN || user.userType === ADMIN;

  return (
    <HeadlessPageWrapper>
      <Grid container spacing={2}>
        {error}
        <Grid item xs={12}>
          <GlobalInvoiceChart />
        </Grid>
        {/* second row */}
        <Grid item xs={12} spacing={2}>
          <Block
            title="Outstanding invoices"
            avatar={<CardAvatar />}
            action={
              <InvoiceTableStat
                details={{ totalCount, totalAmount }}
                currency="USD"
                totalHidden
              />
            }
            sx={{ '.MuiCardContent-root': { p: '0 !important' } }}
          >
            <InvoicesTable
              companyColumn={isAdmin}
              status="customer"
              onError={setError}
            />
          </Block>
        </Grid>
      </Grid>
    </HeadlessPageWrapper>
  );
};

export default SuperAdminDashboardPage;

import styled from 'styled-components';

export default styled('span')`
  color: #fff;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ children }) => {
    let invoiceStatus = '';
    let invoices = '';

    if (Array.isArray(children)) {
      children.forEach((item) => {
        if (item && typeof item !== 'boolean') {
          const stringArr = item.split(' ');
          // eslint-disable-next-line prefer-destructuring
          invoices = stringArr[0];
        }
      });
    }

    invoiceStatus = invoices;

    switch (invoiceStatus) {
      case 'OPEN':
        return '#56B8EF';
      case 'PAID':
        return '#47C26A';
      case 'OVERDUE':
        return '#FF626D';
      case 'VOIDED':
        return '#8C8C8C';
      case 'PENDING':
        return '#F4BF32';
      case 'PAYMENT_PENDING':
        return '#F4BF32';
      default:
        return '#56B8EF';
    }
  }};

  border-radius: 3px;
  padding: 2px 4px;
  font-size: 0.69rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
`;

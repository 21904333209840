import React, { FC, useCallback, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Modal from 'molecules/Modal';
import { ModalContent } from 'molecules/Modal/Modal.styles';

export const CaptchaModal: FC<{
  onComplete: () => void;
  onClose: () => void;
  className?: string;
}> = ({ onComplete, onClose, className }) => {
  const recaptcha = useRef<ReCAPTCHA>();

  const verifyToken = async (token: string) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/verify-token`,
        { recaptcha_token: token }
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const handleChange = useCallback(async () => {
    const captchaValue = recaptcha.current?.getValue();
    if (captchaValue) {
      const validToken = await verifyToken(captchaValue);
      if (validToken && validToken.success) {
        onComplete();
      }
    }
    return captchaValue;
  }, [recaptcha, onComplete]);

  return (
    <Modal
      open
      maxWidth="xs"
      title="Security Check"
      subtitle="You are about to make important changes please pass the security check."
      onClose={onClose}
      className={className}
      sx={{ zIndex: 33333 }}
    >
      <ModalContent sx={{ paddingBottom: '16px !important', height: '100px' }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ReCAPTCHA
          ref={recaptcha}
          size="normal"
          theme="light"
          onChange={handleChange}
          sitekey={process.env.REACT_APP_SITE_KEY}
        />
      </ModalContent>
    </Modal>
  );
};

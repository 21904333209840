import React, { FC, useContext, useEffect, useState } from 'react';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  DateRange,
  DateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  StyledDateRangeFilterAction,
  StyledDateRangeFilterHeader,
  StyledDateRangePicker,
} from 'organisms/Filter/forms/Form.styles';
import { neutrals } from 'utils/styles/color';
import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import { FilterContext } from 'organisms/Filter/index';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';

const actionButtons = [
  { code: 'current_week', title: 'Current Week' },
  { code: 'current_month', title: 'Current Month' },
  { code: 'current_year', title: 'Current Year' },
  { code: 'previous_month', title: 'Previous Month' },
  { code: 'current_quarter', title: 'Current Quarter' },
  { code: 'previous_year', title: 'Previous Year' },
];

const DatePickerFilter: FC<{ name: string }> = ({ name }) => {
  const context = useContext(FilterContext);
  const { filter, setFilter } = context;

  const [active, setActive] = useState<
    (typeof actionButtons)[number]['code'] | undefined
  >();
  const [isBothSelected, setIsBothSelected] = useState<boolean>(false);
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  dayjs.extend(quarterOfYear);
  dayjs.extend(isBetween);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  useEffect(() => {
    setFilter({ ...filter, [name]: value[0] && value[1] ? value : undefined });
  }, [value[0], value[1], name]);

  useEffect(() => {
    if (filter[name]) {
      setValue([
        dayjs((filter[name] as DateRange<Date>)[0] as Date).toDate(),
        dayjs((filter[name] as DateRange<Date>)[1] as Date).toDate(),
      ]);

      if (
        (filter[name] as DateRange<Date>)[0] &&
        (filter[name] as DateRange<Date>)[1]
      ) {
        setIsBothSelected(true);
      }
    }
  }, []);

  const reset = () => {
    setIsBothSelected(false);
    setValue([null, null]);
  };

  const handleAction = (code: (typeof actionButtons)[number]['code']) => {
    setActive(code);
    setIsBothSelected(true);
    switch (code) {
      case 'current_week':
        setValue([
          dayjs().startOf('week').toDate(),
          dayjs().endOf('week').toDate(),
        ]);
        break;
      case 'current_month':
        setValue([
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ]);
        break;
      case 'current_year':
        setValue([
          dayjs().startOf('year').toDate(),
          dayjs().endOf('year').toDate(),
        ]);
        break;
      case 'previous_month':
        setValue([
          dayjs().subtract(1, 'month').startOf('month').toDate(),
          dayjs().subtract(1, 'month').endOf('month').toDate(),
        ]);
        break;
      case 'current_quarter':
        setValue([
          dayjs().startOf('quarter').toDate(),
          dayjs().endOf('quarter').toDate(),
        ]);
        break;
      case 'previous_year':
        setValue([
          dayjs().subtract(1, 'year').startOf('year').toDate(),
          dayjs().subtract(1, 'year').endOf('year').toDate(),
        ]);
        break;
      default:
        setValue([null, null]);
        break;
    }
  };

  const checkDateBetween = (date: Dayjs, startDate: Dayjs, endDate: Dayjs) =>
    dayjs(date).isBetween(dayjs(startDate), dayjs(endDate), null, '[]');

  const checkSameDate = (date: Dayjs, compareDate: Dayjs) =>
    dayjs(date).isSame(dayjs(compareDate), 'date');

  const getDateStyles = (date: Dayjs) => {
    if (checkSameDate(dayjs(value[0]), dayjs(value[1]))) return {};

    if (checkSameDate(date, dayjs(value[0]))) {
      return {
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
        backgroundColor: 'rgba(139, 178, 237, 0.6) !important',
      };
    }

    if (checkSameDate(date, dayjs(value[1]))) {
      return {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        backgroundColor: 'rgba(139, 178, 237, 0.6) !important',
      };
    }

    if (
      !checkSameDate(date, dayjs(value[0])) ||
      !checkSameDate(date, dayjs(value[1]))
    ) {
      return {
        backgroundColor:
          value[0] &&
          value[1] &&
          checkDateBetween(date, dayjs(value[0]), dayjs(value[1]))
            ? 'rgba(139, 178, 237, 0.6) !important'
            : 'transparent !important',
        borderRadius:
          value[0] &&
          value[1] &&
          checkDateBetween(date, dayjs(value[0]), dayjs(value[1]))
            ? '0 !important'
            : '50% !important',
      };
    }

    return {};
  };

  const renderDay = (
    date: Dayjs,
    pickersDayProps: DateRangePickerDayProps<Dayjs>
  ) => {
    const { outsideCurrentMonth } = pickersDayProps;
    return (
      <Box
        sx={{
          '.MuiDateRangePickerDay-root': {
            // eslint-disable-next-line
            ...(getDateStyles(date) as any),
            margin: '0 !important',
          },
          '.MuiButtonBase-root': {
            color:
              outsideCurrentMonth &&
              !checkSameDate(date, dayjs(value[0])) &&
              !checkSameDate(date, dayjs(value[1]))
                ? neutrals[500]
                : neutrals[800],
          },
        }}
      >
        <DateRangePickerDay {...pickersDayProps} />
      </Box>
    );
  };

  if (!context) {
    throw new Error('DatePickerFilter must be used within a FilterProvider');
  }

  const isFilled = !!(value[0] && value[1]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack>
        <StyledDateRangeFilterHeader>
          <Typography
            component="span"
            sx={{ color: `${neutrals[value[0] ? 700 : 400]} !important` }}
          >
            {value[0] ? dayjs(value[0])?.format('YYYY-MM-DD') : 'Start Date'}
          </Typography>
          <Typography component="span">/</Typography>
          <Typography
            component="span"
            sx={{ color: `${neutrals[value[1] ? 700 : 400]} !important` }}
          >
            {value[1] ? dayjs(value[1])?.format('YYYY-MM-DD') : 'End Date'}
          </Typography>
          <Button
            variant="text"
            disableRipple
            onClick={() => setValue([null, null])}
          >
            Reset
          </Button>
        </StyledDateRangeFilterHeader>

        <Stack direction="row">
          <Stack sx={{ p: '8px 0', minWidth: 130, position: 'relative' }}>
            {actionButtons.map((item) => (
              <StyledDateRangeFilterAction
                key={item.code}
                $active={active === item.code}
                onClick={() => handleAction(item.code)}
              >
                {item.title}
              </StyledDateRangeFilterAction>
            ))}
          </Stack>
          <StyledDateRangePicker
            calendars={1}
            disableMaskedInput
            disableCloseOnSelect
            disableAutoMonthSwitching
            showDaysOutsideCurrentMonth
            displayStaticWrapperAs="desktop"
            value={value}
            dayOfWeekFormatter={(weekday: Dayjs) => `${weekday.format('dd')}.`}
            onChange={(ranges: DateRange<Date>) => {
              // Dangerous logic, better update mui or use another lib
              const [from, to] = ranges;
              let toDate = to;
              let fromDate = from;

              setActive(undefined);

              // If range from and to are both selected, then reset
              if (isBothSelected) {
                reset();
                return;
              }

              // if to not selected and from is before previous from:
              if (from && !to && dayjs(from).isBefore(dayjs(value[0]))) {
                setValue([from, value[0]]);
                setIsBothSelected(true);
                return;
              }

              if (isFilled && from && !to) {
                setValue([value[0], from]);
                setIsBothSelected(true);
                return;
              }

              setIsBothSelected(Boolean(from && to));

              if (!from) fromDate = to;
              if (!to) toDate = from;

              setValue([fromDate, toDate]);
            }}
            renderInput={(startProps: MuiTextFieldProps) => (
              <TextField {...startProps} />
            )}
            renderDay={renderDay}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePickerFilter;

import React from 'react';
import { Grid } from '@mui/material';
import PageWrapper from 'templates/PageWrapper/PageWrapper';
import { useBaoSelector } from 'utils/hooks/redux';

type DashboardTemplateProps = {
  firstColumn: JSX.Element;
  secondColumn: JSX.Element;
  lastRow: JSX.Element;
};

const DashboardTemplate = ({
  firstColumn,
  secondColumn,
  lastRow,
}: DashboardTemplateProps) => {
  const firstname = useBaoSelector((state) => state.user.value.name);

  return (
    <PageWrapper title={`Welcome, ${firstname}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          {firstColumn}
        </Grid>
        <Grid item xs={12} sm={5}>
          {secondColumn}
        </Grid>
        <Grid item xs={12} sm={12}>
          {lastRow}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default DashboardTemplate;

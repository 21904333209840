/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PageWrapperMinWidth } from 'templates/PageWrapper/PageWrapper';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import CompanyNotificationsActivityCard from 'organisms/CompanyNotificationsActivityCard/CompanyNotificationsActivityCard';
import { CompanyDetailsCard } from 'organisms/CompanyDetailsCard';
import CompanyInvoicesTabs from 'organisms/CompanyInvoicesTabs';
import InvoiceChart from 'organisms/InvoiceChart/InvoiceChart';
import { AntSwitch } from 'atoms/AntSwitch';
import { Delete } from '@mui/icons-material';
import { DialogTitleWrapper } from 'organisms/PayInvoicePopup/PayInvoicePopup.styles';
import { ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  ProjectModalStatistics,
  ProjectModalStatisticsRow,
  ProjectModalFooter,
  ConfirmModalContent,
  CancelButtonWrapper,
  ConfirmButtonWrapper,
  CompanySettingsBox,
  StyledBody2,
} from './CompanyPage.styles';
import CloudOpsComp from './CloudOps';
import {
  CloudOpsProjectsStateType,
  companyType,
  ErrorModal,
} from 'pages/CompanyPage/types';
import { initialState } from 'pages/CompanyPage/data';
import Block from 'molecules/Card';

const CompanyPage = () => {
  const [paymentMethod, setPaymentMethod] = useState('null');
  const [company, setCompany] = useState<companyType>(initialState);
  const { value: user } = useBaoSelector((state) => state.user);
  const [disablePortalEmailNotifications, setDisablePortalEmailNotifications] =
    useState(false);
  const [
    disableServicesInvoicesSyncFromQB,
    setDisableServicesInvoicesSyncFromQB,
  ] = useState(false);
  const [
    disablePastDueInvoicesNotifications,
    setDisablePastDueInvoicesNotifications,
  ] = useState(false);
  const [disableOverBudgetNotifications, setDisableOverBudgetNotifications] =
    useState(false);
  const [disablePastDueInvoicesZstream, setDisablePastDueInvoicesZstream] =
    useState(false);
  const [disableGCPInvoiceCreation, setDisableGCPInvoiceCreation] =
    useState(false);
  const [errorModal, setErrorModal] = useState<ErrorModal>({
    showError: false,
    backendError: false,
  });

  const handleCloseExistingProjectErrorModal = () => {
    setErrorModal({
      showError: false,
      backendError: false,
    });
  };

  const [showConfirmProjectModal, setShowConfirmProjectModal] =
    useState<boolean>(false);

  const handleCloseConfirmProjectModal = () => {
    setShowConfirmProjectModal(false);
  };

  const [showProjectModal, setShowProjectModal] = useState<boolean>(false);
  const [projectModal] = useState<CloudOpsProjectsStateType>();

  const handleCloseProjectModal = () => {
    setShowProjectModal(false);
  };

  const deleteCompanyProject = async () => {
    setShowConfirmProjectModal(false);
    setShowProjectModal(false);

    try {
      await axios.delete(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${projectModal?.id}`
      );

      // getCompanyProjects();
    } catch (deleteCompanyProjectError) {
      // console.log('Failed to get projects!', deleteCompanyProjectError);
    }
  };

  const { hsId } = useParams() as { hsId: string };

  const changeDisableServicesInvoicesSyncFromQB = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisableServicesInvoicesSyncFromQB(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      create_software_dev_invoices: !flag,
    });
  };

  const changeDisablePortalEmailNotifications = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisablePortalEmailNotifications(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      send_emails: !flag,
    });
  };

  const changeDisablePastDueInvoicesNotifications = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisablePastDueInvoicesNotifications(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      disable_past_due_invoice_emails: flag,
    });
  };

  const changeDisableOverBudgetNotifications = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisableOverBudgetNotifications(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      disable_budget_exceed_email: flag,
    });
  };

  const changeDisablePastDueInvoicesZstream = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisablePastDueInvoicesZstream(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      disable_access_to_zstream: flag,
    });
  };

  const changeDisableGCPInvoiceCreation = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setDisableGCPInvoiceCreation(flag);

    await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`, {
      disable_gcp_invoice_creation: flag,
    });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/main-payment-method?company_hs_id=${hsId}`
      )
      .then((res) => {
        setPaymentMethod(res.data.payment_method);
      });

    fetchPageData();
    window.scrollTo(0, 0);
  }, [hsId]);

  const fetchPageData = async () => {
    const retrievedCompany = await fetchCompanyInfo();

    setCompany(retrievedCompany);
    setDisableServicesInvoicesSyncFromQB(
      !retrievedCompany.createSoftwareDevInvoices
    );

    setDisableGCPInvoiceCreation(retrievedCompany.disableGcpInvoiceCreation);
    setDisablePastDueInvoicesZstream(retrievedCompany.disableAccessToZstream);
    setDisableOverBudgetNotifications(
      retrievedCompany.disableBudgetExceedEmail
    );
    setDisablePortalEmailNotifications(!retrievedCompany.sendEmails);
    setDisablePastDueInvoicesNotifications(
      retrievedCompany.disablePastDueInvoiceEmails
    );
  };

  const fetchCompanyInfo = async () => {
    // todo add catch block
    const retrievedCompanyInfo = await axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`)
      .then(({ data }) => mapKeys(data, (v, k) => camelCase(k)));
    // todo add catch block
    const retirevedCompanyBillingInfo = await axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/company-details?company_hs_id=${hsId}`
      )
      .then(({ data }) => data);
    return Object.assign(
      retrievedCompanyInfo,
      retirevedCompanyBillingInfo
    ) as companyType;
  };

  return (
    <>
      <PageWrapperMinWidth
        // Added block for fill empty space, while company data is loading
        title={company.name || <Box sx={{ height: '50px' }} />}
      >
        <Grid container spacing={2}>
          {/* First row of table */}
          <Grid item xs={7} spacing={2}>
            <Stack gap={2}>
              <CompanyDetailsCard
                company={company}
                paymentMethod={paymentMethod}
                onChange={fetchPageData}
              />
              <CloudOpsComp companyHsId={hsId} />
              <InvoiceChart companyHsId={hsId} />
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Block avatar={<CardAvatar />} title="Settings">
              <CompanySettingsBox>
                <Box>
                  <StyledBody2>
                    Disable Software Dev. invoices sync from QB
                  </StyledBody2>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      disabled={user.userType === ADMIN}
                      checked={disableServicesInvoicesSyncFromQB}
                      onChange={changeDisableServicesInvoicesSyncFromQB}
                    />
                  </Stack>
                </Box>
                <Box>
                  <StyledBody2>
                    Disable Zazmic-Connect email notifications
                  </StyledBody2>
                  <AntSwitch
                    disabled={user.userType === ADMIN}
                    checked={disablePortalEmailNotifications}
                    onChange={changeDisablePortalEmailNotifications}
                  />
                </Box>
                <Box>
                  <StyledBody2>
                    Disable past due invoices notifications
                  </StyledBody2>
                  <AntSwitch
                    disabled={user.userType === ADMIN}
                    checked={disablePastDueInvoicesNotifications}
                    onChange={changeDisablePastDueInvoicesNotifications}
                  />
                </Box>

                <Box>
                  <StyledBody2>
                    Disable CloudOps over budget notifications
                  </StyledBody2>
                  <AntSwitch
                    disabled={user.userType === ADMIN}
                    checked={disableOverBudgetNotifications}
                    onChange={changeDisableOverBudgetNotifications}
                  />
                </Box>

                <Box>
                  <StyledBody2>Disable access to Zoho tools</StyledBody2>
                  <AntSwitch
                    disabled={user.userType === ADMIN}
                    checked={disablePastDueInvoicesZstream}
                    onChange={changeDisablePastDueInvoicesZstream}
                  />
                </Box>

                <Box>
                  <StyledBody2>Disable GCP invoices creation</StyledBody2>
                  <AntSwitch
                    disabled={user.userType === ADMIN}
                    checked={disableGCPInvoiceCreation}
                    onChange={changeDisableGCPInvoiceCreation}
                  />
                </Box>
              </CompanySettingsBox>
            </Block>
            <Card sx={{ flex: 1, maxHeight: 699 }}>
              <CompanyNotificationsActivityCard companyHsId={hsId} />
            </Card>
          </Grid>
          {/* Second row of table */}
          <Grid item xs={12}>
            <CompanyInvoicesTabs company={company} />
          </Grid>
        </Grid>
      </PageWrapperMinWidth>

      <Dialog
        onClose={handleCloseProjectModal}
        open={showProjectModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            height: 333,
          },
        }}
      >
        <DialogTitleWrapper>
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="justify-between"
              alignItems="center"
            >
              <Typography
                sx={{ flexGrow: 1 }}
                fontSize={17}
                fontWeight={700}
                fontFamily="Montserrat, Poppins, sans-serif"
                color="#31363E"
              >
                {projectModal?.name}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseProjectModal}
                sx={{
                  color: '#FC3636',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </DialogTitle>
        </DialogTitleWrapper>
        <DialogContent>
          <Typography
            fontSize={17}
            fontWeight={600}
            fontFamily="Montserrat, Poppins, sans-serif"
          >
            Statistics
          </Typography>
          <ProjectModalStatistics>
            <ProjectModalStatisticsRow>
              <Typography fontSize={12} fontWeight="normal" color="#999999">
                Project start date:
              </Typography>
              <Typography fontSize={12} fontWeight="500" color="#484E56">
                N/A
              </Typography>
            </ProjectModalStatisticsRow>
            <ProjectModalStatisticsRow>
              <Typography fontSize={12} fontWeight="normal" color="#999999">
                Project hours total:
              </Typography>
              <Typography fontSize={12} fontWeight="500" color="#484E56">
                N/A
              </Typography>
            </ProjectModalStatisticsRow>
          </ProjectModalStatistics>
          <Divider sx={{ mt: '38px' }} />
          {user.userType !== ADMIN && (
            <ProjectModalFooter>
              <Typography fontSize={12} fontWeight="500" color="#484E56">
                Delete project and all related data from the Zazmic-Connect
              </Typography>
              <Delete
                onClick={() => setShowConfirmProjectModal(true)}
                sx={{
                  color: '#484E56',
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.8 },
                }}
              />
            </ProjectModalFooter>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleCloseConfirmProjectModal}
        open={showConfirmProjectModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            height: 236,
            width: 407,
          },
        }}
      >
        <DialogTitleWrapper>
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="justify-between"
              alignItems="center"
            >
              <Typography
                sx={{ flexGrow: 1 }}
                fontSize={17}
                fontWeight={700}
                fontFamily="Montserrat, Poppins, sans-serif"
                color="#31363E"
              >
                Delete {projectModal?.name}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseConfirmProjectModal}
                sx={{
                  color: '#FC3636',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </DialogTitle>
        </DialogTitleWrapper>
        <DialogContent
          sx={{
            borderBottom: '1px solid #E8E9EB',
            padding: '20px 0',
            margin: '0 24px',
          }}
        >
          <ConfirmModalContent>
            <Typography
              fontSize={14}
              fontWeight="400"
              color="#484E56"
              sx={{ whiteSpace: 'pre-line' }}
            >
              Confirm if you want to delete all related data to the project
              <span style={{ fontWeight: 500, color: '#1E70B0', fontSize: 16 }}>
                {` ${projectModal?.name} `}
              </span>
              from the Zazmic-Connect.
            </Typography>
          </ConfirmModalContent>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px' }}>
          <CancelButtonWrapper>
            <Button
              sx={{ width: 131 }}
              variant="outlined"
              onClick={() => setShowConfirmProjectModal(false)}
            >
              Cancel
            </Button>
          </CancelButtonWrapper>

          <ConfirmButtonWrapper>
            <Button
              sx={{ width: 98 }}
              variant="contained"
              onClick={() => deleteCompanyProject()}
            >
              Delete
            </Button>
          </ConfirmButtonWrapper>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseExistingProjectErrorModal}
        open={errorModal.showError}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            height: 236,
            width: 407,
          },
        }}
      >
        <DialogTitleWrapper>
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="justify-between"
              alignItems="center"
            >
              <Typography
                sx={{ flexGrow: 1 }}
                fontSize={17}
                fontWeight={700}
                fontFamily="Montserrat, Poppins, sans-serif"
                color="#31363E"
              >
                Can&apos;t proceed
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseExistingProjectErrorModal}
                sx={{
                  color: '#FC3636',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </DialogTitle>
        </DialogTitleWrapper>
        <DialogContent>
          <ConfirmModalContent>
            <Typography
              fontSize={16}
              fontWeight="500"
              color="#484E56"
              lineHeight="24px"
              sx={{ whiteSpace: 'pre-line' }}
            >
              Project
              <span style={{ fontWeight: 500, color: '#1E70B0', fontSize: 16 }}>
                {` ${errorModal.project?.name} `}
              </span>
              is connected to the
              <span style={{ fontWeight: 500, color: '#1E70B0', fontSize: 16 }}>
                {` ${
                  errorModal.backendError
                    ? errorModal.companyName
                    : company?.name
                } `}
              </span>
              .
            </Typography>
          </ConfirmModalContent>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompanyPage;

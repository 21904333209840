export const validEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validPassword = (password: string) => {
  const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return re.test(password);
};

export const validConfirmPassword = (
  password: string,
  confirmPassword: string
) => confirmPassword && password === confirmPassword;

export const validName = (name: string) =>
  name.length >= 3 && name.length <= 25;

export const roundUp = (val: string | number | undefined) => {
  if (val) {
    return parseFloat(val ? `${val}` : '0').toFixed(2);
  }
  return 0;
};

function generatePassword(): string {
  const charsets = {
    NUMBERS: '0123456789',
    LOWERCASE: 'abcdefghijklmnopqrstuvwxyz',
    UPPERCASE: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    SYMBOLS: '!@#$%^&*',
  };
  const length = 8;
  const charset: string =
    charsets.NUMBERS +
    charsets.LOWERCASE +
    charsets.UPPERCASE +
    charsets.SYMBOLS;

  let generatedPassword = '';

  for (let i = 0; i < length; i += 1) {
    let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
    randomNumber /= 0x100000000;
    randomNumber = Math.floor(randomNumber * charset.length);

    generatedPassword += charset[randomNumber];
  }

  return generatedPassword;
}

export const geneatePassStr = () => generatePassword();

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Row } from 'organisms/InvoicesTable/types';
import dayjs from 'dayjs';
import MobileInvoiceStatus from 'utils/styles/MobileInvoiceStatus.styles';
import { StyledButton, StyledText, TextButton } from './MobileInvoice.styles';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { neutrals } from 'utils/styles/color';

export const MobileInvoiceItem: FC<{ invoice: Row }> = ({ invoice }) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        boxShadow: '0px 1px 1px 0px #999',
        padding: '8px 16px',
        height: '56px !important',
      }}
    >
      <MobileInvoiceStatus>
        {invoice.status.state === 'open' && 'OPEN'}

        {invoice.status.state === 'overdue' && 'OVERDUE'}

        {invoice.status.state === 'paid' && 'PAID'}

        {invoice.status.state === 'payment_pending' && 'PENDING'}

        {invoice.status.state === 'pending' &&
          invoice.status.state.toUpperCase()}

        {invoice.status.state === 'voided' &&
          invoice.status.state.toUpperCase()}

        {invoice.status.state === 'past_due' &&
          invoice.status.state.toUpperCase()}
      </MobileInvoiceStatus>

      <Stack
        direction="column"
        sx={{
          flex: 1,
          justifyContent: 'space-between',
          height: '56px',
        }}
      >
        <StyledText>{dayjs(invoice.date).format('MMM DD, YYYY')}</StyledText>
        <StyledText sx={{ fontWeight: '400 !important' }}>
          #{invoice.invoiceQbNumber}
        </StyledText>
        <StyledText
          sx={{
            color: `${neutrals[500]} !important`,
            fontWeight: '300 !important',
          }}
        >
          Due: {dayjs(invoice.dueDate).format('MMM DD, YYYY')}
        </StyledText>
      </Stack>

      <Stack
        direction="column"
        sx={{
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          height: '56px',
        }}
      >
        {invoice.status.state !== 'voided' &&
        invoice.status.state !== 'payment_pending' &&
        invoice.status.state !== 'paid' ? (
          <StyledButton
            variant="contained"
            onClick={() =>
              navigate(`/invoice-details/${invoice.invoiceQbNumber}`)
            }
          >
            Pay
          </StyledButton>
        ) : (
          <TextButton
            variant="text"
            onClick={() =>
              navigate(`/invoice-details/${invoice.invoiceQbNumber}`)
            }
          >
            Invoice details
          </TextButton>
        )}
        <StyledText
          sx={{ fontWeight: '400 !important', color: '#484E56 !important' }}
        >
          {!Number.isNaN(Number(invoice?.invoiceTotal))
            ? currencyFormatter(Number(invoice?.invoiceTotal), invoice.currency)
            : ''}
        </StyledText>
      </Stack>
    </Stack>
  );
};

export const InvoiceDefaultValue = {
  id: '',
  invoiceQbId: '',
  invoiceQbNumber: '',
  invoiceType: '',
  date: '',
  amountDue: 0,
  dueDate: '',
  paidDate: '',
  status: {
    days: 0,
    state: '',
  },
  timeLogsLink: '',
  stripeLink: '',
  company: '',
  companyHsId: '',
  services: [],
  discountAmount: 0,
  serviceReference: '',
  serviceDescription: '',
  companyDisableAccessToZstream: false,
  fee: 0,
  feePercentage: 0,
  currency: '',
};

import styled from 'styled-components';
import { Box } from '@mui/material';

export const Wrapper = styled.div`
  height: 100%;

  .MuiPaper-root {
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
  }

  .MuiTabPanel-root {
    padding: 24px 8px 0 8px;
    height: calc(100% - 85px);
  }
`;

export const MainBlock = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    gap: 16px;

    height: 100%;
    overflow: hidden auto;
  }
`;

export const NotificationRow = styled(Box)`
  && {
    display: flex;
    align-items: center;

    padding: 4px 8px;

    .MuiTypography-body2 {
      font-family: 'Poppins', sans-serif;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 16px;
    }

    .MuiTypography-body2:last-of-type {
      color: #3e444d;
    }

    .MuiTypography-body2:first-of-type {
      min-width: 100px;
      color: #7b828c;
    }

    .MuiBox-root {
      display: flex;
      flex-direction: column;

      .MuiTypography-caption {
        color: #3e444d;
      }
    }
  }

  width: 100%;
  border-bottom: 1px solid #ecedef;
`;

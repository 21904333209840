import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { camelCase, mapKeys, snakeCase } from 'lodash-es';
import axios from 'axios';
import { Stack } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { getRoleLabel, ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import Table from 'molecules/Table';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { UserState } from 'store/user/userSlice';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { Order } from 'organisms/InvoicesTable/types';
import Modal from 'molecules/Modal';
import {
  ModalContent,
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import ButtonMenu from 'molecules/ButtonMenu';

type RolesTableProps = {
  addingUser: boolean;
};

const initial = { page: 1, limit: 20, total: 0 };
type sortByType = 'user_type' | 'name' | 'email' | 'created_at';

const RolesTable = ({ addingUser }: RolesTableProps) => {
  const account = useBaoSelector((state) => state.user.value);
  const [users, setUsers] = useState([
    { id: '', name: '', surname: '', email: '', userType: '' },
  ]);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<sortByType>('created_at');
  const [pagination, setPagination] = useState(initial);

  const [deleteUser, setDeleteUser] = useState<{
    show: boolean;
    value: Partial<UserState> | undefined;
  }>({
    show: false,
    value: undefined,
  });
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  const options = [
    {
      icon: <DeleteIcon />,
      label: 'Delete user',
      value: 'delete',
    },
  ];

  const handleSelect = (row: Partial<UserState>, key: string) => {
    if (key === 'delete') {
      setDeleteUser({ show: true, value: row });
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'name',
        label: 'Full Name',
        sortable: true,
        width: '30%',
        render: ({ name, surname }: UserState) => (
          <StyledCellText>
            {name} {surname}
          </StyledCellText>
        ),
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
        width: '42%',
        render: ({ email }: UserState) => (
          <StyledCellText>{email}</StyledCellText>
        ),
      },
      {
        key: 'userType',
        label: 'Role',
        sortable: true,
        width: '20%',
        render: ({ userType }: UserState) => (
          <StyledCellText>
            {getRoleLabel(userType as 'admin' | 'superadmin')}
          </StyledCellText>
        ),
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        width: '8%',
        render: (user: UserState) => (
          <StyledCellContainer $hStack $right>
            {account.userType !== ADMIN && account.userId !== user.id && (
              <ButtonMenu
                options={options}
                onSelect={(k: string) => handleSelect(user, k)}
              />
            )}
          </StyledCellContainer>
        ),
      },
    ],
    [account]
  );

  const handleDelete = useCallback(async (user: Partial<UserState>) => {
    // todo add try/catch
    await axios.delete(
      `${process.env.REACT_APP_USER_SERVICE}/delete/${user.id}`
    );
    await fetchPageData(initial);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchPageData({ ...initial, limit: pagination.limit });
    })();
  }, [addingUser, order, orderBy]);

  const deleteMethod = useCallback(async () => {
    if (!deleteUser?.value) return;

    setShowCaptcha(false);
    await handleDelete(deleteUser?.value);
    setDeleteUser({ show: false, value: undefined });
  }, [handleDelete, deleteUser]);

  const showCaptchaDialog = useCallback(() => {
    setDeleteUser((prev) => ({ ...prev, show: false }));
    setShowCaptcha(true);
  }, []);

  const hideCaptcha = useCallback(() => {
    setShowCaptcha(false);
  }, []);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };
      setPagination(nextState);
      await fetchPageData(nextState);
    },
    [pagination]
  );

  const handleChangeRowsPerPage = useCallback(async (limit: number) => {
    const nextState = {
      ...pagination,
      limit,
      page: 1,
    };
    setPagination(nextState);
    await fetchPageData(nextState);
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: sortByType
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';

    // on third click reset to default
    if (isDesc) {
      setOrder('desc');
      setOrderBy('created_at');
      return;
    }

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function fetchPageData(filter: { [k: string]: string | number }) {
    const { total, ...params } = filter;
    if (orderBy) {
      params.sort = snakeCase(orderBy); // `sort[${snakeCase(orderBy)}]=${order}`;
      params.sort_order = order;
    }
    await axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/users`, { params })
      .then(({ data }) => {
        const { users: usersData, count } = data;
        setUsers(
          usersData.map((user: (typeof users)[0]) =>
            mapKeys(user, (v, k) => camelCase(k))
          )
        );
        setPagination({ ...params, total: count || total } as typeof initial);
      });
  }

  const getUserRoleLabel = (role: string) => {
    if (role === SUPER_ADMIN) return 'Super Admin';
    if (role === ADMIN) return 'Admin';
    return '';
  };

  return (
    <>
      {/* */}
      <StyledTableContainer $new sx={{ position: 'relative' }}>
        <Table
          idKey="userId"
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          data={users || []}
        />
        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.total ? pagination.page - 1 : 0}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>

      <Modal
        open={deleteUser.show}
        maxWidth="xs"
        title="Delete user"
        subtitle="The deleted user will no longer have access to log in to Zazmic-Connect."
        onClose={() => setDeleteUser({ show: false, value: undefined })}
      >
        <>
          <ModalContent>
            <Stack>
              <ModalContentText>
                Full name: {deleteUser.value?.name} {deleteUser.value?.surname}
              </ModalContentText>
              <ModalContentText>
                Email: {deleteUser.value?.email}
              </ModalContentText>
              <ModalContentText>
                Role: {getUserRoleLabel(deleteUser.value?.userType as string)}
              </ModalContentText>
            </Stack>
          </ModalContent>
          <ModalActions>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={() => setDeleteUser({ show: false, value: undefined })}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton variant="contained" onClick={showCaptchaDialog}>
              Delete
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal onComplete={deleteMethod} onClose={hideCaptcha} />
      ) : (
        <span />
      )}
    </>
  );
};

export default RolesTable;

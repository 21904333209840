import React, { FC, useState } from 'react';

import { Stack, Grid, Typography, IconButton } from '@mui/material';

import { IService } from 'organisms/InvoicesTable/types';

import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import {
  InvoiceServiceDetailGrid,
  InvoiceServiceDescription,
} from 'molecules/InvoiceServiceDetail/InvoiceServiceDetail.styles';
import { usd } from 'utils/helpers/currencyFormatter';
import { roundUp } from 'utils/helpers/formValidators';
import { neutrals } from 'utils/styles/color';

const InvoiceServiceDetail: FC<{ service: IService }> = ({ service }) => {
  const [show, setShow] = useState<boolean>(false);

  if (show)
    return (
      <InvoiceServiceDetailGrid container sx={{ alignItems: 'center' }}>
        <Grid item xs={11}>
          <InvoiceServiceDescription>
            <Typography
              variant="body2"
              color={neutrals[600]}
              fontSize="11px"
              fontWeight="300"
              lineHeight="16px"
              paddingRight="10px"
            >
              {service.description}
            </Typography>
          </InvoiceServiceDescription>
        </Grid>

        <Grid item xs={1} sx={{ textAlign: 'center' }}>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setShow((prev) => !prev)}
          >
            <CloseIcon width={14} height={14} />
          </IconButton>
        </Grid>
      </InvoiceServiceDetailGrid>
    );

  return (
    <InvoiceServiceDetailGrid container>
      <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body2"
          color={neutrals[600]}
          fontFamily="Poppins, sans-serif"
          fontSize="0.75rem"
          fontWeight={400}
        >
          {service.name}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Stack
          alignItems="flex-end"
          gap="5px"
          height="56px"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            color="#484E56"
            fontFamily="Poppins, sans-serif"
            fontSize="0.875rem"
            fontWeight={300}
          >
            {service.amount
              ? usd.format(Number(roundUp(service.amount)))
              : '$0.00'}
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setShow((prev) => !prev)}
          >
            <InfoIcon width={24} height={24} color="#CAD0D9" />
          </IconButton>
        </Stack>
      </Grid>
    </InvoiceServiceDetailGrid>
  );
};

export default InvoiceServiceDetail;

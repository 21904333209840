import React, { FC, useState } from 'react';
import { ModalActions } from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import { Box, IconButton, Chip } from '@mui/material';
import { palette } from 'utils/styles/variables';
import Modal from 'molecules/Modal';
import { UserRow } from 'organisms/CompanyDetailsCard/CompanyContacts';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

const PasswordChangedModal: FC<{
  open: boolean;
  user: UserRow;
  password: string;
  onClose: () => void;
}> = ({ open, user, password, onClose }) => {
  const [openSnack, setOpenSnack] = useState(false);

  function copyToClipboard() {
    // open
    setOpenSnack(true);
    // hide
    setTimeout(() => setOpenSnack(false), 5000);
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(password);
    }
    return document.execCommand('copy', true, password);
  }

  return (
    <Modal
      padding24
      open={open}
      maxWidth="sm"
      title="New password set"
      subtitle={`Now user with email ${user?.email} can use new password`}
      onClose={onClose}
    >
      <ModalActions sx={{ justifyContent: 'flex-start' }} $padding24>
        <ModalContentText
          sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}
        >
          {password}
          <Box>
            <IconButton
              aria-label="copy-password"
              onClick={() => copyToClipboard()}
              sx={{
                p: '0 !important',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <CopyIcon color={palette.primary.main} />
              {openSnack && (
                <Chip
                  label="Copied!"
                  color="success"
                  variant="outlined"
                  sx={{ height: '20px' }}
                />
              )}
            </IconButton>
          </Box>
        </ModalContentText>
      </ModalActions>
    </Modal>
  );
};

export default PasswordChangedModal;

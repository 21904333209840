import { StyledMonthDetails } from 'organisms/InvoiceChart/InvoiceChart.styles';
import { Box, Typography } from '@mui/material';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import React from 'react';
import { InvoiceChartState } from 'organisms/InvoiceChart/InvoiceChart';

export const MonthDetails = ({
  details,
  currency,
  totalLabel = 'total',
}: {
  details: InvoiceChartState | null;
  currency?: string;
  totalLabel?: 'total' | 'paid';
}) => {
  if (!details) return null;

  const { month, total_outstanding, total_payment } = details;

  return (
    <StyledMonthDetails $largeLabel>
      <Box>
        <Typography variant="body2">
          {month} {totalLabel}:
        </Typography>
        <Typography variant="body2">
          {total_payment
            ? currencyFormatter(Number(total_payment), currency)
            : currencyFormatter(0, currency)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">Outstanding:</Typography>
        <Typography variant="body2">
          {total_outstanding
            ? currencyFormatter(Number(total_outstanding), currency)
            : currencyFormatter(0, currency)}
        </Typography>
      </Box>
    </StyledMonthDetails>
  );
};

import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Alert, Stack } from '@mui/material';
import { CloudOpsProjectsType, FormType } from 'pages/CompanyPage/types';
import { WhiteBorderTextField } from 'pages/CompanyPage/CompanyPage.styles';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { invoiceInitialDetails } from 'pages/CompanyPage/helpers';
import { keys } from 'organisms/ProjectDialogs/utils';
import axios from 'axios';
import { InvoiceInputNameType } from 'organisms/ProjectDialogs/types';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';

const regExp = /^\d*\.?\d*$/;

const CloudOpsProjectTermsDialog: FC<{
  currency?: keyof typeof currencyIcon;
  companyHsId?: string;
  mode: 'edit' | 'create';
  project?: CloudOpsProjectsType;
  onClose: () => void;
}> = ({ mode, companyHsId, currency, project, onClose }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormType>(invoiceInitialDetails);
  const [cloudOpsProjectDetails, setCloudOpsProjectDetails] = useState<
    string | CloudOpsProjectsType | null
  >(null);

  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const handleCreateProject = useCallback(async () => {
    if (cloudOpsProjectDetails && typeof cloudOpsProjectDetails === 'object') {
      try {
        setLoading(true);
        await handleSaveProjectChanges();

        setForm(invoiceInitialDetails);

        setCloudOpsProjectDetails(null);

        setLoading(false);
        onClose();
      } catch (err) {
        setAlert({ error: true, message: err.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
      }
    }
  }, [cloudOpsProjectDetails, form, companyHsId, onClose]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      !/^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = value.slice(0, -1);
      return;
    }

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      /^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = Number(value);
    }

    // value can be empty
    // value should be decimal or integer
    // value should be more or equal to 0
    // key should be included in keys
    if (value === '' && keys.includes(name)) {
      setForm((prev) => ({ ...prev, [name]: Number(value) }));
      return;
    }

    if ((!regExp.test(value) || Number(value) < 0) && keys.includes(name)) {
      event.target.value = '';
      return;
    }

    setForm((prev) => ({ ...prev, [name]: Number(value) }));
  }, []);

  const handleSaveProjectChanges = useCallback(async () => {
    try {
      if (
        project?.id_str &&
        Number(form.monthly_budget_in_hours) >= 0 &&
        Number(form.monthly_budget_in_hours) <= 1000
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_USER_SERVICE}/projects/${project?.id_str}`,
            {
              minimum_monthly_budget: form.minimum_monthly_budget
                ? Number(form.minimum_monthly_budget)
                : 0,
              monthly_budget_in_hours: form.monthly_budget_in_hours
                ? Number(form.monthly_budget_in_hours)
                : 0,
              over_budget_hourly_rate: form.over_budget_hourly_rate
                ? Number(form.over_budget_hourly_rate)
                : 0,
              type: 'CO',
            }
          )
          .then(() => {
            const projectDetailsCopy = { ...project };
            projectDetailsCopy.minimum_monthly_budget =
              form.minimum_monthly_budget ? form.minimum_monthly_budget : '';

            projectDetailsCopy.monthly_budget_in_hours =
              form.monthly_budget_in_hours ? form.monthly_budget_in_hours : '';

            projectDetailsCopy.over_budget_hourly_rate =
              form.over_budget_hourly_rate ? form.over_budget_hourly_rate : '';

            updateDialogInputs(projectDetailsCopy);
          });
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  }, [project, form]);

  const handleInputBlur = useCallback(
    (event) => {
      const {
        name,
        value,
      }: { name: InvoiceInputNameType; value: string | number } = event.target;

      if (value === '') {
        setForm((prev) => ({ ...prev, [name]: '0' }));
        event.target.value = currencyFormatter(
          Number.parseFloat('0'),
          currency || 'USD'
        );
        //   `${
        //   currencyIcon[currency || 'USD']
        // }${Number.parseFloat('0').toFixed(2)}`;
      } else if (Number(form[name]) >= 0) {
        event.target.value = currencyFormatter(
          Number.parseFloat(form[name]),
          currency || 'USD'
        );
        //   `${
        //   currencyIcon[currency || 'USD']
        // }${Number.parseFloat(form[name]).toFixed(2)}`;
      }

      // handleSaveProjectChanges();
    },
    [form, handleSaveProjectChanges, currency]
  );
  const updateDialogInputs = (details: CloudOpsProjectsType) => {
    // If project 'id' isn't exists, then it's creation mode;
    if (!details.id) return;

    const {
      monthly_budget_in_hours,
      minimum_monthly_budget,
      over_budget_hourly_rate,
    } = details;

    // Set form fields:
    setCloudOpsProjectDetails(details);
    setForm({
      monthly_budget_in_hours,
      minimum_monthly_budget: minimum_monthly_budget || '0',
      over_budget_hourly_rate: over_budget_hourly_rate || '0',
    });
  };

  useEffect(() => {
    if (!project) return;

    updateDialogInputs(project);
  }, [project]);

  const onEmptyAction = () => {
    // eslint-disable-next-line no-console
    console.log('This method will never reached!');
  };

  return (
    <Modal
      open
      maxWidth="sm"
      transitionDuration={0}
      title={`${mode === 'edit' ? 'Edit' : 'Add'} contract terms`}
      subtitle={
        mode === 'edit'
          ? 'New contract terms will be applied to all CloudOps reports for which invoices have not yet been created.'
          : 'Once project contract terms is added project from QuickBooks will be synced with CloudOps project and monthly CloudOps reports will be generated starting from the 1st of the current month. Subsequently, Zazmic-Connect will automatically generate invoices each following month.'
      }
      onClose={mode === 'edit' ? onClose : undefined}
    >
      <>
        <ModalContent sx={{ overflowY: 'hidden' }}>
          {alert.message ? (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          ) : undefined}
          <Stack gap="16px" pb={1}>
            <ZStreamProjectAutoComplete
              disabled
              submitted
              smallLabel
              project={project}
              onChange={onEmptyAction}
            />
            <WhiteBorderTextField
              fullWidth
              size="small"
              disabled={!isSuperAdmin} // If not super admin then disable
              name="monthly_budget_in_hours"
              defaultValue={form.monthly_budget_in_hours || 0}
              onChange={handleInputChange}
              // onBlur={handleInputBlur}
              error={
                Number(form.monthly_budget_in_hours) < 0 ||
                Number(form.monthly_budget_in_hours) > 1000
              }
              helperText={
                Number(form.monthly_budget_in_hours) < 0 ||
                Number(form.monthly_budget_in_hours) > 1000
                  ? 'Please enter a number between 0 and 1000.'
                  : ''
              }
              InputProps={{
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="given-name"
              margin="normal"
              label="Monthly budget in hours"
            />

            <WhiteBorderTextField
              fullWidth
              size="small"
              disabled={!isSuperAdmin} // If not super admin then disable
              name="minimum_monthly_budget"
              InputProps={{
                inputProps: {
                  min: 0,
                  style: { textAlign: 'right' },
                },
              }}
              defaultValue={(() => {
                if (form.minimum_monthly_budget === '') {
                  return form.minimum_monthly_budget;
                }
                return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                  form.minimum_monthly_budget
                ).toFixed(2)}`;
              })()}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onFocus={(e) => {
                e.target.value =
                  Number(form.minimum_monthly_budget) > 0
                    ? form.minimum_monthly_budget
                    : '';
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                Number(form.minimum_monthly_budget) < 0 ||
                Number(form.minimum_monthly_budget) > 100000
              }
              helperText={
                Number(form.minimum_monthly_budget) < 0 ||
                Number(form.minimum_monthly_budget) > 100000
                  ? 'Please enter a number between 0 and 100000.'
                  : ''
              }
              margin="normal"
              label="Base monthly Fee"
            />

            <WhiteBorderTextField
              fullWidth
              size="small"
              disabled={!isSuperAdmin} // If not super admin then disable
              name="over_budget_hourly_rate"
              InputProps={{
                inputProps: {
                  min: 0,
                  style: { textAlign: 'right' },
                },
              }}
              defaultValue={(() => {
                if (form.over_budget_hourly_rate === '') {
                  return form.over_budget_hourly_rate;
                }
                return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                  form.over_budget_hourly_rate
                ).toFixed(2)}`;
              })()}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onFocus={(e) => {
                e.target.value =
                  Number(form.over_budget_hourly_rate) > 0
                    ? form.over_budget_hourly_rate
                    : '';
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                Number(form.over_budget_hourly_rate) < 0 ||
                Number(form.over_budget_hourly_rate) > 10000
              }
              helperText={
                Number(form.over_budget_hourly_rate) < 0 ||
                Number(form.over_budget_hourly_rate) > 10000
                  ? 'Please enter a number between 0 and 10000.'
                  : ''
              }
              margin="normal"
              label="Over budget hourly rate"
            />
          </Stack>
        </ModalContent>

        <ModalActions>
          {mode === 'edit' && (
            <ModalCloseButton
              disabled={loading}
              autoFocus
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </ModalCloseButton>
          )}
          <ModalSubmitButton
            loading={loading}
            variant="contained"
            onClick={handleCreateProject}
          >
            Save
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectTermsDialog;

import React, { FC, MouseEvent, useCallback } from 'react';
import { TableCell, Collapse, Box } from '@mui/material';
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowCollapse,
} from 'molecules/Table/styles';
import { ITableRowProps } from 'molecules/Table/types';

const TableRow: FC<ITableRowProps> = ({
  hover = true,
  size,
  borderless,
  columns,
  data,
  idKey,
  index,
  collapsible,
  collapsedRow,
  onRowClick,
  rowDetailsRenderer,
}) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>) => {
      if (onRowClick) onRowClick(event, data);
    },
    [onRowClick, data]
  );

  if (!collapsible)
    return (
      <StyledTableRow
        hover={hover}
        data-index={index + 1}
        data-key={data[idKey]}
        tabIndex={-1}
        key={data[idKey]}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={handleClick}
      >
        {columns.map((column) => (
          <StyledTableCell
            $size={size}
            $borderless={borderless}
            key={`${data[idKey]}-${column.key}`}
            component="th"
            scope="row"
          >
            {column?.render ? column.render(data) : data[column.key]}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );

  return (
    <>
      <StyledTableRow
        hover
        data-index={index + 1}
        data-key={data[idKey]}
        tabIndex={-1}
        key={data[idKey]}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={handleClick}
        $active={collapsedRow === data[idKey]}
        $collapsible
      >
        {columns.map((column) => (
          <StyledTableCell
            $size={size}
            key={`${data[idKey]}-${column.key}`}
            component="th"
            scope="row"
          >
            {column?.render ? column.render(data) : data[column.key]}
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRowCollapse>
        <TableCell sx={{ p: 0, border: 0 }} colSpan={100}>
          <Collapse
            in={collapsedRow === data[idKey]}
            timeout="auto"
            unmountOnExit
          >
            <Box>{rowDetailsRenderer && rowDetailsRenderer(data)}</Box>
          </Collapse>
        </TableCell>
      </StyledTableRowCollapse>
    </>
  );
};

export default TableRow;
